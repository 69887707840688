<template>
  <div>
    <flux-modal
      class="w-full max-w-[22rem]"
      v-model:visible="showTreatmentCounts"
      title="Aantal externe behandelingen"
    >
      <PatientEditTreatmentCountComponent
        :patientZisNumber="patient.zis_number"
        :patientTreatmentCounts="patient.patient_treatment_counts"
      />
    </flux-modal>
    <div v-if="!editing && !createnew">
      <div class="flex justify-end">
        <flux-button
          v-if="covEnabled"
          icon="far fa-redo"
          type="text"
          @click="emit('doCovCheck')"
        >
          {{ $t("patient.cov_check.execute") }}
        </flux-button>
        <flux-button
          icon="fal fa-plus"
          ref="btn_add_more"
          type="text"
          @click="createnew = true"
          >{{
            $t("patient.create.form.step.payer_insurers.add_more")
          }}</flux-button
        >
        <flux-button
          type="text"
          icon="fal fa-plus"
          @click="showTreatmentCounts = !showTreatmentCounts"
        >
          Externe behandelingen registreren
        </flux-button>
      </div>
      <div
        v-for="(combinedPayerInsurer, index) in combinedPayerInsurers"
        :key="index"
      >
        <flux-card class="mb-4" shadow="never">
          <div style="float: right">
            <clickable-icon-group>
              <clickable-icon
                icon="fa-pencil"
                ref="btn_start_edit"
                @click="setEditState(true, index)"
              ></clickable-icon>
            </clickable-icon-group>
          </div>
          <flux-card-button-header
            class="mb-4"
            :title="combinedPayerInsurer.insurer_name"
          >
            <template #suffix>
              <span
                class="rounded border border-orange-200 bg-orange-50 px-2 text-sm text-orange-400 dark:bg-orange-700 dark:text-orange-100"
                v-if="objectIsExpired(combinedPayerInsurer)"
              >
                {{ $t("patient.edit.payer_insurers.old") }}
              </span>
            </template>
          </flux-card-button-header>
          <div>
            <flux-short-form>
              <flux-short-form-item
                :label="$t('patient.create.form.step.payer_insurers.end_date')"
                span="col-span-6"
              >
                {{ dateFilter(combinedPayerInsurer.end_date) }}
              </flux-short-form-item>
              <flux-short-form-item
                :label="
                  $t('patient.create.form.step.payer_insurers.insurer_number')
                "
                span="col-span-6"
              >
                <template v-if="combinedPayerInsurer.insurer_number">
                  {{ combinedPayerInsurer.insurer_number }}
                </template>
                <template v-else>
                  <i class="fas fa-exclamation-circle mr-1 text-amber-200"></i>
                  <span style="color: #cdcfd2">{{
                    $t("general.unknown")
                  }}</span>
                </template>
              </flux-short-form-item>
              <flux-short-form-item
                :label="$t('patient.create.form.step.payer_insurers.number')"
                span="col-span-12"
              >
                <template v-if="combinedPayerInsurer.number">
                  {{ combinedPayerInsurer.number }}
                </template>
                <template v-else>
                  <i class="fas fa-exclamation-circle mr-1 text-amber-200"></i>
                  <span class="text-text-quaternary">{{
                    $t("general.unknown")
                  }}</span>
                </template>
              </flux-short-form-item>
              <flux-short-form-item
                :label="$t('patient.create.form.step.payer_insurers.type')"
                span="col-span-6"
              >
                <div
                  v-for="(
                    same_payer_insurer, index
                  ) in combinedPayerInsurer.payer_insurers"
                  :key="index"
                >
                  {{ $t("labels.insurance_type." + same_payer_insurer.type) }}
                  <ZorgvergoedingLabel
                    v-if="
                      same_payer_insurer.package_code &&
                      hasFeatureFlag('zorgvergoeding') &&
                      hasApp('zorgvergoeding')
                    "
                    :payerInsurer="same_payer_insurer"
                    :packageCode="same_payer_insurer.package_code"
                    displayDetails="expand"
                  />
                </div>
              </flux-short-form-item>
              <flux-short-form-item
                :label="
                  $t('patient.create.form.step.payer_insurers.package_name')
                "
                span="col-span-6"
              >
                <div
                  v-for="(
                    same_payer_insurer, index
                  ) in combinedPayerInsurer.payer_insurers"
                  :key="index"
                >
                  {{ same_payer_insurer.package_name || "-" }}
                </div>
              </flux-short-form-item>
            </flux-short-form>
          </div>
        </flux-card>
      </div>
    </div>
    <div v-else-if="createnew">
      <div class="mb-6">
        <span
          class="cursor-pointer text-text-tertiary active:text-text-primaryActive"
          v-flux-loading="loading"
          @click="setEditState(false)"
          ><i class="far fa-arrow-left"></i> {{ $t("general.back") }}</span
        >
        <CreateNewPayerInsurer
          :patient="patient"
          @cancel="setEditState(false)"
          @created="createNew()"
          @creating="loading = true"
        />
      </div>
    </div>
    <div v-else-if="editing">
      <div class="mb-6">
        <span
          class="cursor-pointer text-text-tertiary active:text-text-primaryActive"
          @click="setEditState(false)"
          ><i class="far fa-arrow-left"></i> {{ $t("general.back") }}</span
        >
      </div>
      <div
        v-flux-loading="loading"
        v-for="(payerInsurer, index) in editingPayerInsurers"
        :key="index"
      >
        <EditCombinedPayerInsurer
          :payerInsurer="payerInsurer"
          :patient="patient"
          @updated="
            emit('updated');
            loading = false;
          "
          @updating="loading = true"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { $t } from "@/libraries/i18n";
import { Patient } from "@/composables/patient";
import { computed, ref, watch } from "vue";
import { CombinedPayerInsurer } from "./PatientEditPayerInsurer.vue";
import EditCombinedPayerInsurer from "./EditCombinedPayerInsurer.vue";
import CreateNewPayerInsurer from "./CreateNewPayerInsurer.vue";
import { PayerInsurer, PayerInsurerPersisted } from "@/models/PayerInsurer";
import { getUser, hasApp } from "@/libraries/plugins/getUser";
import { hasFeatureFlag } from "@/libraries/plugins/hasFeatureFlag";
import PatientEditTreatmentCountComponent from "./PatientEditTreatmentCount.vue";
import { objectIsExpired } from "@/libraries/utils/objectIsActive";
import ClickableIcon from "../ClickableIcon.vue";
import ZorgvergoedingLabel from "@/components/Patient/Zorgvergoedingen/ZorgvergoedingLabel.vue";
import { dateFilter } from "@/filters";

const editing = ref(false);
const createnew = ref(false);
const editingPayerInsurers = ref<PayerInsurerPersisted[]>([]);
const showTreatmentCounts = ref(false);
const props = defineProps<{
  combinedPayerInsurers: CombinedPayerInsurer[];
  patient: Patient;
  payerInsurers: PayerInsurer[];
}>();

const emit = defineEmits<{
  (e: "updated"): void;
  (e: "doCovCheck"): void;
}>();

const loading = ref<boolean>(false);

function setEditState(newState: boolean, index?: number) {
  if (!newState) {
    editing.value = false;
    createnew.value = false;
    return;
  }
  if (index == undefined) {
    throw new Error("index undefined");
  }

  editingPayerInsurers.value =
    props.combinedPayerInsurers[index].payer_insurers;
  editing.value = true;
}

watch(
  () => props.combinedPayerInsurers,
  () => {
    updateEditingPayerInsurers();
  },
  { deep: true },
);

function updateEditingPayerInsurers() {
  const totalPayerInsurers = props.combinedPayerInsurers.flatMap(
    (x) => x.payer_insurers,
  );
  const updatedPayerInsurers = totalPayerInsurers.filter((payerInsurer) =>
    editingPayerInsurers.value.some(({ id }) => payerInsurer.id == id),
  );
  editingPayerInsurers.value = updatedPayerInsurers;
  loading.value = false;

  if (updatedPayerInsurers.length < 1) {
    setEditState(false);
  }
}

function createNew() {
  emit("updated");
  setEditState(false);
  loading.value = false;
}

const covEnabled = computed(() => {
  return (
    getUser().healthcare_provider.cov_settings.enabled &&
    hasFeatureFlag("cov-check")
  );
});
</script>
