<template>
  <flux-modal
    :visible="modalVisible"
    :title="filter.subject"
    @update:visible="emit('cancel')"
  >
    <flux-input
      class="my-4"
      v-model:modelValue="textContent"
      ref="input"
      @enter="saveFilter()"
    />
    <template #footer>
      <flux-button-group>
        <flux-button size="small" @click="emit('cancel')">
          {{ $t("general.cancel") }}
        </flux-button>
        <flux-button type="primary" size="small" @click="saveFilter()">
          {{ $t("general.add") }}
        </flux-button>
      </flux-button-group>
    </template>
  </flux-modal>
</template>

<script lang="ts" setup>
import { FilterManager, TextFilter } from "@/models/Filter/filter";
import { watch, ref } from "vue";
import { $t } from "@/libraries/i18n";

const props = defineProps<{
  filter: TextFilter;
  filterManager: FilterManager;
  modalVisible: boolean;
}>();

const input = ref<HTMLElement | null>(null);

watch(input, () => {
  input.value?.focus();
});

const emit = defineEmits<{ (e: "cancel"): void; (e: "save"): void }>();

const textContent = ref("");

const { saveSelectedText } = props.filterManager;

function saveFilter() {
  saveSelectedText(props.filter.uuid, textContent.value);
  emit("save");
}
</script>
