import { Ref } from "vue";
import { recurringPaymentMandatesQueryKeys } from "./../queries/recurringPaymentMandates/recurringPaymentMandatesQueryKeys";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import {
  createRecurringPaymentMandate,
  fetchRecurringPaymentMandatesForPatient,
  revokeRecurringPaymentMandate,
} from "@/queries/recurringPaymentMandates/recurringPaymentMandatesQuery";
import { hasFeatureFlag } from "@/libraries/plugins/hasFeatureFlag";

export function useRecurringPaymentMandates(zisNumber: Ref<number>) {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey:
      recurringPaymentMandatesQueryKeys.recurringPaymentMandates(zisNumber),
    queryFn: ({ queryKey }) =>
      fetchRecurringPaymentMandatesForPatient(queryKey[1]),
    enabled: hasFeatureFlag("recurring-payments"),
    refetchOnWindowFocus: false,
    throwOnError: true,
  });

  const create = useMutation({
    mutationFn: (payload: {
      zisNumber: number;
      signatureDate: Temporal.PlainDate;
      reference: string;
      iban: string;
    }) =>
      createRecurringPaymentMandate(
        payload.zisNumber,
        payload.signatureDate,
        payload.reference,
        payload.iban,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey:
          recurringPaymentMandatesQueryKeys.recurringPaymentMandates(zisNumber),
      });
    },
  });

  const revoke = useMutation({
    mutationFn: (payload: {
      zisNumber: number;
      recurringPaymentMandateUuid: string;
    }) =>
      revokeRecurringPaymentMandate(
        payload.zisNumber,
        payload.recurringPaymentMandateUuid,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey:
          recurringPaymentMandatesQueryKeys.recurringPaymentMandates(zisNumber),
      });
    },
  });

  return {
    ...query,
    createRecurringPaymentMandate: (
      zisNumber: number,
      signatureDate: Temporal.PlainDate,
      reference: string,
      iban: string,
    ) => create.mutateAsync({ zisNumber, signatureDate, reference, iban }),
    revokeRecurringPaymentMandate: (
      zisNumber: number,
      recurringPaymentMandateUuid: string,
    ) => revoke.mutateAsync({ zisNumber, recurringPaymentMandateUuid }),
  };
}
