<template>
  <flux-modal
    :visible="modalVisible"
    :title="filter.subject"
    @update:visible="emit('cancel')"
  >
    <div class="space-y-4">
      <flux-input-box
        v-if="filter.presetRangeOptions && filter.presetRangeOptions.length > 0"
        label="Periode"
      >
        <flux-select v-model:modelValue="selectedRangeOption">
          <option :value="undefined">Anders</option>
          <option
            v-for="option in filter.presetRangeOptions"
            :value="option"
            :key="option.label"
          >
            {{ option.label }}
          </option>
        </flux-select>
      </flux-input-box>
      <flux-input-box label="Begin">
        <flux-input
          class="my-4"
          v-model:modelValue="rangeStart"
          :type="filter.isDate ? 'date' : 'text'"
          ref="inputStart"
        />
      </flux-input-box>
      <flux-input-box label="Eind">
        <flux-input
          class="my-4"
          v-model:modelValue="rangeEnd"
          :type="filter.isDate ? 'date' : 'text'"
          ref="inputEnd"
        />
      </flux-input-box>
    </div>
    <template #footer>
      <flux-button-group>
        <flux-button size="small" @click="emit('cancel')">
          {{ $t("general.cancel") }}
        </flux-button>
        <flux-button
          type="primary"
          size="small"
          @click="saveFilter()"
          :disabled="filter.isDate && (rangeStart === '' || rangeEnd === '')"
        >
          {{ $t("general.add") }}
        </flux-button>
      </flux-button-group>
    </template>
  </flux-modal>
</template>

<script lang="ts" setup>
import { $t } from "@/libraries/i18n";
import { FilterManager, RangeFilter } from "@/models/Filter/filter";
import { onMounted, ref, watch } from "vue";

const props = defineProps<{
  filter: RangeFilter;
  filterManager: FilterManager;
  modalVisible: boolean;
}>();

const inputStart = ref<HTMLInputElement>();
const selectedRangeOption = ref(props.filter.presetRangeOptions?.at(0));

watch(selectedRangeOption, () => {
  if (!selectedRangeOption.value) {
    return;
  }
  rangeStart.value = selectedRangeOption.value.rangeStart;
  rangeEnd.value = selectedRangeOption.value.rangeEnd;
});

onMounted(() => {
  inputStart.value!.focus();
});

const emit = defineEmits<{
  (e: "cancel"): void;
  (e: "save"): void;
}>();

const rangeStart = ref(selectedRangeOption.value?.rangeStart ?? "");
const rangeEnd = ref(selectedRangeOption.value?.rangeEnd ?? "");

const { saveSelectedRange } = props.filterManager;

function saveFilter() {
  let start = rangeStart.value;
  let end = rangeEnd.value;
  saveSelectedRange(props.filter.uuid, start, end);
  emit("save");
}
</script>
