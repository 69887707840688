import { Patient } from "./Patient";
import { PhoneNumber, PhoneNumberPersisted } from "./PhoneNumber";
import { EmailAddress } from "./EmailAddress";
import { EmailAddressForm } from "./Forms";

export const contactRelationships = [
  "AUNT",
  "BRO",
  "BROINLAW",
  "COUSN",
  "DAUC",
  "DAUINLAW",
  "DOMPART",
  "FTH",
  "FTHINLAW",
  "DAUFOST",
  "FTHFOST",
  "MTHFOST",
  "SONFOST",
  "GRNDDAU",
  "GRFTH",
  "GRMTH",
  "GRNDSON",
  "GGRFTH",
  "GGRMTH",
  "HUSB",
  "MTH",
  "MTHINLAW",
  "NEPHEW",
  "NIECE",
  "SIS",
  "SISINLAW",
  "SONC",
  "SONINLAW",
  "UNCLE",
  "WIFE",
  "OTH",
] as const;
export type ContactRelationship = (typeof contactRelationships)[number];

export const contactRoles = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "9",
  "11",
  "14",
  "15",
  "19",
  "20",
  "21",
  "23",
  "24",
] as const;

export type ContactRole = (typeof contactRoles)[number];
export interface ContactBody {
  fullname?: string;
  relationship: ContactRelationship;
  role: ContactRole;
  phone_numbers: PhoneNumber[];
  email_addresses: EmailAddressForm[];
  is_payer: boolean;
}
export interface Contact {
  id: number;
  patient: Patient;
  fullname: string;
  relationship: ContactRelationship;
  role: ContactRole;
  phone_numbers: PhoneNumberPersisted[];
  email_addresses: EmailAddress[];
  is_payer: boolean;
}

export type ContactForm = ContactBody;
