<template>
  <flux-tooltip
    content="Geen acties beschikbaar."
    :disabled="actions.length > 0"
    @show="emit('show')"
  >
    <flux-tooltip
      v-if="actions.length === 1"
      :content="getDescription(actions[0])"
      :openDelay="600"
    >
      <flux-button
        v-if="actions.length === 1"
        :icon="actions[0].icon"
        :disabled="actions[0].disabled"
        @click="onClick(actions[0])"
        size="mini"
        :class="[
          actions[0].disabled
            ? 'pointer-events-none text-text-quaternary opacity-50'
            : 'cursor-pointer text-sm text-text-primary opacity-100',
          actions[0].hidden ? 'hidden' : 'visible',
        ]"
      >
      </flux-button>
    </flux-tooltip>
    <flux-dropdown
      v-else-if="actions.length > 1"
      :iconButton="true"
      icon="fas fa-ellipsis-h"
      direction="right"
      :disabled="actions.length === 0"
    >
      <flux-dropdown-item
        v-for="action in actions"
        :key="
          typeof action.description === 'string'
            ? action.description
            : 'dynamic'
        "
        :icon="action.icon"
        @click="onClick(action)"
        :class="[
          action.disabled
            ? 'pointer-events-none text-text-quaternary opacity-50'
            : 'cursor-pointer text-text-primary opacity-100',
          action.hidden ? 'hidden' : 'visible',
        ]"
      >
        {{ getDescription(action) }}
      </flux-dropdown-item>
    </flux-dropdown>
  </flux-tooltip>
</template>

<script setup lang="ts" generic="T extends any, TRow extends any">
import { RowAction } from "./RowActions";

const props = defineProps<{
  actions: RowAction<T, TRow>[];
  row?: TRow;
}>();

const emit = defineEmits<{
  execute: [body: T];
  show: [];
}>();

function onClick(action: RowAction<T, TRow>) {
  if ("command" in action) {
    emit("execute", action.command);
  } else if ("callback" in action) {
    if (action.callback.length > 0 && props.row !== undefined) {
      (action.callback as (row: TRow) => void)(props.row);
    } else {
      (action.callback as () => void)();
    }
  }
}

function getDescription(action: RowAction<T, TRow>) {
  return typeof action.description === "function" && props.row !== undefined
    ? action.description(props.row)
    : (action.description as string);
}
</script>
