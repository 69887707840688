<template>
  <div class="my-2 first:mt-0 last:mb-0" ref="root">
    <label class="inline-block max-w-prose self-center" :for="uuid + 'svg'">
      {{ item.text }}
    </label>
    <div class="flex items-center">
      <input
        v-if="false"
        v-model.number="points"
        type="number"
        :min="minPoints"
        :max="maxPoints"
        :maxlength="maxPoints.toString().length"
        :class="[
          'w-12 rounded text-sm outline-none dark:ring-offset-neutral-600',
          readonly ? '' : 'focus: focus:ring',
        ]"
      />

      <div class="flex flex-col p-1">
        <div>
          <svg
            :id="uuid + 'svg'"
            :tabindex="readonly ? -1 : 0"
            ref="svg"
            @keydown="keydown"
            :viewBox="'0 0' + ' ' + (100 + 2 * margin) + ' 10'"
            :class="[
              'mx-8 h-8 outline-none ring-offset-4 transition duration-75',
              readonly
                ? ''
                : 'cursor-pointer focus:ring dark:ring-offset-neutral-800 ',
            ]"
            xmlns="http://www.w3.org/2000/svg"
            @click="(ev: MouseEvent) => (readonly ? undefined : click(ev))"
            @mousemove="mouseover"
            @mouseout="temp = undefined"
          >
            <line
              :x1="margin"
              y1="5"
              :x2="100 + margin"
              y2="5"
              :stroke="lineColor"
            ></line>
            <rect
              class="fill-current text-white dark:text-neutral-800"
              ref="scale"
              :x="margin"
              width="100"
              height="10"
            />
            <line
              :x1="0 + margin"
              y1="5"
              :x2="100 + margin"
              y2="5"
              stroke-width="0.5"
              :stroke="lineColor"
            ></line>
            <line
              v-for="tick in ticks"
              :key="uuid + 'tick' + tick"
              :x1="tick + margin"
              y1="2.5"
              :x2="tick + margin"
              y2="7.5"
              stroke-width="0.5"
              opacity="0.75"
              :stroke="lineColor"
              stroke-linecap="round"
            ></line>
            <rect
              class="pointer-events-none"
              v-if="temp !== undefined && !readonly"
              :x="0"
              :width="tempLineWidth"
              height="10"
              fill="#a0aec0"
              :style="`transform: translateX(${
                temp - tempLineWidth / 2 + margin
              }px);`"
            />
            <rect
              class="pointer-events-none fill-current text-text-secondary"
              :x="margin - 0.25"
              :width="0.5"
              height="10"
            />
            <rect
              class="pointer-events-none fill-current text-text-secondary"
              :x="100 + margin - 0.25"
              :width="0.5"
              height="10"
            />
            <rect
              class="pointer-events-none fill-current text-red-600"
              v-if="val !== undefined"
              :x="val - valLineWidth / 2 + margin"
              :width="valLineWidth"
              height="10"
              :transform="`rotate(45, ${val + margin}, 5)`"
            />
          </svg>
        </div>
        <div class="flex">
          <div
            :class="['max-w-32 text-xs', readonly ? '' : 'cursor-pointer']"
            @click="
              () => {
                if (readonly) return;
                points = minPoints;
                focus();
              }
            "
          >
            {{ lowerBound }}
          </div>
          <div class="flex-grow"></div>
          <div
            :class="['max-w-32 text-xs', readonly ? '' : 'cursor-pointer']"
            @click="
              () => {
                if (readonly) return;
                points = maxPoints;
                focus();
              }
            "
          >
            {{ upperBound }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  DecimalItem,
  DisplayItem,
  EXTENSION,
  getChildItemWithExtension,
  getExtension,
  IntegerItem,
} from "@/libraries/questionnaires/item";
import { scrollIntoViewIfNeeded } from "@/libraries/utils";
import { isCoding } from "@/libraries/questionnaires/score";
import { computed, onBeforeMount, ref, watch } from "vue";

const scoreModes = ["closest_node", "interval"] as const;
type ScoreMode = (typeof scoreModes)[number];

const props = defineProps<{
  item: DecimalItem | IntegerItem;
  response: any;
  readonly: boolean;
}>();

const margin = 5;

const uuid = crypto.randomUUID();

const root = ref<HTMLDivElement>();
const scale = ref<SVGRectElement>();

/** Number between 0 and 100 */
const val = ref<number>();
const temp = ref<number>();

const scoreMode = computed((): ScoreMode => {
  const res = getExtension(props.item, {
    url: EXTENSION.VAS_SCORE_MODE,
  })?.valueCodeableConcept?.coding[0].code;

  if (res === "closest_node" || res === "interval") {
    return res;
  }

  if (res === undefined) {
    return "interval";
  }

  throw new Error(`Got unexpected VAS_SCORE_MODE ${res}`);
});

const minPoints = computed((): number => {
  return (
    getExtension(props.item, { url: EXTENSION.MIN_VALUE })?.valueDecimal ??
    -Infinity
  );
});
const maxPoints = computed((): number => {
  return (
    getExtension(props.item, { url: EXTENSION.MAX_VALUE })?.valueDecimal ??
    Infinity
  );
});

function findBoundingChildItem(
  bound: "upper" | "lower",
): DisplayItem | undefined {
  const boundItem = getChildItemWithExtension(props.item, {
    url: EXTENSION.ITEM_CONTROL,
    code: bound,
  });

  if (boundItem?.type !== "display") {
    return undefined;
  }

  return boundItem;
}

const lowerBound = computed((): string | undefined => {
  return findBoundingChildItem("lower")?.text;
});

const upperBound = computed((): string | undefined => {
  return findBoundingChildItem("upper")?.text;
});

const svg = ref<SVGElement>();

function focus() {
  svg.value?.focus();
}

const points = computed({
  get(): number | undefined {
    if (val.value === undefined) {
      return undefined;
    } else if (scoreMode.value === "closest_node") {
      return Math.round(
        minPoints.value +
          ((maxPoints.value - minPoints.value) * val.value) / 100,
      );
    } else if (scoreMode.value === "interval") {
      return Math.min(
        Math.floor(
          minPoints.value +
            ((1 + maxPoints.value - minPoints.value) * val.value) / 100,
        ),
        maxPoints.value,
      );
    } else {
      throw new Error("Unexpected score mode");
    }
  },
  set(points: number | undefined) {
    if (points === undefined) {
      val.value = undefined;
    } else if (scoreMode.value === "closest_node") {
      val.value =
        (100 * (points - minPoints.value)) /
        (maxPoints.value - minPoints.value);
    } else if (scoreMode.value === "interval") {
      val.value =
        (100 * (0.5 + points - minPoints.value)) /
        (1 + maxPoints.value - minPoints.value);
    } else {
      throw new Error("Unexpected score mode");
    }
  },
});

const ticks = computed((): number[] => {
  let nPoints: number;
  if (scoreMode.value === "closest_node") {
    nPoints = maxPoints.value - minPoints.value - 1;
  } else {
    nPoints = maxPoints.value - minPoints.value;
  }

  const d = 100 / (nPoints + 1);
  const points = Array.from(new Array(nPoints), (a, i) => (i + 1) * d);
  return points;
});

const valLineWidth = computed(() => 0.75);

const tempLineWidth = computed(() => 0.5);

watch(val, () => {
  if (root.value) {
    scrollIntoViewIfNeeded(root.value);
  }
  props.response[props.item.linkId] = {
    valueCoding: {
      code: points.value,
      display: val.value,
    },
  };
});

onBeforeMount(() => {
  if (props.item.linkId in props.response) {
    const response = props.response[props.item.linkId];

    if (!(typeof response === "object") || !isCoding(response)) {
      return;
    }

    const { display } = response.valueCoding;

    if (display !== undefined) val.value = display as any;
  }
});

function getPercentage(ev: MouseEvent): number {
  if (scale.value === undefined) {
    throw new Error("scaleElement is undefined");
  }

  const bounds = scale.value.getBoundingClientRect();
  if (bounds === undefined) {
    throw new Error("could not find rect");
  }

  return Math.min(1, Math.max(0, (ev.clientX - bounds.left) / bounds.width));
}

function click(ev: MouseEvent) {
  val.value = getPercentage(ev) * 100;
}

function mouseover(ev: MouseEvent) {
  temp.value = getPercentage(ev) * 100;
}

function keydown(ev: KeyboardEvent) {
  switch (ev.key) {
    case "ArrowRight":
    case "ArrowUp":
      points.value = Math.min((points.value ?? 0) + 1, maxPoints.value);
      ev.preventDefault();
      return;
    case "ArrowLeft":
    case "ArrowDown":
      points.value = Math.max((points.value ?? 0) - 1, minPoints.value);
      ev.preventDefault();
      return;
  }
}

const lineColor = computed(() => {
  return window.matchMedia("(prefers-color-scheme: dark)").matches
    ? "#a3a3a3"
    : "#4a5568";
});
</script>
