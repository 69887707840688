import { computed, ref } from "vue";
import {
  BooleanFilterStatus,
  Filter,
  FilterLogicOption,
  FilterStatus,
  InputFilterStatus,
  OptionFilterStatus,
  RangeFilterStatus,
} from "@/models/Filter/filter";
import { useRememberFilters } from "./rememberFilters";

const filterTypes = [
  "boolean",
  "optionRadio",
  "optionCheckbox",
  "input",
  "range",
] as const;
type FilterType = (typeof filterTypes)[number];

export type FilterStates = {
  boolean: {
    [key: string]: BooleanFilterStatus;
  };
  option: {
    [key: string]: OptionFilterStatus;
  };
  input: {
    [key: string]: InputFilterStatus;
  };
  range: {
    [key: string]: RangeFilterStatus;
  };
};

export function useFilterManager(
  initialFilters: Filter[],
  filterRememberKey?: string,
  forcedFilterParams?: { [key: string]: FilterEntryQuery },
) {
  const registry: Map<string, Filter> = new Map(
    initialFilters.map((filter) => [filter.uuid, filter]),
  );
  const active = ref<Set<string>>(new Set());

  const activeFilters = ref<Array<Filter>>([]);
  const inactiveFilters = ref<Array<Filter>>(initialFilters);

  const filterStates = ref<FilterStates>(getInitialStates(initialFilters));
  const filterLogic = ref<"AND" | "OR">("AND");

  const setFilterLogic = (newLogic: "AND" | "OR") =>
    (filterLogic.value = newLogic);

  const saveSelectedText = (uuid: string, text: string) =>
    saveStatus(uuid, { text });

  const saveSelectedOptions = (
    uuid: string,
    selectedOptions: (string | number | string[] | number[])[],
  ) => saveStatus(uuid, { selectedOptions });

  const saveSelectedLogic = (
    uuid: string,
    selectedLogic: FilterLogicOption,
  ) => {
    saveStatus(uuid, { selectedLogic });
  };

  const saveSelectedRange = (
    uuid: string,
    rangeStart: string,
    rangeEnd: string,
  ) => saveStatus(uuid, { rangeStart, rangeEnd });

  const getSelectedOptions = (uuid: string) =>
    filterStates.value.option[uuid].selectedOptions;

  const saveStatus = (uuid: string, status: Partial<FilterStatus>) => {
    const type = registry.get(uuid)!.filterType;
    const state = filterStates.value[type][uuid];
    filterStates.value[type][uuid] = {
      ...state,
      ...(status as typeof state),
    };
    active.value.add(uuid);
    updateFilters();
  };

  const activateFilter = (uuid: string) => {
    active.value.add(uuid);
    updateFilters();
  };

  const removeFilter = (uuid: string) => {
    const filter = registry.get(uuid)!;
    filterStates.value[filter.filterType][uuid] = filter.defaultFilterStatus;
    active.value.delete(uuid);
    updateFilters();
  };

  const removeAllFilters = () => {
    for (const [uuid, filter] of registry.entries()) {
      filterStates.value[filter.filterType][uuid] = filter.defaultFilterStatus;
      active.value.delete(uuid);
    }
    updateFilters();
  };

  function updateFilters() {
    updateActiveFilters();
    updateInactiveFilters();
  }

  function updateActiveFilters() {
    const act: Map<string, Filter> = new Map();
    active.value.forEach((uuid) => {
      act.set(uuid, registry.get(uuid)!);
    });
    activeFilters.value = Array.from(act.values());
  }

  function updateInactiveFilters() {
    const cloneFilters = new Map(registry);
    active.value.forEach((uuid) => {
      cloneFilters.delete(uuid);
    });
    inactiveFilters.value = Array.from(cloneFilters.values());
  }

  function reInitializeFilter(uuid: string, filter: Filter) {
    registry.set(uuid, filter);
    updateFilters();
  }

  const filterQuery = computed(() => {
    return buildFilterQuery(
      activeFilters.value,
      filterStates.value,
      filterLogic.value,
    );
  });

  const filterQueryString = computed(() =>
    buildFilterQueryString(filterQuery.value, forcedFilterParams),
  );

  if (filterRememberKey !== undefined) {
    useRememberFilters(
      registry,
      activeFilters,
      filterStates,
      filterLogic,
      activateFilter,
      filterRememberKey,
    );
  }

  return {
    saveSelectedLogic,
    saveSelectedOptions,
    saveSelectedText,
    saveSelectedRange,
    getSelectedOptions,
    activateFilter,
    removeFilter,
    removeAllFilters,
    setFilterLogic,
    reInitializeFilter,
    filterLogic,
    activeFilters,
    inactiveFilters,
    filterStates,
    filterQuery,
    filterQueryString,
  };
}

function getInitialStates(initialFilters: Filter[]): FilterStates {
  return initialFilters.reduce(
    (states, filter) => {
      Object.assign(states[filter.filterType], {
        [filter.uuid]: filter.defaultFilterStatus,
      });
      return states;
    },
    { boolean: {}, option: {}, input: {}, range: {} },
  );
}

export type FilterEntryQuery = {
  type: FilterType;
  values: (string | number)[];
} & { logic?: FilterLogicOption };

export type FilterQuery = {
  filter_entries: {
    [key: string]: FilterEntryQuery;
  };
  filter_logic: "AND" | "OR";
};

function buildFilterQuery(
  filters: Filter[],
  filterStates: FilterStates,
  filter_logic: "AND" | "OR",
): FilterQuery | undefined {
  if (filters.length === 0) {
    return;
  }
  const filterObject = {
    filter_entries: {},
    filter_logic,
  };

  filters.forEach((filter) => {
    if (filter.filterType === "option") {
      const filterState = filterStates.option[filter.uuid];
      Object.assign(filterObject.filter_entries, {
        [filter.queryStringKey]: {
          type:
            filter.dropdownType === "radio" ? "optionRadio" : "optionCheckbox",
          values: filterState.selectedOptions.flat(),
          logic: filterState.selectedLogic,
        },
      });
    }
    if (filter.filterType === "boolean") {
      const filterState = filterStates.boolean[filter.uuid];
      Object.assign(filterObject.filter_entries, {
        [filter.queryStringKey]: {
          type: "boolean",
          values: [filter.value],
          logic: filterState.selectedLogic,
        },
      });
    }
    if (filter.filterType === "input") {
      const filterState = filterStates.input[filter.uuid];
      Object.assign(filterObject.filter_entries, {
        [filter.queryStringKey]: {
          type: "input",
          values: [filterState.text],
          logic: filterState.selectedLogic,
        },
      });
    }
    if (filter.filterType === "range") {
      const filterState = filterStates.range[filter.uuid];
      Object.assign(filterObject.filter_entries, {
        [filter.queryStringKey]: {
          type: "range",
          values: [filterState.rangeStart, filterState.rangeEnd],
          logic: filterState.selectedLogic,
        },
      });
    }
  });

  return filterObject;
}

function buildFilterQueryString(
  filterQuery?: FilterQuery,
  forcedFilterParams?: { [key: string]: FilterEntryQuery },
): string {
  const queryItems: string[] = [];
  Object.entries({
    ...forcedFilterParams,
    ...filterQuery?.filter_entries,
  }).forEach(([key, value]) => {
    if (value.type === "optionCheckbox") {
      queryItems.push(
        `${key}=[${value.values
          .flat()
          .map((option) =>
            typeof option === "string" ? `"${option}"` : option,
          )
          .join(",")}]`,
      );
    } else if (value.type === "boolean") {
      queryItems.push(`${key}=${value.logic === "EQ" ? "true" : "false"}`);
    } else if (value.type === "range") {
      queryItems.push(
        `${key}={"start":"${value.values[0]}", "end":"${value.values[1]}"}`,
      );
    } else if (value.type === "input" && value.logic === "AEQ") {
      queryItems.push(`${"approximate_" + key}=${value.values[0]}`);
    } else {
      queryItems.push(`${key}=${value.values[0]}`);
    }
  });
  return queryItems.join("&");
}
