import { objectIsActive } from "@/libraries/utils/objectIsActive";
import { z } from "zod";
import { insuranceTypes, InsuranceTypes } from "./Insurer";

export const payerInsurerSchema = z.object({
  id: z.number(),
  number: z.string().optional(),
  type: z.enum(insuranceTypes),
  insurer_id: z.number().optional(),
  insurer_name: z.string().optional(),
  insurer_number: z.string().optional(),
  insurer_code: z.string().optional(),
  package_code: z.string().optional(),
  package_name: z.string().optional(),
  start_date: z.string(),
  end_date: z.string(),
  patient_zis_number: z.number(),
  insurer: z.any(),
  created_at: z.string(),
  updated_at: z.string(),
});

export interface PayerInsurer {
  id?: number;
  number?: string;
  type?: InsuranceTypes;
  insurer_id?: number;
  insurer_name?: string;
  insurer_number?: string;
  insurer_code?: string;
  package_code?: string;
  package_name?: string;
  start_date?: string;
  end_date?: string;
  created_at?: string;
  updated_at?: string;
}

export type PayerInsurerPersisted = PayerInsurer & {
  id: number;
  number?: string;
  type: InsuranceTypes;
  insurer_id?: number;
  insurer_name?: string;
  insurer_number?: string;
  insurer_code?: string;
  package_code?: string;
  package_name?: string;
  start_date: string;
  end_date: string;
  created_at: string;
  updated_at: string;
};

export interface PayerInsurerForm {
  number?: string;
  type: InsuranceTypes;
  insurer_id?: number;
  insurer_name?: string;
  insurer_number?: string;
  insurer_code?: string;
  package_code?: string;
  package_name?: string;
  start_date: string;
  end_date: string;
}

export interface CovCheckPayerInsurerApiResponse {
  number: string;
  type: InsuranceTypes;
  package_code: string;
  package_name: string;
  insurer_name: string;
  insurer_number?: string;
  insurer_code: string;
  start_date: string;
  end_date: string;
}

export function isPayerInsurerPersisted(
  payerInsurer: PayerInsurer,
): payerInsurer is PayerInsurerPersisted {
  return !!payerInsurer.id;
}

export interface PayerInsurerBody {
  number: string;
  type: InsuranceTypes;
  insurer_id?: number;
  insurer_name?: string;
  insurer_number?: string;
  insurer_code?: string;
  package_code?: string;
  package_name?: string;
  start_date: string;
  end_date: string;
}

export function payerInsurerFormToApi(
  form: PayerInsurerForm,
): PayerInsurerBody {
  return {
    number: form.number ?? "",
    type: form.type,
    insurer_id: form.insurer_id ?? undefined,
    insurer_name: form.insurer_name,
    insurer_number: form.insurer_number,
    insurer_code: form.insurer_code ?? undefined,
    package_code: form.package_code ?? undefined,
    package_name: form.package_name ?? undefined,
    start_date: form.start_date ?? "",
    end_date: form.end_date ?? "",
  };
}

export function getMostRelevantInsurer(payerInsurers: PayerInsurerPersisted[]) {
  const activeInsurers = sortInsurersByCoverage(
    payerInsurers.filter((pi) => objectIsActive({ object: pi })),
  );

  return sortInsurersByCoverage(activeInsurers).at(0);
}

export function sortInsurersByCoverage(pis: PayerInsurerPersisted[] = []) {
  return pis.sort((a, b) =>
    getCoveragePriority(a.type) > getCoveragePriority(b.type) ? 1 : -1,
  );
}

export function insurerHasPhysioAftercare(insurer: PayerInsurerPersisted) {
  return ["3311", "3358", "3351", "3313", "3311", "3311", "1000"].includes(
    insurer.insurer_number ?? "",
  );
}

function getCoveragePriority(type: InsuranceTypes) {
  switch (type) {
    case "AT":
      return 0;
    case "A":
      return 1;
    case "B":
      return 2;
    case "BZ":
      return 3;
    case "H":
      return 4;
    case "T":
      return 5;
    default:
      return 10;
  }
}
