import { apiClient } from "@/libraries/utils/axios";
import { $t } from "@/libraries/i18n";
import moment from "moment";
import { PatientCovCheckResponse } from "@/apis/patients/index";
import { useNotify } from "@/composables/notify";
import { z } from "zod";
import { insuranceTypes } from "@/models/Insurer";

const { notify } = useNotify();

const covCheckScheme = z
  .object({
    result: z.string(),
    success: z.boolean(),
    message: z.string().optional(),
    patient: z
      .object({
        surname: z.string(),
        surname_prefix: z.string().optional(),
        maiden_name: z.string().optional(),
        maiden_name_prefix: z.string().optional(),
        date_of_birth: z
          .string()
          .transform((value) => Temporal.PlainDate.from(value)),
        gender: z.enum(["M", "F", "UNK", "UN"]),
        deceased: z.boolean(),
        bsn: z.string().optional(),
      })
      .optional(),
    payer_insurers: z.array(
      z.object({
        number: z.string().optional(),
        type: z.enum(insuranceTypes),
        package_code: z.string().optional(),
        package_name: z.string().optional(),
        insurer_number: z.string().optional(),
        insurer_name: z.string(),
        insurer_code: z.string().optional(),
        start_date: z
          .string()
          .transform((value) => Temporal.PlainDate.from(value)),
        end_date: z
          .string()
          .transform((value) => Temporal.PlainDate.from(value)),
      }),
    ),
    unauthorized_insurers: z.array(
      z.object({
        insurer_number: z.string().optional(),
      }),
    ),
  })
  .readonly();

export type CovCheckResponse = z.infer<typeof covCheckScheme>;

export async function covCheck({
  date_of_birth,
  bsn,
  postal_code,
  house_number,
}: {
  date_of_birth: string;
  bsn: string;
  postal_code?: string;
  house_number?: string;
}): Promise<{ data: CovCheckResponse }> {
  if (postal_code && house_number && !bsn) {
    return z.object({ data: covCheckScheme }).parse(
      await apiClient.post("/cov_check", {
        search_type: "postcode",
        reference_date: moment().format("YYYY-MM-DD"),
        date_of_birth: date_of_birth,
        postal_code: postal_code,
        house_number: house_number,
      }),
    );
  }
  return z.object({ data: covCheckScheme }).parse(
    await apiClient.post("/cov_check", {
      search_type: "bsn",
      reference_date: moment().format("YYYY-MM-DD"),
      date_of_birth: date_of_birth,
      bsn: bsn,
    }),
  );
}

export async function existingPatientCovCheck(
  patient: { zis_number: number },
  date = moment().format("YYYY-MM-DD"),
) {
  return apiClient.post(
    "/patients/:zis_number/vecozo/cov_check",
    {
      reference_date: date,
      directly_create_payer_insurers: true,
    },
    {
      params: {
        zis_number: patient.zis_number,
      },
    },
  );
}

export function notifyCovCheckError(
  data?: CovCheckResponse | PatientCovCheckResponse,
) {
  if (data) {
    notify({
      message: ("message" in data ? data.message : null) ?? data.result,
      type: "error",
    });
  } else {
    notify({
      message: $t("error.unknown"),
      type: "error",
    });
  }
}

export function notifyCovCheckSuccess() {
  notify({
    message: $t("patient.cov_check.success"),
    type: "success",
  });
}
