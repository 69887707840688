<template>
  <div
    @click="focus"
    :class="[
      editable
        ? 'group flex min-h-[3.5rem] flex-col overflow-hidden rounded border border-opacity-30 duration-150 focus-within:border-opacity-100 hover:border-opacity-100 '
        : '',
      editable && inline
        ? 'border-transparent hover:border-border-primary  '
        : 'border-border-secondary ',
    ]"
  >
    <flux-modal
      v-model:visible="visible"
      title="Voeg een link toe"
      :before-close="close"
    >
      <flux-input
        v-model:modelValue="linkText"
        :prefix="linkPrefix"
        ref="input"
      />
      <template #footer>
        <flux-button-group>
          <flux-button size="small" @click="close">{{
            $t("general.cancel")
          }}</flux-button>
          <flux-button size="small" @click="confirm" type="primary">{{
            $t("general.add")
          }}</flux-button>
        </flux-button-group>
      </template>
    </flux-modal>
    <div
      :class="[
        'grow-wrap peer relative order-2 box-border grid w-full overflow-auto break-words rounded transition duration-75',
        paddingBottom,
      ]"
    >
      <div
        v-if="editor && editor?.isEmpty"
        :class="[
          textSize,
          'absolute left-3 top-1 select-none text-text-tertiary',
        ]"
      >
        {{ placeholder }}
      </div>

      <editor-content :editor="editor" />
    </div>
    <slot>
      <!-- Set order manually because otherwise the peer pseudoselector does not work because of how general sibling combinators in CSS work. -->
      <div
        class="transform-all z-10 order-1 mb-2 box-border flex flex-wrap items-center gap-1 border-b border-border-primary bg-background-primary px-3 py-2 opacity-30 duration-150 focus-within:opacity-100 group-hover:opacity-100 peer-focus-within:opacity-100 dark:opacity-70"
        v-if="editor != null && editable && !inline"
      >
        <template v-if="!noStyling">
          <editor-button
            size="mini"
            icon="far fa-bold"
            @click="editor?.chain().focus().toggleBold().run()"
            :active="editor?.isActive('bold')"
          ></editor-button>
          <editor-button
            size="mini"
            icon="far fa-italic"
            @click="editor?.chain().focus().toggleItalic().run()"
            :active="editor?.isActive('italic')"
          >
            italic
          </editor-button>
          <editor-button
            size="mini"
            icon="far fa-underline"
            @click="editor?.chain().focus().toggleUnderline().run()"
            :active="editor?.isActive('underline')"
          ></editor-button>
          <editor-button
            size="mini"
            icon="far fa-strikethrough"
            @click="editor?.chain().focus().toggleStrike().run()"
            :active="editor?.isActive('strike')"
          >
            italic
          </editor-button>
          <div
            class="mx-2 box-border h-5 self-center border-l border-slate-200"
          />
          <button
            class="shadow-xs flex h-6 w-6 flex-none cursor-pointer items-center justify-center rounded-md bg-background-primary text-text-quaternary hover:bg-background-secondary hover:text-text-primaryHover"
            tabindex="-1"
            @click.stop="editor?.chain().insertContent('👍').run()"
          >
            👍
          </button>
          <button
            class="shadow-xs flex h-6 w-6 flex-none cursor-pointer items-center justify-center rounded-md bg-background-primary text-text-quaternary hover:bg-background-secondary hover:text-text-primaryHover"
            tabindex="-1"
            @click.stop="editor?.chain().insertContent('👎').run()"
          >
            👎
          </button>
          <div
            class="mx-2 box-border h-5 self-center border-l border-slate-200"
          />

          <editor-button
            size="mini"
            icon="far fa-h1"
            @click="editor?.chain().focus().toggleHeading({ level: 1 }).run()"
            :active="editor?.isActive('heading', { level: 1 })"
          >
            h1
          </editor-button>
          <editor-button
            size="mini"
            icon="far fa-h2"
            @click="editor?.chain().focus().toggleHeading({ level: 2 }).run()"
            :active="editor?.isActive('heading', { level: 2 })"
          >
            h2
          </editor-button>
          <editor-button
            size="mini"
            icon="far fa-h3"
            @click="editor?.chain().focus().toggleHeading({ level: 3 }).run()"
            :active="editor?.isActive('heading', { level: 3 })"
          >
            h3
          </editor-button>
          <div
            class="mx-2 box-border h-5 self-center border-l border-slate-200"
          />
          <editor-button
            size="mini"
            icon="far fa-list-ol"
            @click="editor?.chain().focus().toggleOrderedList().run()"
            :active="editor?.isActive('orderedList')"
          >
            italic
          </editor-button>
          <editor-button
            size="mini"
            icon="far fa-list-ul"
            @click="editor?.chain().focus().toggleBulletList().run()"
            :active="editor?.isActive('bulletList')"
          >
            italic
          </editor-button>
          <editor-button
            size="mini"
            icon="fas fa-horizontal-rule"
            @click="editor.chain().focus().setHorizontalRule().run()"
            :active="editor?.isActive('horizontalRule')"
          >
            horizontal rule
          </editor-button>
          <template v-if="links">
            <div
              class="mx-2 box-border h-5 self-center border-l border-slate-200"
            />
            <editor-button
              title="Email link"
              icon="far fa-envelope"
              @click="openModal('mailto:')"
              :active="editor?.isActive('link') && hasPrefix('mailto:')"
            >
              setLink
            </editor-button>
            <editor-button
              title="Telefoonnummer link"
              icon="far fa-phone"
              @click="openModal('tel:')"
              :active="editor?.isActive('link') && hasPrefix('tel:')"
            >
              setLink
            </editor-button>
            <editor-button
              title="Website link"
              icon="far fa-link"
              @click="openModal('https://')"
              :active="editor?.isActive('link') && hasPrefix('https://')"
            >
              setLink
            </editor-button>
            <editor-button
              icon="far fa-unlink"
              @click="editor?.chain().focus().unsetLink().run()"
              :active="editor?.isActive('link')"
            >
              unsetLink
            </editor-button>
          </template>
          <template v-if="align">
            <div
              class="mx-2 box-border h-5 self-center border-l border-slate-200"
            />
            <editor-button
              icon="far fa-align-left"
              @click="editor?.chain().focus().setTextAlign('left').run()"
              :active="editor?.isActive({ textAlign: 'left' })"
            >
              left
            </editor-button>
            <editor-button
              icon="far fa-align-center"
              @click="editor?.chain().focus().setTextAlign('center').run()"
              :active="editor?.isActive({ textAlign: 'center' })"
            >
              center
            </editor-button>
            <editor-button
              icon="far fa-align-right"
              @click="editor?.chain().focus().setTextAlign('right').run()"
              :active="editor?.isActive({ textAlign: 'right' })"
            >
              right
            </editor-button>
            <editor-button
              icon="far fa-align-justify"
              @click="editor?.chain().focus().setTextAlign('justify').run()"
              :active="editor?.isActive({ textAlign: 'justify' })"
            >
              justify
            </editor-button>
          </template>
          <template v-if="submitOnEnter">
            <div class="flex flex-grow flex-row-reverse">
              <flux-button
                class=""
                type="default"
                size="mini"
                :disabled="!isDirty"
                @click="emit('enter')"
              >
                {{ (isMac ? "⌘" : "ctrl") + " ⏎" }}
              </flux-button>
            </div>
          </template>
        </template>
        <slot name="extra" :editor="editor"></slot>
      </div>
    </slot>
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import {
  Editor,
  EditorContent,
  Extension,
  Extensions,
  JSONContent,
} from "@tiptap/vue-3";
import EditorButton from "./EditorButton.vue";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import TextAlign from "@tiptap/extension-text-align";
import { Encoding } from "@/models/MedicalUpdate";
import { TemplatePlaceholder } from "@/libraries/tiptap/TemplatePlaceholder";
import { ProseMirrorTemplatePlaceholder } from "@/libraries/tiptap/ProseMirrorTemplatePlaceholder";
import { MedicalUpdate } from "@/libraries/tiptap/MedicalUpdate";
import { ButtonPlaceholder } from "@/libraries/tiptap/ButtonPlaceholder";
import { TextSizes } from "@/libraries/UI/text";
import { TextSnippet } from "@/libraries/tiptap/TextSnippet";
import { LegalExport } from "@/libraries/tiptap/LegalExport";
import { Snippet } from "@/models/Snippet";
import { clone } from "@/libraries/utils/clone";
import Underline from "@tiptap/extension-underline";
import * as Sentry from "@sentry/browser";
import { setConsultValues } from "@/libraries/tiptap/SetConsultValues";

type Prefix = "mailto:" | "tel:" | "https://";

const props = withDefaults(
  defineProps<{
    modelValue?: string | object;
    encoding?: Encoding;
    editable?: boolean;
    align?: boolean;
    links?: boolean;
    placeholder?: string;
    inline?: boolean;
    submitOnEnter?: boolean;
    noStyling?: boolean;
    snippets?: boolean;
    snippetItems?: (query: string) => Readonly<Snippet[]>;
    textSize?: TextSizes;
    paddingBottom?: string;
    contenteditable?: never;
    enablePlaceholderExtensions?: boolean;
  }>(),
  {
    contenteditable: undefined,
    encoding: "json-prosemirror",
    editable: true,
    aling: false,
    links: false,
    inline: false,
    submitOnEnter: false,
    noStyling: false,
    placeholder: "",
    snippets: false,
    textSize: "text-sm",
    paddingBottom: "pb-2",
    enablePlaceholderExtensions: true,
  },
);

const emit = defineEmits<{
  (e: "update:modelValue", v: JSONContent): void;
  (e: "enter"): void;
}>();

const editor = ref<Editor>();

const input = ref();
const visible = ref(false);
const linkText = ref("");
const linkPrefix = ref("");
const isDirty = ref(false);
let hasEmitted = 0;

onMounted(() => {
  if (props.encoding !== "json-prosemirror") {
    throw new Error(
      "Unexpected encoding Error. Expected json-prosemirror in RichTextarea component, got: " +
        props.encoding,
    );
  }

  const extensions = getExtensions();
  const editorClass = getEditorClass();

  editor.value = new Editor({
    content: props.modelValue ?? "",
    editable: props.editable,
    extensions: extensions,
    editorProps: {
      attributes: {
        class: editorClass,
      },
    },
    enableContentCheck: true,
    onContentError: (error) => {
      Sentry.captureException(error.error);
    },
  });

  editor.value?.on("update", () => {
    emit("update:modelValue", editor.value!.getJSON());
    isDirty.value = true;
    hasEmitted++;
  });
});

onBeforeUnmount(() => {
  editor.value?.destroy();
});

function close() {
  visible.value = false;
}

function focus() {
  editor.value?.commands.focus();
}

function hasPrefix(prefix: Prefix) {
  return editor.value?.getAttributes("link").href?.includes(prefix);
}

watch(
  () => props.modelValue,
  () => {
    if (hasEmitted > 0) {
      hasEmitted--;
      return;
    }
    if (props.modelValue !== undefined) {
      editor.value?.chain().setContent(props.modelValue).run();
    } else {
      editor.value
        ?.chain()
        .setContent({ type: "doc", content: [{ type: "paragraph" }] })
        .run();
    }
  },
  { deep: true },
);

watch(visible, () => {
  if (visible.value) {
    setTimeout(() => input.value.focus(), 50);
  }
});

function openModal(prefix: Prefix) {
  visible.value = true;

  let previousUrl = editor.value?.getAttributes("link").href;
  if (previousUrl) {
    previousUrl = previousUrl.replace("mailto:", "");
    previousUrl = previousUrl.replace("tel:", "");
    previousUrl = previousUrl.replace("https://", "");
    linkText.value = previousUrl;
  } else {
    linkText.value = "";
  }
  linkPrefix.value = prefix;
}

function confirm() {
  if (linkText.value !== undefined && editor.value)
    editor.value
      .chain()
      .focus()
      .extendMarkRange("link")
      .setLink({ href: linkPrefix.value + linkText.value, target: "_blank" })
      .run();

  visible.value = false;
}

const isMac = computed(() => navigator.userAgent.includes("Mac"));

function getExtensions(): Extensions {
  const extensions: Extensions = [
    StarterKit.configure({
      horizontalRule: {
        HTMLAttributes: {
          class: "border-t border-200 my-4",
        },
      },
    }),
    Underline,
    ...(props.enablePlaceholderExtensions
      ? [TemplatePlaceholder, ProseMirrorTemplatePlaceholder, ButtonPlaceholder]
      : []),
    setConsultValues,
    MedicalUpdate,
    LegalExport,
    Link.configure({
      openOnClick: false,
    }),
    ...(props.align
      ? [
          TextAlign.configure({
            types: ["heading", "paragraph"],
          }),
        ]
      : []),
  ];
  if (props.submitOnEnter) {
    extensions.push(
      Extension.create({
        addKeyboardShortcuts() {
          return {
            "Mod-Enter": () => {
              emit("enter");
              return true;
            },
          };
        },
      }),
    );
  }
  if (props.snippets && props.snippetItems !== undefined) {
    extensions.push(
      TextSnippet.configure({
        items: (query: string) => clone(props.snippetItems!(query)),
      }),
    );
  }
  return extensions;
}

function getEditorClass(): string {
  let editorClass =
    "resize-none box-border outline-none break-words rounded overflow-hidden m-0 py-1 w-full box-border ";
  if (props.editable) {
    editorClass += " px-3";
  }
  editorClass += " " + props.textSize;
  return editorClass;
}
</script>

<style lang="postcss">
.ProseMirror-TemplatePlaceholder {
  @apply inline-block cursor-default rounded px-0.5 lowercase text-sky-400 ring-1 ring-slate-200;
}

.ProseMirror-ButtonPlaceholder {
  @apply block w-fit rounded-[3px] border-x-[18px] border-y-[10px] border-solid border-[#3490DC] bg-[#3490DC] text-white no-underline shadow-[0_2px_3px_rgba(0,0,0,0.16)];
  -webkit-text-size-adjust: none;
}

.ProseMirror-TemplatePlaceholder,
.ProseMirror-MedicalUpdate,
.ProseMirror-ButtonPlaceholder {
  &.ProseMirror-selectednode {
    @apply ring ring-slate-300;
  }
}
</style>
