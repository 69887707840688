import {
  FilterEntryQuery,
  FilterQuery,
  useFilterManager,
} from "@/libraries/managers/FilterManager";
import { createOptionFilter } from "@/models/Filter/Functionals/createOptionFilter";
import { computed, ref, Ref } from "vue";
import { PatientLabel } from "@/models/PatientLabel";
import { clone } from "@/libraries/utils/clone";
import { createBooleanFilter } from "@/models/Filter/Functionals/createBooleanFilter";
import { createUserFilter } from "@/models/Filter/Filters/userFilter";
import { $t } from "@/libraries/i18n";
import { getUser } from "@/libraries/plugins/getUser";

export type NoLabelOption = { id: "none"; name: "Geen label" };

export function usePatientFilter(
  entries: Ref<(PatientLabel | NoLabelOption)[]>,
  forcedFilterParams: Ref<{ [key: string]: FilterEntryQuery }> = ref({}),
) {
  const users = getUser().healthcare_provider.users.filter((u) =>
    u.roles?.some((r) => r.name === "doctor"),
  );

  const filterManager = useFilterManager(
    [
      createOptionFilter({
        data: (
          [<NoLabelOption>{ id: "none", name: "Geen label" }] as (
            | PatientLabel
            | NoLabelOption
          )[]
        ).concat(clone(entries.value)),
        subject: "Label",
        prefix: "Label",
        filterKey: "id",
        queryStringKey: "labels",
        searchKeys: ["name"],
        label: (p) => p.name,
        optionsType: "single",
        shouldCount: false,
        rememberKey: "label",
      }),
      createBooleanFilter({
        subject: "Gearchiveerd",
        prefix: "Patient",
        queryStringKey: "is_archived",
        value: false,
        logicOptions: ["EQ", "NEQ"],
        rememberKey: "is_archived",
      }),
      createBooleanFilter({
        prefix: "Patiënt",
        queryStringKey: "deceased",
        rememberKey: "deceased",
        subject: "Overleden",
        value: true,
        logicTranslation: new Map([
          ["EQ", "is"],
          ["NEQ", "is niet"],
        ]),
      }),
      createUserFilter({
        data: users,
        subject: "Hoofdbehandelaar",
        prefix: "Hoofdbehandelaar",
        filterKey: "id",
        queryStringKey: "user_ids",
        rememberKey: "userAgbCode",
        logicOptionLogic: {
          onlyPositive: true,
          onlyOr: true,
        },
      }),
    ],
    undefined,
    forcedFilterParams.value,
  );

  const filterQuery = filterManager.filterQuery;
  const filteredEntries = computed((): (PatientLabel | NoLabelOption)[] => {
    if (!filterQuery.value) {
      return (
        [<NoLabelOption>{ id: "none", name: "Geen label" }] as (
          | PatientLabel
          | NoLabelOption
        )[]
      ).concat(clone(entries.value));
    }
    return filter(
      (
        [<NoLabelOption>{ id: "none", name: "Geen label" }] as (
          | PatientLabel
          | NoLabelOption
        )[]
      ).concat(clone(entries.value)),
      filterQuery.value,
    );
  });

  function filterByLabel(
    entries: (PatientLabel | NoLabelOption)[],
    query: FilterEntryQuery,
  ) {
    const predicate = (
      entry: PatientLabel | NoLabelOption,
      value: string | number,
    ) => String(entry.id).startsWith(value.toString());
    if (query.logic === "NEQ") {
      return entries.filter((entry) => !predicate(entry, query.values[0]));
    }

    if (query.logic === "EQ") {
      return entries.filter((entry) => predicate(entry, query.values[0]));
    }

    if (query.logic === "OR") {
      return entries.filter((entry) =>
        query.values.some((value) => predicate(entry, value)),
      );
    }

    if (query.logic === "NOR") {
      return entries.filter((entry) =>
        query.values.every((value) => !predicate(entry, value)),
      );
    }

    throw new Error("Unexpected logic type");
  }

  function filter(
    entries: (PatientLabel | NoLabelOption)[],
    filterQuery: FilterQuery,
  ) {
    if (filterQuery.filter_logic === "AND") {
      let filteredEntries = entries;
      for (const [key, val] of Object.entries(filterQuery.filter_entries)) {
        if (key === "label") {
          filteredEntries = filterByLabel(entries, val);
        }
      }
      return filteredEntries;
    } else {
      const filteredEntries: (PatientLabel | NoLabelOption)[] = [];
      for (const [key, val] of Object.entries(filterQuery.filter_entries)) {
        if (key === "label") {
          filteredEntries.push(...filterByLabel(entries, val));
        }
      }
      return Array.from(
        new Map(filteredEntries.map((entry) => [entry.name, entry])).values(),
      );
    }
  }

  return {
    filterManager,
    filteredEntries,
  };
}
