import { apiClient } from "@/libraries/utils/axios";
import { isTooManyRequestsErrorResponse } from "@/libraries/utils/errorHandling";
import {
  PayerInsurerPersisted,
  payerInsurerSchema,
} from "@/models/PayerInsurer";
import {
  ZorgvergoedingResult,
  zorgvergoedingResultSchema,
} from "@/models/zorgvergoeding";
import { MaybeRef } from "@tanstack/vue-query/build/legacy/types";
import { unref } from "vue";
import { z } from "zod";

export const zorgvergoedingRatelimitError = new Error("Too many requests");

export async function getZorgvergoedingResult(
  uzovi: MaybeRef<string>,
  pakketCode: MaybeRef<string>,
  date: MaybeRef<string>,
): Promise<ZorgvergoedingResult[]> {
  const { data } = await apiClient.get("/apps/zorgvergoeding/results", {
    params: {
      uzovi,
      pakket_code: pakketCode,
      date,
    },
  });

  if (isTooManyRequestsErrorResponse(data)) {
    throw zorgvergoedingRatelimitError;
  }

  return z.array(zorgvergoedingResultSchema).parse(data.results);
}

export async function getPatientZorgvergoedingResult(
  zisNumber: MaybeRef<number>,
  date: MaybeRef<string>,
): Promise<
  { payer_insurer: PayerInsurerPersisted; results: ZorgvergoedingResult[] }[]
> {
  const { data } = await apiClient.get(
    `/apps/zorgvergoeding/patients/${unref(zisNumber)}/results`,
    {
      params: {
        date,
      },
    },
  );

  if (isTooManyRequestsErrorResponse(data)) {
    throw zorgvergoedingRatelimitError;
  }

  return z
    .array(
      z.object({
        payer_insurer: payerInsurerSchema,
        results: z.array(zorgvergoedingResultSchema),
      }),
    )
    .parse(data.results);
}
