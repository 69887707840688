import { CalendarState, UserFromSync } from "./interfaces";
import moment, { Moment } from "moment";
import { State as RootState } from "./interfaces";
import { ActionTree, MutationTree, GetterTree } from "vuex";
import axios, { Canceler } from "axios";
import {
  Appointment,
  AppointmentProposal,
  AppointmentStatus,
  CopiedAppointment,
  getUserParticipants,
  UnpersistedAppointment,
  UnpersistedParticipant,
} from "../../models/Appointment";
import { isPersisted, StoreAppointmentPayload } from "../../models/Appointment";
import { apiClient } from "../utils/axios";
import { PatientLocalCache } from "../../models/Patient";
import { addMomentToModel, timeout } from "../utils/index";
import AppointmentRepositoryUsingApi from "../repositories/appointmentRepositoryUsingApi";
import * as Sentry from "@sentry/browser";
import { clone } from "../utils/clone";

const locale = "nl";
moment.locale(locale);

const CancelToken = axios.CancelToken;
let running_requests: Canceler[] = [];

const state: CalendarState = {
  suggestFor: null,
  days: [],
  appointments: [],
  appointmentProposals: [],
  loading: false,
  slotSize: 10,
  slotHeight: 15,
  slotHeightUnit: "px",
  activeAppointmentId: -1,
  patients: {},
  user_ids: [],
  unpersistedAppointment: undefined,
  unpersistedAppointmentIsDirty: false,
  copiedAppointment: undefined,
  cuttedAppointment: undefined,
  confirmDeleteUnpersisted: false,
  originalAppointments: {},
  displayMode: "week",
  selectedPatientZisNumber: undefined,
  initialLoad: true,
  nextOnLoadActions: [],
};

export function getTop(
  appointment: { start: moment.Moment },
  startTimeInMinutes: number,
  options: {
    dayStart: moment.Moment;
    slotSize: number;
    slotHeight: number;
    slotHeightUnit: "px";
  },
): `${number}px` {
  // use the hour * 60 instead of minutes from day start to avoid DST issues.
  const fromDayStartInMinutes =
    appointment.start.hour() * 60 +
    appointment.start.minute() -
    startTimeInMinutes;

  return `${(fromDayStartInMinutes / options.slotSize) * options.slotHeight}${
    options.slotHeightUnit
  }`;
}

export function getHeight(
  appointment: { start: moment.Moment; end: moment.Moment },
  options: {
    dayStart: moment.Moment;
    dayEnd: moment.Moment;
    slotSize: number;
    slotHeight: number;
    slotHeightUnit: "px";
  },
): `${number}px` {
  const maxAppEnd = moment.min(appointment.end, options.dayEnd);
  const minAppStart = moment.max(appointment.start, options.dayStart);

  const appointmentLengthInMinutes = maxAppEnd.diff(minAppStart, "minutes");
  return `${
    (Math.max(5, appointmentLengthInMinutes) / options.slotSize) *
      options.slotHeight -
    1 // substract 1 to compensate for outline
  }${options.slotHeightUnit}`;
}

function getDefaultUserId(
  currentUser?: UserFromSync,
  suggestedUserId?: number,
) {
  if (suggestedUserId) {
    return suggestedUserId;
  } else if (state.user_ids.length === 1) {
    return state.user_ids[0];
  } else if (
    (currentUser?.AGB_code !== "" && currentUser?.AGB_code !== undefined) ||
    currentUser?.responsible_user_id !== undefined
  ) {
    return state.user_ids.find((id) => id === currentUser?.id)
      ? currentUser?.id
      : state.user_ids[0];
  }
}

function getDefaultParticipants(
  currentUser?: UserFromSync,
  suggestedUserId?: number,
) {
  const participants: UnpersistedParticipant[] = [];
  const userId = getDefaultUserId(currentUser, suggestedUserId);
  const user = currentUser?.healthcare_provider.users.find(
    ({ id }) => userId === id,
  );

  if (userId) {
    participants.push({
      user_id: userId,
      status: "ACCEPTED",
    });
  }
  if (user?.responsible_user_id) {
    participants.push({
      responsible_user_id: user.responsible_user_id,
      status: "ACCEPTED",
    });
  }
  return participants;
}

function cloneAppointmentWithMoment(appointment: Appointment) {
  const appointmentWithoutMoment = clone({
    ...appointment,
    start: undefined,
    end: undefined,
  });
  return {
    ...appointmentWithoutMoment,
    start: appointment.start,
    end: appointment.end,
  };
}

const getters: GetterTree<CalendarState, RootState> = {
  activeAppointmentIndex(calendarState) {
    return calendarState.appointments.findIndex(
      (a: Appointment) => a.id === calendarState.activeAppointmentId,
    );
  },
};

const actions: ActionTree<CalendarState, RootState> = {
  getAppointmentsForUsers(module, userIds: number[]) {
    if (module.rootState === undefined || module.rootState.user === undefined) {
      return;
    }

    const individualDates =
      state.displayMode === "dayPerWeek" ? state.days : undefined;
    module.commit("loading", true);

    running_requests.forEach((cancel) => {
      cancel("cancel");
    });

    running_requests = [];

    return apiClient
      .get(`/appointments`, {
        params: {
          user_ids: userIds,
          start: state.days[0],
          end: state.days[state.days.length - 1],
          individual_dates: individualDates,
        },
        cancelToken: new CancelToken((cancel) => running_requests.push(cancel)),
      })
      .then((response) => {
        const appointments = response.data.appointments.map((obj) =>
          addMomentToModel(["start", "end"], obj),
        );

        if (
          response.data.users === undefined ||
          !Array.isArray(response.data.users)
        ) {
          throw new Error(
            "The returned users were either undefined or not an array.",
          );
        }
        if (
          response.data.patients === undefined ||
          !Array.isArray(response.data.patients)
        ) {
          throw new Error(
            "The returned patients were either undefined or not an array.",
          );
        }

        const patients = Object.fromEntries(
          response.data.patients.map((patient) => [
            patient.zis_number,
            patient,
          ]),
        );

        module.commit("replacePatients", patients);
        module.commit("replaceAppointments", appointments);
        module.state.nextOnLoadActions?.forEach((action) => action());
        module.commit("unsetNextOnLoadActions");

        module.commit("loading", false);
      })
      .catch((e) => {
        if (e.message !== "cancel") {
          throw e;
        }
      });
  },

  async getAppointmentProposals(module) {
    if (module.rootState === undefined || module.rootState.user === undefined) {
      return;
    }

    const startDateString = moment().format("YYYY-MM-DD");
    const endDateString = moment().add(20, "weeks").format("YYYY-MM-DD");

    const appointmentRepository = new AppointmentRepositoryUsingApi(apiClient);
    const user = module.rootState.user;
    const isDoctor = user.roles.some((r) => r.name === "doctor");
    const isFrontDesk = user.roles.some((r) => r.name === "front-desk");
    const doctorSeesAllProposals =
      user.healthcare_provider.online_scheduling_settings
        .practitioners_see_all_proposals;
    const showAllProposals =
      isFrontDesk || (isDoctor && doctorSeesAllProposals);

    const userIds = showAllProposals
      ? user.healthcare_provider.users
          .filter(({ roles }) => roles?.some((role) => role.name === "doctor"))
          .map(({ id }) => id)
      : [user.id];

    const data = await appointmentRepository.findAll({
      start: startDateString,
      end: endDateString,
      user_ids: userIds,
      status: "PROPOSED",
    });
    const appointments = data.appointments.map((obj) =>
      addMomentToModel(["start", "end"], obj),
    );
    module.commit("addPatients", data.patients ?? []);
    module.commit(
      "addAppointments",
      appointments.filter((appointment) => {
        module.state.user_ids.includes(
          getUserParticipants(appointment)[0]?.user_id,
        );
      }),
    );
    module.commit("addAppointmentProposals", appointments);
  },

  getAppointmentsForCheckedUsers({ state: calendarState, dispatch }) {
    if (calendarState.user_ids.length === 0) {
      return;
    }
    return dispatch("getAppointmentsForUsers", calendarState.user_ids);
  },

  filterAppointmentsByDate(module) {
    const days: moment.Moment[] = module.state.days.map((day: string) =>
      moment(day),
    );
    module.state.appointments = module.state.appointments.filter((app) =>
      days.some(
        (day) => app.start.isSame(day, "day") || app.end.isSame(day, "day"),
      ),
    );
  },

  filterAppointmentsByUser(module) {
    module.state.appointments = module.state.appointments.filter((app) =>
      app.participants.some((participant) =>
        module.state.user_ids.includes(participant.user_id ?? 0),
      ),
    );
  },

  addUnpersistedAppointment(
    { commit, rootState },
    {
      start,
      end,
      appointment_type_id,
      user_id,
    }: {
      start: Moment;
      end: Moment;
      appointment_type_id: number;
      user_id?: number;
    },
  ) {
    const participants = getDefaultParticipants(rootState.user, user_id);
    const newAppointment: UnpersistedAppointment = {
      start,
      end,
      participants,
      appointment_type_id,
      status: "BOOKED",
      email_sents: [],
    };
    commit("deactivateAppointment");
    commit("addUnpersistedAppointment", newAppointment);
  },

  activateAppointment({ state: calendarState, commit }, appointmentId: number) {
    commit("deactivateAppointment");
    const appointment = calendarState.appointments.find(
      (app) => app.id === appointmentId,
    );
    if (appointment === undefined) {
      throw new Error(
        `Could not find appointment with id ${appointmentId} to activate.`,
      );
    }

    if (!appointment.referral_id && appointment.referral) {
      throw new Error("Expected referral_id to be set if referral is set");
    }
    commit("activateAppointment", appointment);
  },

  pasteAppointment({ state: calendarState, commit }) {
    const checkForUserSwitch = (
      pastedAppointment: CopiedAppointment,
      unpersistedAppointment: UnpersistedAppointment,
    ): UnpersistedParticipant[] => {
      let participants;
      if (
        calendarState.user_ids.length === 1 ||
        calendarState.displayMode === "day"
      ) {
        participants = clone(pastedAppointment.participants).filter(
          (p: UnpersistedParticipant) => {
            return p.user_id === undefined;
          },
        );

        const newUser = clone(unpersistedAppointment.participants).find(
          (p: UnpersistedParticipant) => p.user_id !== undefined,
        );
        if (newUser) {
          participants.push(newUser);
        }
      } else {
        participants = pastedAppointment.participants;
      }
      return participants;
    };

    if (
      calendarState.copiedAppointment &&
      calendarState.unpersistedAppointment
    ) {
      const appointment_type_id =
        calendarState.copiedAppointment.appointment_type_id;
      const participants = checkForUserSwitch(
        calendarState.copiedAppointment,
        calendarState.unpersistedAppointment,
      );
      const referral_id = calendarState.copiedAppointment.referral_id;
      const duration = calendarState.copiedAppointment.duration;

      const newAppointment: CopiedAppointment = {
        participants: participants,
        appointment_type_id,
        status: "BOOKED",
        email_sents: [],
        referral_id,
        end: calendarState.unpersistedAppointment.start
          .clone()
          .add(duration, "minutes"),
      };
      commit("unpersistedAppointmentEdit", newAppointment);
      if (calendarState.copiedPatient) {
        commit("addPatient", calendarState.copiedPatient);
      }
    } else if (
      calendarState.cuttedAppointment &&
      calendarState.unpersistedAppointment
    ) {
      const duration = calendarState.cuttedAppointment.end.diff(
        calendarState.cuttedAppointment.start,
        "minutes",
      );
      const start = calendarState.unpersistedAppointment.start;
      const end = start.clone().add(duration, "minutes");
      const participants = checkForUserSwitch(
        calendarState.cuttedAppointment,
        calendarState.unpersistedAppointment,
      );

      const newProps = { start, end, participants };
      let index = calendarState.appointments.findIndex(
        (a) => a.id === calendarState.cuttedAppointment?.id,
      );
      if (calendarState.cuttedPatient) {
        commit("addPatient", calendarState.cuttedPatient);
      }
      if (index < 0) {
        commit("addAppointment", calendarState.cuttedAppointment);
        index = calendarState.appointments.findIndex(
          (a) => a.id === calendarState.cuttedAppointment?.id,
        );
      }

      if (index !== -1) {
        commit("activeAppointmentEdit", {
          newProps,
          activeAppointmentIndex: index,
        });
        calendarState.unpersistedAppointment = undefined;
        if (!calendarState.cuttedAppointment) {
          throw new Error("cutted appointment was undefined");
        }
        commit("activateAppointment", calendarState.cuttedAppointment);
      }
    }
  },
  addCuttedAppointmentToClipboard(
    { state: calendarState, commit },
    appointment: Appointment,
  ) {
    const patientZisNumber = appointment.participants.find(
      (p) => p.patient_zis_number,
    )?.patient_zis_number;
    let patient = undefined;
    if (patientZisNumber) {
      patient = calendarState.patients[patientZisNumber] as PatientLocalCache;
    }
    commit("cutAppointment", appointment);
    commit("cutPatient", patient);
  },
  addCopiedAppointmentToClipboard(
    { state: calendarState, commit },
    appointment: Appointment,
  ) {
    const appointment_type_id = appointment.appointment_type_id;
    const description = appointment.description;
    const participants = appointment.participants;
    const referral_id = appointment.referral_id;
    const private_value = appointment.private;
    const duration = appointment.end.diff(appointment.start, "minutes");

    const copy: CopiedAppointment = {
      appointment_type_id,
      email_sents: [],
      status: "BOOKED",
      participants,
      referral_id,
      description,
      private: private_value,
      duration: duration,
    };

    const patientZisNumber = appointment.participants.find(
      (p) => p.patient_zis_number,
    )?.patient_zis_number;
    let patient = undefined;
    if (patientZisNumber) {
      patient = calendarState.patients[patientZisNumber] as PatientLocalCache;
    }
    commit("copyAppointment", copy);
    commit("copyPatient", patient);
  },

  editAppointment(
    module,
    {
      newProps,
      activeAppointmentIndex,
      appointment,
    }: {
      newProps: Partial<Appointment | UnpersistedAppointment>;
      activeAppointmentIndex?: number;
      appointment?: Appointment;
    },
  ) {
    if (newProps.participants) {
      const areParticipantsEqual = (
        part1: (typeof newProps.participants)[0],
        part2: (typeof newProps.participants)[0],
      ): boolean => {
        return (
          part1.user_id === part2.user_id &&
          part1.patient_zis_number === part2.patient_zis_number &&
          part1.location_id === part2.location_id &&
          part1.company_division_id === part2.company_division_id &&
          part1.responsible_user_id === part2.responsible_user_id
        );
      };

      let uniqueParticipants = newProps.participants.filter(
        (part1, index, self) =>
          index ===
          self.findIndex((part2) => areParticipantsEqual(part1, part2)),
      );
      if (uniqueParticipants.length === 0) {
        uniqueParticipants = getDefaultParticipants(module.rootState.user);
      }
      newProps = { ...newProps, participants: uniqueParticipants };
    }

    if (state.unpersistedAppointment) {
      module.commit("unpersistedAppointmentEdit", newProps);
    } else if (appointment !== undefined) {
      const index = module.state.appointments.findIndex(
        (a) => a.id === appointment.id,
      );
      if (index !== -1) {
        module.commit("activeAppointmentEdit", {
          newProps,
          activeAppointmentIndex: index,
        });
      }
    } else {
      if (activeAppointmentIndex !== -1) {
        module.commit("activeAppointmentEdit", {
          newProps,
          activeAppointmentIndex,
        });
      }
    }
  },

  cancelEdit({ commit, getters }, { force = true }: { force: boolean }) {
    if (!force) {
      // TODO: add some confirmation
    }
    const activeAppointmentIndex = getters.activeAppointmentIndex as number;
    if (activeAppointmentIndex === -1) {
      commit("confirmDeleteUnpersisted", true);
      return;
    }

    commit("replaceAppointmentWithOriginal", activeAppointmentIndex);
    commit("deactivateAppointment");
  },

  reloadAppointment({ commit }, appointmentId: number) {
    return apiClient
      .get("/appointments/:id", {
        params: {
          id: appointmentId,
        },
      })
      .then((response) => {
        const index = this.state.calendar.appointments.findIndex(
          (appointment) => appointment.id === appointmentId,
        );
        const appointment = addMomentToModel(["start", "end"], response.data);
        if (index < 0) {
          commit("addAppointment", appointment);
        } else {
          commit("replaceAppointment", {
            index,
            appointment,
          });
        }
        return response;
      })
      .catch(() => {
        commit("removeAppointment", appointmentId);
      });
  },

  async reloadAppointmentForEmailSents(
    { dispatch },
    { appointmentId, shouldHaveEmailSents, maxTries = 5 },
  ) {
    let tries = 0;
    let appointment = this.state.calendar.appointments.find(
      (appointment) => appointment.id === appointmentId,
    );
    if (!appointment) {
      throw new Error("could not find appointment with id " + appointmentId);
    }
    while (
      appointment.email_sents.length < shouldHaveEmailSents &&
      tries < maxTries
    ) {
      await timeout(500 + tries * 250);
      appointment = this.state.calendar.appointments.find(
        (appointment) => appointment.id === appointmentId,
      );
      if (!appointment) {
        return;
      }
      await dispatch("reloadAppointment", appointmentId);
      tries++;
    }
  },

  async directlyUpdateAppointmentStatus(
    { commit },
    payload: { id: number; status: AppointmentStatus },
  ) {
    const res = await apiClient.patch(`/appointments/${payload.id}/status`, {
      status: payload.status,
    });
    const index = this.state.calendar.appointments.findIndex(
      (appointment) => appointment.id === payload.id,
    );
    commit("replaceAppointment", {
      index,
      appointment: addMomentToModel(["start", "end"], res.data.appointment),
    });
  },

  saveAppointment(
    { state: calendarState, commit, rootState },
    params?: {
      appointmentId?: number;
      suppressMail?: boolean;
      qrrsTemplateUuid?: string;
      ignoreOverlap?: boolean;
    },
  ) {
    let appointment: Appointment | UnpersistedAppointment | undefined;
    if (params?.appointmentId !== undefined) {
      appointment = calendarState.appointments.find(
        (a) => a.id === params.appointmentId,
      );
    } else if (calendarState.unpersistedAppointment !== undefined) {
      appointment = calendarState.unpersistedAppointment;
    }
    if (appointment === undefined) {
      throw new Error(
        `Appointment ${params?.appointmentId} to be saved was undefined.`,
      );
    }

    // Appointment to be saved is loaded into the appointment variable.
    if (rootState.user === undefined) {
      throw new Error("user was undefined");
    }
    const payload: StoreAppointmentPayload = createStoreAppointmentPayload(
      appointment,
      params?.suppressMail,
      "id" in appointment &&
        calendarState.cuttedAppointment?.id === appointment.id,
      params?.qrrsTemplateUuid,
      params?.ignoreOverlap,
    );

    commit("loading", true);
    if (isPersisted(appointment)) {
      return apiClient
        .put("/appointments/:id", payload, {
          params: {
            id: appointment.id,
          },
        })
        .then((response) => {
          commit("replaceAppointment", {
            index: this.state.calendar.appointments.findIndex(
              (appointment) => appointment.id === params?.appointmentId,
            ),
            appointment: addMomentToModel(
              ["start", "end"],
              response.data.appointment,
            ),
          });
          return response;
        })
        .finally(() => {
          commit("loading", false);
        });
    }

    return apiClient
      .post("/appointments", payload)
      .then((response) => {
        const persistedAppointment = addMomentToModel(
          ["start", "end"],
          response.data.appointment,
        );
        commit("persistAppointment", persistedAppointment);
        return response;
      })
      .finally(() => {
        commit("loading", false);
      });
  },

  removeAppointment(
    { state: calendarState, commit, rootState },
    appointmentId: number | Appointment,
  ) {
    const appointment =
      typeof appointmentId === "number"
        ? calendarState.appointments.find((a) => a.id === appointmentId)
        : appointmentId;

    if (appointment === undefined) {
      throw new Error(
        `Appointment ${appointmentId} to be removed was undefined.`,
      );
    }

    // Appointment to be removed is loaded into the appointment variable.
    if (rootState.user === undefined) {
      throw new Error("user was undefined");
    }

    return apiClient
      .delete("/appointments/:id", {
        params: {
          id: appointment.id,
        },
      })
      .then((response) => {
        commit("removeAppointment", appointment.id);
        return response;
      });
  },
};

const mutations: MutationTree<CalendarState> = {
  selectedPatientZisNumber(calendarState, zisNumber: number) {
    calendarState.selectedPatientZisNumber = zisNumber;
  },

  suggestFor(calendarState, newSuggestFor: number | null) {
    calendarState.suggestFor = newSuggestFor;
  },

  slotHeight(calendarState, newSlotHeight: number) {
    calendarState.slotHeight = newSlotHeight;
  },

  unpersistedAppointmentEdit(
    calendarState,
    newProps: Partial<UnpersistedAppointment>,
  ) {
    calendarState.unpersistedAppointment = Object.assign(
      calendarState.unpersistedAppointment || {},
      newProps,
    ) as UnpersistedAppointment;
  },

  activeAppointmentEdit(
    calendarState,
    {
      newProps,
      activeAppointmentIndex,
    }: {
      newProps: Partial<UnpersistedAppointment>;
      activeAppointmentIndex: number;
    },
  ) {
    const activeAppointment =
      calendarState.appointments[activeAppointmentIndex];
    const originalAppointments = calendarState.originalAppointments;

    if (!originalAppointments[calendarState.activeAppointmentId]) {
      calendarState.originalAppointments[activeAppointment.id] =
        cloneAppointmentWithMoment(activeAppointment);
    }

    const editedAppointment = Object.assign(activeAppointment, newProps);
    if (
      !editedAppointment.start ||
      !editedAppointment.end ||
      !editedAppointment.participants
    ) {
      Sentry.captureException(
        new Error(
          `tried to add appointment without fields ${editedAppointment}`,
        ),
      );
    }
    calendarState.appointments[activeAppointmentIndex] =
      editedAppointment as Appointment;
  },

  loading(calendarState, value: boolean) {
    calendarState.loading = value;
  },

  confirmDeleteUnpersisted(calendarState, deleteValue: boolean) {
    if (deleteValue === true) {
      calendarState.unpersistedAppointment = undefined;
      calendarState.unpersistedAppointmentIsDirty = false;
    }
    calendarState.confirmDeleteUnpersisted = false;
  },

  replaceAppointments(calendarState, appointments: Appointment[]) {
    if (
      calendarState.appointments.some(
        (a) => !a.start || !a.end || !a.participants,
      )
    ) {
      Sentry.captureException(
        new Error(
          `tried to add appointment without fields ${calendarState.appointments}`,
        ),
      );
    }
    calendarState.appointments = appointments;
  },

  addAppointments(calendarState, appointments: Appointment[]) {
    if (
      calendarState.appointments.some(
        (a) => !a.start || !a.end || !a.participants,
      )
    ) {
      Sentry.captureException(
        new Error(
          `tried to add appointment without fields ${calendarState.appointments}`,
        ),
      );
    }
    appointments.forEach((appointment) => {
      if (!calendarState.appointments.some((a) => a.id == appointment.id)) {
        calendarState.appointments.push(appointment);
      }
    });
  },

  addAppointmentProposals(calendarState, appointments: AppointmentProposal[]) {
    calendarState.appointmentProposals = appointments;
  },

  replacePatients(
    calendarState,
    patients: { [key: number]: PatientLocalCache },
  ) {
    let activePatient = undefined;
    if (calendarState.selectedPatientZisNumber) {
      activePatient =
        calendarState.patients[calendarState.selectedPatientZisNumber];
    }
    calendarState.patients = patients;
    if (activePatient) {
      calendarState.patients[activePatient.zis_number] = activePatient;
    }
  },

  addPatient(calendarState, patient: PatientLocalCache) {
    calendarState.patients[patient.zis_number] = patient;
  },

  addPatients(calendarState, patients: PatientLocalCache[]) {
    patients.forEach(
      (patient) => (calendarState.patients[patient.zis_number] = patient),
    );
  },

  addUnpersistedAppointment(
    calendarState,
    appointment: UnpersistedAppointment,
  ) {
    if (calendarState.unpersistedAppointment) {
      if (calendarState.unpersistedAppointmentIsDirty) {
        calendarState.confirmDeleteUnpersisted = true;
      } else {
        calendarState.unpersistedAppointment = undefined;
      }
      return;
    }
    calendarState.unpersistedAppointment = appointment;
    if (calendarState.selectedPatientZisNumber) {
      calendarState.unpersistedAppointment.participants.push({
        patient_zis_number: calendarState.selectedPatientZisNumber,
        status: "ACCEPTED",
      });
    }
  },

  addAppointment(calendarState, appointment: Appointment) {
    if (!appointment.start || !appointment.end || !appointment.participants) {
      Sentry.captureException(
        new Error(`tried to add appointment without fields ${appointment}`),
      );
    }
    if (!calendarState.appointments.some((a) => a.id == appointment.id)) {
      calendarState.appointments.push(appointment);
    }
  },

  removeAppointment(calendarState, appointmentId) {
    const appointmentIndex = calendarState.appointments.findIndex(
      (appointment) => appointment.id === appointmentId,
    );
    if (appointmentIndex === -1) {
      return;
    }

    calendarState.activeAppointmentId = -1;
    calendarState.appointments.splice(appointmentIndex, 1);
  },

  persistAppointment(calendarState, appointment: Appointment) {
    calendarState.unpersistedAppointment = undefined;
    calendarState.selectedPatientZisNumber = undefined;
    calendarState.appointments.push(appointment);
  },
  copyAppointment(calendarState, appointment: CopiedAppointment) {
    calendarState.cuttedAppointment = undefined;
    calendarState.copiedAppointment = appointment;
  },
  cutAppointment(calendarState, appointment: Appointment) {
    calendarState.copiedAppointment = undefined;
    calendarState.cuttedAppointment = appointment;
  },
  copyPatient(calendarState, patient?: PatientLocalCache) {
    calendarState.cuttedPatient = undefined;
    calendarState.copiedPatient = patient;
  },
  cutPatient(calendarState, patient?: PatientLocalCache) {
    calendarState.copiedPatient = undefined;
    calendarState.cuttedPatient = patient;
  },
  activateAppointment(calendarState, appointment: Appointment) {
    if (
      calendarState.unpersistedAppointmentIsDirty &&
      calendarState.unpersistedAppointment
    ) {
      calendarState.confirmDeleteUnpersisted = true;
      return;
    }
    calendarState.unpersistedAppointment = undefined;
    calendarState.selectedPatientZisNumber = undefined;

    calendarState.originalAppointments[appointment.id] =
      cloneAppointmentWithMoment(appointment);
    calendarState.activeAppointmentId = appointment.id;
  },

  deactivateAppointment(calendarState) {
    calendarState.activeAppointmentId = -1;
  },

  /**
   * Replace appointment after it has been saved.
   */
  replaceAppointment(
    calendarState,
    { index, appointment }: { index: number; appointment: Appointment },
  ) {
    if (index < 0 || index >= calendarState.appointments.length) {
      throw new Error(
        `Got invalid index ${index}. Expected between 0 and ${calendarState.appointments.length}`,
      );
    }
    if (!appointment.start || !appointment.end || !appointment.participants) {
      Sentry.captureException(
        new Error(`tried to add appointment without fields ${appointment}`),
      );
    }
    calendarState.appointments[index] = appointment;
    calendarState.originalAppointments[appointment.id] = appointment;
  },

  replaceAppointmentWithOriginal(calendarState, appointmentIndex: number) {
    const appointmentId = calendarState.appointments[appointmentIndex].id;
    const original = calendarState.originalAppointments[appointmentId];

    if (!original.start || !original.end || !original.participants) {
      Sentry.captureException(
        new Error(`tried to add appointment without fields ${original}`),
      );
    }
    calendarState.appointments[appointmentIndex] = original;
    delete calendarState.originalAppointments[appointmentId];
  },

  addNextOnLoadAction(calendarState, action: () => void) {
    calendarState.nextOnLoadActions.push(action);
  },

  unsetNextOnLoadActions(calendarState) {
    calendarState.nextOnLoadActions = [];
  },
};

const modules = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};

export function createStoreAppointmentPayload(
  appointment: Appointment | UnpersistedAppointment,
  suppressMail?: boolean,
  cutAppointment?: boolean,
  questionnaireResponseRequestSchemaTemplateUuid?: string,
  ignoreOverlap?: boolean,
): StoreAppointmentPayload {
  return {
    description: appointment.description,
    status: appointment.status,
    start: appointment.start.format("YYYY-MM-DD HH:mm:ss"),
    end: appointment.end.format("YYYY-MM-DD HH:mm:ss"),
    participants: appointment.participants,
    referral_id: appointment.referral_id,
    appointment_type_id: appointment.appointment_type_id,
    private: appointment.private,
    suppress_email: suppressMail,
    cut_appointment: cutAppointment,
    questionnaire_response_request_schema_template_uuid:
      questionnaireResponseRequestSchemaTemplateUuid,
    ignore_overlap: ignoreOverlap,
  };
}
