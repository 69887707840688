<template>
  <input
    class="m-0 h-4 w-4 flex-shrink-0 appearance-none rounded border border-border-quaternary bg-background-secondary transition duration-75 focus:border-blue-600 focus:outline-none focus:ring"
    v-model="value"
    :value="optionValue"
    ref="input"
    type="checkbox"
    :disabled="disabled"
    :class="[
      disabled
        ? 'checked: cursor-not-allowed checked:bg-gray-300'
        : 'cursor-pointer checked:border-transparent checked:bg-blue-600 indeterminate:border-blue-600 indeterminate:bg-blue-600  ',
    ]"
    :style="{
      'background-image': backgroundImage,
    }"
  />
</template>

<script setup lang="ts" generic="T">
import FormManager from "@/libraries/managers/FormManager";
import { inject, onMounted, ref, watch, computed } from "vue";

const input = ref<HTMLInputElement | null>(null);

const modelValue = defineModel<T[] | boolean>({ required: true });

const props = withDefaults(
  defineProps<{
    optionValue?: T;
    indeterminate?: boolean;
    disabled?: boolean;
    prop?: string;
  }>(),
  {
    disabled: false,
  },
);

const value = computed({
  get() {
    return modelValue.value;
  },
  set(value) {
    if (formManager && props.prop) {
      formManager.setError(props.prop, false);
    }
    if (typeof value === "boolean") {
      modelValue.value = value;
    } else {
      if (props.optionValue === undefined) {
        throw new Error("cannot add undefined");
      }
      if (value.includes(props.optionValue)) {
        modelValue.value = value;
      } else {
        modelValue.value = value.filter((v) => v !== props.optionValue);
      }
    }
  },
});

watch(
  () => props.indeterminate,
  (indeterminate) => {
    if (props.indeterminate !== undefined) {
      input.value!.indeterminate = props.indeterminate;
    }
  },
);

const formManager = inject<FormManager | null>("formManager", null);

const checked = computed(() => {
  if (typeof modelValue.value === "boolean") {
    return modelValue.value;
  } else {
    if (props.optionValue === undefined) {
      throw new Error("cannot add undefined");
    }
    return modelValue.value.includes(props.optionValue);
  }
});

onMounted(() => {
  if (!formManager) {
    return;
  }

  if (props.prop && input.value) {
    formManager.register({
      el: input.value,
      prop: props.prop,
      error: false,
    });
  }
});

const backgroundImage = computed(() => {
  if (props.indeterminate) {
    return indeterminateBackgroundImage;
  }

  if (checked.value) {
    return checkedBackgroundImage;
  }
});

const checkedBackgroundImage =
  "url(\"data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e\")";

const indeterminateBackgroundImage =
  "url(\"data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3crect  x='3' y='7' width='10' height='2' rx='1' /%3e%3c/svg%3e\")";
</script>
