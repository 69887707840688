<template>
  <InboxLayout :items="medicalConversations">
    <template #title>
      <p class="text-lg font-semibold md:text-2xl">Inkomende berichten</p>
    </template>
    <template #item="{ item, activeItem }">
      <InboxItem
        :isActive="activeItem?.id === item.id"
        :isProcessed="itemIsProcessed(item)"
        :time="item.updated_at ?? item.created_at"
      >
        <template #name>
          <p v-if="item.patient.zdPatient !== undefined">
            {{ item.patient.zdPatient?.name[0]?.text }}
          </p>
          <AsyncPatientLink
            v-if="item.patient.patientZisNumber"
            :zis-number="item.patient.patientZisNumber"
          />
          <p v-if="item.messages">
            {{
              $t(
                `medical_converstion.message_type.${item.messages[item.messages.length - 1].type}`,
              )
            }}
          </p>
        </template>
        <template #type>
          <p>{{ item.sender.practitioner?.name[0]?.text }}</p>
        </template>
      </InboxItem>
    </template>
    <template #activeTitel="{ item }">
      <div
        class="border-b-[1px] border-border-secondary p-4 font-semibold md:pl-12"
      >
        <p>{{ item.patient.zdPatient?.name[0]?.text }}</p>
      </div>
    </template>
    <template #activeItem="{ item }">
      <MedicalConversationComponent class="p-4" :conversation="item" />
      <flux-modal
        class="w-full"
        v-model:visible="showModal"
        title="Patiënt inschrijven"
        @close="showModal = false"
      >
        <PatientCreate>
          <template #sidePanel>
            <div class="sticky top-24">
              <MedicalConversationComponent class="p-4" :conversation="item" />
            </div>
          </template>
        </PatientCreate>
      </flux-modal>
    </template>
    <template #activeFooter="{ item }">
      <div
        class="flex items-center justify-end gap-x-4 border-t-[1px] border-border-secondary p-4"
      >
        <template v-if="item.patient.zdPatient">
          <flux-button size="small" type="default" @click="createPatient(item)">
            Patiënt aanmaken
          </flux-button>
          <flux-button
            size="small"
            type="default"
            @click="updatePatientConversation(item.id)"
          >
            Patiënt koppelen
          </flux-button>
        </template>
        <AsyncPatientLink
          v-if="item.patient.patientZisNumber"
          :zis-number="item.patient.patientZisNumber"
        />
        <flux-button
          size="small"
          text="Sluiten"
          type="default"
          @click="processConversation(item.id)"
        >
          {{
            itemIsProcessed(item) ? "Verwerking annuleren" : "Bericht verwerkt"
          }}
        </flux-button>
      </div>
    </template>
  </InboxLayout>
</template>
<script lang="ts" setup>
import InboxLayout from "@/components/ui/InboxLayout.vue";
import MedicalConversationComponent from "./MedicalConversation.vue";
import PatientCreate from "@/components/PatientCreate/PatientCreate.vue";
import { useMedicalConversations } from "@/composables/medicalConversations";
import { MedicalConversation } from "@/queries/medicalConversations/medicalConversations";
import { useFormAutoSave } from "@/composables/formAutoSaveManager";
import { ref } from "vue";
import moment from "moment";
import { PatientCreateForm } from "@/models/Forms";
import { clone } from "@/libraries/utils/clone";
import AsyncPatientLink from "../ui/AsyncPatientLink.vue";
import InboxItem from "./InboxItem.vue";

const {
  data: medicalConversations,
  processConversation,
  updatePatientConversation,
} = useMedicalConversations();

function itemIsProcessed(item: MedicalConversation) {
  if (item.updated_at === undefined || item.processed_at === undefined)
    return false;

  return (
    Temporal.PlainDateTime.compare(item.processed_at, item.updated_at) >= 0
  );
}

const showModal = ref(false);

function createPatient(item: MedicalConversation) {
  const input = clone(initialform);

  input.bsn =
    item.patient.zdPatient?.identifier?.find(
      (i) => i.system === "http://fhir.nl/fhir/NamingSystem/bsn",
    )?.value ?? "";
  input.date_of_birth = item.patient.zdPatient?.birthDate ?? "";

  input.addresses[0].postal_code =
    item.patient.zdPatient?.address?.[0]?.postalCode ?? "";
  input.addresses[0].city = item.patient.zdPatient?.address?.[0]?.city ?? "";

  saveData(input);
  showModal.value = true;
}

const patientForm = ref<PatientCreateForm | boolean>(false);

const { saveData: saveData } = useFormAutoSave(
  ref<string>("patientcreate"),
  ref<string>("/patients/create"),
  patientForm,
);

const initialform: PatientCreateForm = {
  first_names: "",
  nickname: "",
  surname: "",
  surname_prefix: "",
  maiden_name: "",
  maiden_name_prefix: "",
  gender: undefined,
  patient_title_uuid: null,
  custom_short_title: null,
  custom_long_title: null,
  salutation_template_uuid: null,
  custom_salutation: null,
  email_addresses: [
    {
      email_address: "",
      use_for_appointment: true,
      use_for_financial: true,
      use_for_medical: true,
    },
  ],
  phone_numbers: [
    {
      phone_number: "",
      phone_number_type: "MC",
      preferred: true,
    },
  ],
  date_of_birth: "",
  bsn: "",
  national_identity_number_type: "bsn",
  nationality_id: undefined,
  multiple_birth: false,
  initials: "",
  identity_documents: [
    {
      type: "id_card",
      number: "",
    },
  ],
  contacts: [],
  payer_insurers: [
    {
      number: "",
      type: "B",
      start_date: moment().startOf("year").format("YYYY-MM-DD"),
      end_date: moment().endOf("year").format("YYYY-MM-DD"),
      insurer_id: undefined,
      insurer_name: "",
      insurer_number: "",
      package_name: "",
    },
  ],
  addresses: [
    {
      address_type: "PHYS",
      street_name: "",
      house_number: "",
      postal_code: "",
      city: "",
      country_id: 159,
    },
  ],
  healthcare_professionals: [],
  languages: ["nl"],
  company_division_id: undefined,
  patient_treatment_count: {
    amount: "0",
    reference_year: new Date().getFullYear().toString(),
  },
};
</script>
