import { ContactRelationship, ContactRole } from "./Contact";
import { IdentityDocumentType } from "./IdentityDocument";
import { PhoneNumberType } from "./PhoneNumber";
import { HealthcareProfessionalRole } from "./HealthcareProfessional";
import { GenderType } from "./Patient";
import { AddressType } from "./Address";
import { PayerInsurerForm } from "./PayerInsurer";
import { Country } from "./Country";

export interface AddressForm {
  id: number;
  address_type: AddressType;
  street_name: string;
  house_number: string;
  postal_code: string;
  city: string;
  country_id: number;
}

export interface AddressCreateForm {
  address_type: AddressType;
  street_name: string;
  house_number: string;
  postal_code: string;
  city: string;
  country_id: number;
  country?: Country;
}
export interface HealthcareProfessionalForm {
  fullname: string;
  AGB_code?: string;
  UZI_code?: string;
  specialism_code?: string;
  healthcare_provider?: string;
  role: HealthcareProfessionalRole;
}

export const nationalIdentityNumberType = ["bsn", "other"] as const;
export type NationalIdentityNumberType =
  (typeof nationalIdentityNumberType)[number];

export interface PatientCreateForm {
  first_names: string;
  nickname: string;
  surname: string;
  surname_prefix: string;
  maiden_name: string;
  maiden_name_prefix: string;
  gender?: GenderType;
  patient_title_uuid: string | null;
  custom_short_title: string | null;
  custom_long_title: string | null;
  salutation_template_uuid: string | null;
  custom_salutation: string | null;
  addresses: AddressCreateForm[];
  email_addresses: PatientEmailAddressForm[];
  phone_numbers: PhoneNumberForm[];
  date_of_birth: string;
  contacts: ContactForm[];
  payer_insurers: PayerInsurerForm[];
  identity_documents: IdentityDocumentForm[];
  bsn: string;
  national_identity_number_type?: NationalIdentityNumberType;
  nationality_id?: number;
  multiple_birth: boolean;
  initials: string;
  healthcare_professionals: HealthcareProfessionalForm[];
  languages: string[];
  company_division_id?: string | undefined;
  employee_number?: string | undefined;
  patient_treatment_count: {
    amount: string;
    reference_year: string;
  };
}

export interface EmailAddressForm {
  id?: number;
  email_address: string;
}

export type PatientEmailAddressForm = EmailAddressForm & {
  use_for_appointment: boolean;
  use_for_financial: boolean;
  use_for_medical: boolean;
};

export interface PhoneNumberForm {
  preferred: boolean;
  phone_number: string;
  phone_number_type: PhoneNumberType;
}

export interface ContactForm {
  fullname: string;
  relationship: ContactRelationship;
  role: ContactRole;
  email_addresses: EmailAddressForm[];
  phone_numbers: PhoneNumberForm[];
  is_payer: boolean;
}

export interface IdentityDocumentForm {
  type: IdentityDocumentType;
  number: string;
}
