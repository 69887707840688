<template>
  <div
    class="flex gap-1"
    ref="root"
    :class="[
      groupType === 'table' || groupType === 'table-header'
        ? 'flex-row items-center'
        : 'flex-col items-start',
    ]"
    role="radiogroup"
    :aria-labelledby="uuid + '_label'"
  >
    <div class="max-w-prose" :id="uuid + '_label'" style="scroll-margin: 1rem">
      {{ item.text }}
    </div>
    <div
      class="items-center"
      v-if="displayMode === 'NRS'"
      :class="[groupType === 'table-header' ? 'contents' : 'flex']"
    >
      <div
        class="mr-1 text-xs"
        v-if="lowerBound && groupType !== 'table-header'"
      >
        {{ lowerBound }}
      </div>
      <div
        class="m-1 flex h-8 w-8 select-none place-content-center items-center place-self-center self-center rounded-full text-sm outline-none"
        v-for="(option, index) in options"
        role="radio"
        name="NRS"
        :tabindex="readonly ? -1 : getTabindex(option)"
        :value="option"
        :id="uuid + '_option_' + option"
        :style="{
          filter: `hue-rotate(${
            ((option - bounds[0]) / (bounds[1] - bounds[0])) * 120
          }deg)`,
        }"
        :class="{
          ['col_' + index]: true,
          'cursor-pointer focus:ring': !readonly,
          'bg-gray-200 ': value !== option,
          'hover:bg-gray-300 ': value !== option && !readonly,
          'bg-gradient-to-br from-blue-600 to-blue-800 font-semibold':
            value === option,
          'hover:bg-blue-900': value === option,
          'text-gray-100': value === option,
        }"
        :key="option"
        ref="optionElements"
        @mousedown="mouseDown = true"
        @mouseup="
          mouseDown = false;
          scrollToQuestion();
        "
        @mouseleave="mouseDown = false"
        @click="() => (readonly ? undefined : setValue(option))"
        :aria-checked="`${value === option}`"
        :aria-label="`${option}`"
        @keydown="keydown"
        @keyup="keyup"
        @focus="focus"
      >
        <label
          class="pointer-events-none select-none slashed-zero"
          :for="uuid + '_option_' + option"
          >{{ option }}</label
        >
      </div>
      <div
        class="ml-1 text-xs"
        v-if="upperBound && groupType !== 'table-header'"
      >
        {{ upperBound }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { debounce } from "debounce";

import {
  EXTENSION,
  ExtensionHasCode,
  IntegerItem,
} from "@/libraries/questionnaires/item";
import { computed, ref, useTemplateRef } from "vue";

type Answer = number;

const emit = defineEmits<{
  change: [Answer];
}>();

const props = withDefaults(
  defineProps<{
    leftLabel?: string | null;
    rightLabel?: string | null;
    item: IntegerItem;
    response: any;
    readonly: boolean;
    groupType?: string | number | string[];
  }>(),
  {
    leftLabel: null,
    rightLabel: null,
    readonly: false,
    groupType: "",
  },
);

const uuid = crypto.randomUUID();

const mouseDown = ref(false);

const el = useTemplateRef("root");
const optionEls = useTemplateRef<HTMLDivElement[]>("optionElements");

const focusQuestion = () => {
  el.value?.querySelector('[id*="label"]')?.scrollIntoView({
    block: "nearest",
    inline: "nearest",
  });
};

const bounds = computed(() => {
  if (!Array.isArray(props.item.extension)) {
    return [-Infinity, Infinity];
  }
  const minValue =
    props.item.extension?.find((ex) => ex.url === EXTENSION.MIN_VALUE)
      ?.valueDecimal ?? -Infinity;

  const maxValue =
    props.item.extension?.find((ex) => ex.url === EXTENSION.MAX_VALUE)
      ?.valueDecimal ?? Infinity;

  return [minValue, maxValue];
});

const findBoundingChildItem = (bound: "upper" | "lower") => {
  const boundItem = props.item.item?.find((item) => {
    if (item.extension === undefined) {
      return;
    }
    const extension = item.extension;
    return extension?.some((extension) =>
      ExtensionHasCode(extension, EXTENSION.ITEM_CONTROL, bound),
    );
  });

  if (boundItem?.type !== "display") {
    return undefined;
  }

  return boundItem;
};

const lowerBound = computed(() => findBoundingChildItem("lower")?.text);
const upperBound = computed(() => findBoundingChildItem("upper")?.text);

const options = computed(() => {
  const [min, max] = bounds.value;

  if (!Number.isSafeInteger(min) || !Number.isSafeInteger(max)) {
    throw new Error("Got unsafe integer");
  }

  return new Array(max - min + 1).fill(0).map((val, idx) => min + idx);
});

const value = computed({
  get: () => props.response[props.item.linkId],
  set: (val: Answer) => {
    props.response[props.item.linkId] = val;
    emit("change", val);
    focusQuestion();
  },
});

const displayMode = "NRS" as const;

const getTabindex = (option: number): 0 | -1 => {
  if (value.value !== null) {
    return value.value == option ? 0 : -1;
  }

  const [min] = bounds.value;
  return option === min ? 0 : -1;
};

function isValid(n: unknown): n is Answer {
  if (typeof n !== "number") {
    return false;
  }

  return bounds.value[0] <= n && n <= bounds.value[1];
}

const debouncedClearKeyBuffer = debounce(() => clearKeyBuffer(), 300);
const keyBuffer = ref<number[]>([]);
const keyup = (ev: KeyboardEvent) => {
  if (ev.key === "Tab") {
    focusQuestion();
  }
};

const focus = () => {
  if (mouseDown.value === false) {
    focusQuestion();
  }
};

function keydown(ev: KeyboardEvent) {
  if (props.readonly) {
    return;
  }

  if (ev.key == "Enter" || ev.key == " ") {
    handleEnter(ev);
    scrollToQuestion();
    ev.preventDefault();
  }
  if (ev.key == "ArrowRight" || ev.key == "ArrowDown") {
    handleRight(ev);
    scrollToQuestion();
    ev.preventDefault();
  }

  if (ev.key == "ArrowLeft" || ev.key == "ArrowUp") {
    handleLeft(ev);
    scrollToQuestion();
    ev.preventDefault();
  }

  const val = parseInt(ev.key, 10);
  if (Number.isSafeInteger(val)) {
    keyBuffer.value.push(val);

    const bufferVal = parseKeyBuffer();
    if (isValid(bufferVal)) {
      setValue(bufferVal);
      debouncedClearKeyBuffer();
    } else {
      if (isValid(val)) {
        setValue(val);
      }

      clearKeyBuffer();
    }
  }
}

function setValue(val: Answer) {
  props.response[props.item.linkId] = val;
  emit("change", val);
  focusOnOption(val);
}

function parseKeyBuffer(): number | undefined {
  if (keyBuffer.value.length === 0) {
    return undefined;
  }
  const bufferAsString = keyBuffer.value.join("");
  return parseInt(bufferAsString, 10);
}

function clearKeyBuffer() {
  keyBuffer.value = [];
}

function handleLeft(ev: KeyboardEvent) {
  const oldVal = getValueFromEvent(ev);
  const val =
    ((oldVal - bounds.value[0] - bounds.value[0] + bounds.value[1]) %
      (1 - bounds.value[0] + bounds.value[1])) +
    bounds.value[0];
  if (!isValid(val)) {
    throw new Error("Got unexpected value");
  }
  setValue(val);
}

function handleRight(ev: KeyboardEvent) {
  const oldVal = getValueFromEvent(ev);
  const val =
    ((oldVal + 1 - bounds.value[0]) % (1 - bounds.value[0] + bounds.value[1])) +
    bounds.value[0];
  if (!isValid(val)) {
    throw new Error("Got unexpected value");
  }
  setValue(val);
}

function handleEnter(ev: KeyboardEvent) {
  const val = getValueFromEvent(ev);
  setValue(val);
}

function getValueFromEvent(ev: KeyboardEvent): Answer {
  const el = ev.target as HTMLDivElement;
  const val = parseInt(el.attributes.getNamedItem("value")?.value ?? "0", 10);
  if (!isValid(val)) {
    throw new Error("Got unexpected value");
  }
  return val;
}

function focusOnOption(option: Answer) {
  const optionElement = optionEls.value?.[options.value.indexOf(option)];
  if (optionElement instanceof HTMLElement) {
    optionElement?.focus();
    return;
  }
}

function scrollToQuestion() {
  el.value?.querySelector('[id*="_label"]')?.scrollIntoView({
    block: "nearest",
    inline: "nearest",
  });
}
</script>
