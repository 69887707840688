<template>
  <div class="flex items-center justify-between">
    <slot name="header">
      <div class="flex items-center space-x-4">
        <slot name="icon"></slot>
        <div class="flex flex-col">
          <h1 class="m-0 text-xl font-medium text-text-primary">
            {{ title }}
          </h1>
          <h2
            class="m-0 text-sm font-normal text-text-tertiary"
            v-if="subtitle"
          >
            {{ subtitle }}
          </h2>
        </div>
        <slot name="suffix"></slot>
      </div>
      <slot name="action">
        <flux-button
          v-if="showAction && action"
          type="text"
          size="small"
          :icon="icon"
          :disabled="actionDisabled"
          @click="emit('action')"
          >{{ action }}</flux-button
        >
      </slot>
    </slot>
  </div>
</template>

<script lang="ts" setup>
withDefaults(
  defineProps<{
    title: string;
    subtitle?: string;
    showAction?: boolean;
    action?: string;
    actionDisabled?: boolean;
    icon?: string;
  }>(),
  {
    showAction: true,
    actionDisabled: false,
  },
);

const emit = defineEmits<{
  action: [];
}>();
</script>
