import { TranslateResult } from "vue-i18n";
import {
  BooleanFilterStatus,
  FilterLogicOption,
  FilterLogicOptionLogic,
} from "../filter";

export function createBooleanFilter({
  subject,
  prefix,
  queryStringKey,
  value,
  logicOptions,
  logicOptionLogic,
  logicTranslation,
  rememberKey,
}: {
  subject: string | TranslateResult;
  prefix: string | TranslateResult;
  queryStringKey: string;
  value: any;
  logicOptions?: FilterLogicOption[];
  logicOptionLogic?: FilterLogicOptionLogic;
  logicTranslation?: Map<FilterLogicOption, string>;
  rememberKey?: string;
}) {
  const defaultFilterStatus: BooleanFilterStatus = {
    selectedLogic: logicOptions?.at(0) ?? "EQ",
  };

  return {
    uuid: crypto.randomUUID(),
    filterType: "boolean" as const,
    subject,
    prefix,
    queryStringKey,
    value,
    logicOptions,
    logicOptionLogic,
    logicTranslation,
    defaultFilterStatus,
    rememberKey,
  };
}
