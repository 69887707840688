import { useQueryClient, useQuery, useMutation } from "@tanstack/vue-query";
import { apiClient } from "@/libraries/utils/axios";
import { Ref } from "vue";
import { useNotify } from "@/composables/notify";
import { ConsentType, PatientConsentAction } from "@/models/Consent";
import { getPatientConsents } from "@/queries/patientConsents/patientConsents";

const gcTime = 1000 * 60 * 5;
const staleTime = 1000 * 60 * 60 * 24;

export function usePatientConsents(zisNumber: Ref<number>) {
  const queryClient = useQueryClient();
  function invalidateLdfConsent(zisNumber: number) {
    queryClient.invalidateQueries({
      queryKey: ["patient_consents", zisNumber],
    });
  }

  const query = useQuery({
    gcTime,
    staleTime,
    retry: 1,
    queryKey: ["patient_consents", zisNumber] as const,
    queryFn: ({ queryKey }) => getPatientConsents(queryKey[1]),
  });

  return { ...query, invalidateLdfConsent };
}

export function updatePatientConsent(zisNumber: Ref<number>) {
  const queryClient = useQueryClient();
  const { notify } = useNotify();

  const mutation = useMutation({
    mutationFn: (payload: {
      action: PatientConsentAction;
      type: ConsentType;
    }) => mutatePatientConsent(zisNumber.value, payload.action, payload.type),
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: ["patient_consents", zisNumber.value],
      });
    },
    onError: () => {
      notify({
        message: "Consent opslaan mislukt",
        type: "error",
      });
      queryClient.invalidateQueries({
        queryKey: ["patient_consents", zisNumber.value],
      });
    },
    onSuccess: () => {
      notify({
        message: "Opslaan consent gelukt",
        type: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["patient_consents", zisNumber],
      });
    },
  });

  return {
    updatePatientConsent: (payload: {
      action: PatientConsentAction;
      type: ConsentType;
    }) => mutation.mutateAsync(payload, {}),
    isMutating: mutation.isPending,
  };
}

async function mutatePatientConsent(
  zisNumber: number,
  action: PatientConsentAction,
  type: ConsentType,
) {
  await apiClient.patch(`/patients/${zisNumber}/consent`, { type, action });
}
