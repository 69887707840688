<template>
  <div
    class="inline-block"
    @mouseenter="startTimer"
    @mouseover="startTimer"
    @mouseleave="
      visible = false;
      active = false;
    "
    ref="reference"
  >
    <slot />
    <Teleport :disabled="teleportDisabled" to="body">
      <transition
        enter-active-class="duration-200 ease-out"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-200 ease-out"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <Suspense v-if="visible">
          <div
            class="transition-filter pointer-events-none z-50 select-none rounded border border-border-secondary px-2 py-1.5 text-xs text-text-primary shadow-sm transition-opacity"
            :class="
              inModal || inHeader
                ? 'bg-background-primary  '
                : 'bg-background-primary/85 backdrop-blur'
            "
            ref="floating"
            :style="floatingStyles"
          >
            <slot name="content"
              ><span class="text-nowrap">{{ content }}</span></slot
            >
          </div>
        </Suspense>
      </transition>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import {
  useFloating,
  shift,
  flip,
  offset,
  autoUpdate,
  Placement,
} from "@floating-ui/vue";
import { PropType, computed, inject, ref, watch } from "vue";

const props = defineProps({
  content: {
    type: String,
  },
  placement: {
    default: "top",
    type: String as PropType<Placement>,
  },
  disabled: {
    default: false,
    type: Boolean,
  },
  openDelay: {
    default: 150,
    type: Number,
  },
});

const emit = defineEmits<{
  show: [];
  hide: [];
}>();

const inModal = inject<boolean>("inModal", false);
const inHeader = inject<boolean>("inHeader", false);
const teleportDisabled = inModal;

const reference = ref(null);
const floating = ref(null);
const visible = ref(false);
const active = ref(false);

function startTimer() {
  active.value = true;
  setTimeout(showTooltip, props.openDelay);
}
function showTooltip() {
  if (active.value && !props.disabled) {
    visible.value = true;
  }
}

watch(visible, () => {
  if (!visible.value) {
    emit("hide");
  } else {
    emit("show");
  }
});

const { floatingStyles } = useFloating(reference, floating, {
  placement: computed(() => props.placement),
  middleware: [offset(4), flip(), shift()],
  whileElementsMounted(referenceEl, floatingEl, update) {
    const cleanup = autoUpdate(referenceEl, floatingEl, update, {
      elementResize: false,
    });
    return cleanup;
  },
});
</script>
