import { AppointmentType, AppointmentTypeForm } from "@/models/Appointment";
import {
  createAppointmentType,
  getAppointmentTypesWithTrashed,
  removeAppointmentType,
  reorder as reorderQuery,
  updateAppointmentType,
} from "@/queries/appointmentTypes";
import { useQuery, useQueryClient, useMutation } from "@tanstack/vue-query";
import { computed } from "vue";

export function useAppointmentTypes() {
  const queryClient = useQueryClient();

  const reorderMutation = useMutation({
    mutationFn: (ids: number[]) => reorderQuery(ids),
    onMutate: async (ids) => {
      await queryClient.cancelQueries({ queryKey: ["appointmentTypes"] });
      const previousData = queryClient.getQueryData(["appointmentTypes"]);

      queryClient.setQueryData(
        ["appointmentTypes"],
        (old: AppointmentType[] | undefined) => {
          if (!old) return old;
          const newData = [...old];
          ids.forEach((id, index) => {
            const item = newData.find((x) => x.id === id);
            if (item) item.order = index;
          });
          return newData.sort(
            (a, b) => (a.order ?? Infinity) - (b.order ?? Infinity),
          );
        },
      );

      return { previousData };
    },
    onError: (_err, _ids, context) => {
      queryClient.setQueryData(["appointmentTypes"], context?.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["appointmentTypes"] });
    },
  });

  const query = useQuery({
    queryKey: ["appointmentTypes"],
    queryFn: getAppointmentTypesWithTrashed,
    throwOnError: true,
    staleTime: 300 * 1000,
  });

  async function edit(appointmentTypeId: number, payload: AppointmentTypeForm) {
    await updateAppointmentType(appointmentTypeId, {
      ...payload,
      duration: Number(payload.duration),
      amount_credits: Number(payload.amount_credits),
    });
    queryClient.invalidateQueries({ queryKey: ["appointmentTypes"] });
  }

  async function create(payload: AppointmentTypeForm) {
    await createAppointmentType({
      ...payload,
      duration: Number(payload.duration),
      amount_credits: Number(payload.amount_credits),
    });
    queryClient.invalidateQueries({ queryKey: ["appointmentTypes"] });
  }

  async function remove(id: number) {
    await removeAppointmentType(id);
    queryClient.invalidateQueries({ queryKey: ["appointmentTypes"] });
  }

  return {
    edit,
    create,
    remove,
    suspense: query.suspense,
    reorder: (ids: number[]) => reorderMutation.mutateAsync(ids),
    isLoading: query.isLoading,
    data: computed(() =>
      query.data.value?.filter(({ deleted_at }) => !deleted_at),
    ),
    dataWithTrashed: computed(() => query.data.value),
  };
}
