import { computed, Ref, watch } from "vue";
import {
  Filter,
  FilterLogicOption,
  FilterManager,
} from "@/models/Filter/filter";
import { clone } from "@/libraries/utils/clone";

export function useFilterLogic(
  filter: Ref<Filter>,
  filterManager: FilterManager,
) {
  const { saveSelectedLogic, filterStates } = filterManager;

  const filterState = computed(
    () => filterStates.value[filter.value.filterType][filter.value.uuid],
  );
  const selectedLogic = computed(() => filterState.value.selectedLogic);

  const defaultLogicOptions = computed((): FilterLogicOption[] => {
    if (filter.value.logicOptions) {
      return filter.value.logicOptions;
    }
    if (filter.value.filterType === "boolean") {
      return ["EQ", "NEQ"];
    }

    if (filter.value.filterType === "option") {
      if (!("selectedOptions" in filterState.value)) {
        return [];
      }
      if (filter.value.optionsType === "single") {
        if (filterState.value.selectedOptions.length < 2) {
          return ["EQ", "NEQ"];
        } else {
          return ["OR", "NOR"];
        }
      }
      if (filter.value.optionsType === "multiple") {
        if (filterState.value.selectedOptions.length < 2) {
          return ["EQ", "NEQ"];
        } else {
          return ["OR", "NOR", "AND", "NAND"];
        }
      }
    }
    if (filter.value.filterType === "input") {
      return ["EQ", "NEQ"];
    }
    return [];
  });

  const logicOptions = computed(() => {
    if (!filter.value.logicOptionLogic) {
      return defaultLogicOptions.value;
    }
    let options: FilterLogicOption[] = clone(defaultLogicOptions.value);
    const optionLogics = filter.value.logicOptionLogic;
    if (optionLogics.onlyPositive) {
      options = options.filter(
        (opt) => opt !== "NEQ" && opt !== "NAND" && opt !== "NOR",
      );
    }
    if (optionLogics.onlyNegative) {
      options = options.filter(
        (opt) => opt !== "EQ" && opt !== "AND" && opt !== "OR",
      );
    }
    if (optionLogics.onlyOr) {
      options = options.filter((opt) => opt !== "AND" && opt !== "NAND");
    }
    if (optionLogics.onlyAnd) {
      options = options.filter((opt) => opt !== "OR" && opt !== "NOR");
    }
    return options;
  });

  watch(logicOptions, () => {
    if (!logicOptions.value.includes(filterState.value.selectedLogic)) {
      updateFilterLogic(logicOptions.value[0]);
    }
  });

  function updateFilterLogic(newLogic: FilterLogicOption) {
    saveSelectedLogic(filter.value.uuid, newLogic);
  }

  return {
    selectedLogic,
    logicOptions,
    updateFilterLogic,
  };
}
