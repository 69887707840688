import { mergeAttributes, Node } from "@tiptap/vue-3";
import { $t } from "../i18n";

interface TemplatePlaceholderOptions {
  field: string;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    templatePlaceholder: {
      /**
       * Add an image
       */
      setTemplatePlaceholder: (options: { field: string }) => ReturnType;
    };
  }
}

export const TemplatePlaceholder = Node.create<TemplatePlaceholderOptions>({
  name: "templatePlaceholder",
  renderHTML({ HTMLAttributes }) {
    const text = $t(`templates.placeholder.${HTMLAttributes.field}`);
    return [
      "span",
      mergeAttributes(HTMLAttributes, {
        class: "ProseMirror-TemplatePlaceholder",
      }),
      text,
    ];
  },
  parseHTML() {
    return [
      {
        tag: "span.ProseMirror-TemplatePlaceholder",
      },
    ];
  },
  addAttributes() {
    return {
      field: "default",
    };
  },
  inline: true,

  group: "inline",

  addCommands() {
    return {
      setTemplatePlaceholder:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: "templatePlaceholder",
            attrs: options,
          });
        },
    };
  },
});
