<template>
  <button
    :type="nativeButtonType"
    :disabled="disabled"
    @click="emit('click', $event)"
    :class="buttonClasses"
  >
    <i
      v-if="icon"
      :class="[icon, iconPosition === 'right' ? 'order-last' : 'order-first']"
    ></i>
    <slot></slot>
    <div v-if="hotkey && showHotkey" :class="hotkeyClasses">
      <div :class="hotkeyStyle">
        <i class="fal fa-arrow-alt-up" v-if="hotkey.shiftKey" />
        <i class="fal fa-arrow-alt-right" v-if="hotkey.key === 'arrowRight'" />
        <i
          class="fal fa-arrow-alt-left"
          v-else-if="hotkey.key === 'arrowLeft'"
        />
        <template v-else>
          {{ hotkey.key.toUpperCase() }}
        </template>
      </div>
    </div>
  </button>
</template>

<script lang="ts" setup>
import { PropType, toRef, computed } from "vue";
import { Hotkey } from "@/models/Hotkey";
import { useHotkey } from "@/composables/hotkeyComposable";
import { ButtonType } from "@/models/Button";

const props = defineProps({
  nativeButtonType: {
    default: "button",
    type: String as PropType<"button" | "submit" | "reset">,
  },
  type: {
    default: "default",
    type: String as PropType<ButtonType>,
  },
  size: {
    default: "medium",
    type: String as PropType<"medium" | "small" | "mini">,
  },
  icon: {
    type: String,
  },
  iconPosition: {
    default: "left",
    type: String as PropType<"left" | "right">,
  },
  disabled: {
    default: false,
    type: Boolean,
  },
  hotkey: {
    type: Object as PropType<Hotkey>,
  },
  showHotkey: {
    default: true,
    type: Boolean,
  },
  hotkeyPosition: {
    default: "right",
    type: String as PropType<"left" | "right">,
  },
});

const emit = defineEmits<{
  (e: "click", v?: MouseEvent): void;
  (e: "hotkeyClick"): void;
}>();

useHotkey(toRef(props, "hotkey"), () => {
  emit("click");
  emit("hotkeyClick");
});

const buttonClasses = computed(() => {
  const baseClasses = [
    "inline-flex transform items-center gap-2 rounded font-sans outline-none ring-opacity-50 focus:ring",
    "group m-0 box-border justify-center border transition duration-75 ",
    props.disabled
      ? "cursor-not-allowed hover:!shadow-none focus:!ring-0"
      : "cursor-pointer",
    props.size == "mini"
      ? "py-1 text-xs hover:shadow-sm"
      : props.size == "small"
        ? "px-3 py-2 text-sm hover:shadow-sm"
        : "px-6 py-3 text-sm hover:shadow",
    props.type == "primary"
      ? "  text-white" +
        (props.disabled
          ? " border-blue-300 bg-blue-300  dark:border-blue-900 dark:bg-blue-900 dark:text-text-quaternary "
          : " border-blue-500 bg-blue-500 hover:border-blue-600 hover:bg-blue-600 ")
      : props.type == "warning"
        ? "ring-orange-500 " +
          (props.disabled
            ? " border-orange-100 bg-orange-100 text-orange-400 dark:border-orange-900 dark:bg-orange-900 dark:text-orange-300"
            : " border-orange-100 bg-orange-100 text-orange-700 hover:border-orange-200 hover:bg-orange-200  dark:border-orange-900 dark:bg-orange-900 dark:text-orange-100 hover:dark:border-orange-800  hover:dark:bg-orange-800")
        : props.type == "error"
          ? "ring-red-500 " +
            (props.disabled
              ? " border-red-100 bg-red-100 text-red-400 dark:border-red-500 dark:bg-red-600 dark:text-red-400"
              : " border-red-100 bg-red-100 text-red-700 hover:border-red-200 hover:bg-red-200 dark:border-red-900 dark:bg-red-800 dark:text-red-100")
          : props.type == "delete"
            ? "ring-red-500 " +
              (props.disabled
                ? " border-red-400 bg-red-400 text-red-50"
                : " border-red-600 bg-red-600 text-white hover:border-red-500 hover:bg-red-500")
            : props.type == "default" || props.type == "secondary"
              ? "border-border-secondary bg-background-card shadow-sm ring-gray-400  dark:ring-neutral-400 " +
                (props.disabled
                  ? "border-border-primary text-text-quaternary"
                  : "text-text-secondary hover:bg-background-tertiary hover:text-text-primaryHover active:bg-background-secondary active:text-text-primaryActive")
              : props.type == "text"
                ? "border-transparent bg-transparent shadow-none hover:!shadow-none " +
                  (props.disabled
                    ? "text-text-quaternary"
                    : "text-blue-500 hover:text-text-primaryHover active:text-blue-700 dark:text-blue-400 hover:dark:text-blue-500")
                : "",
  ];
  return baseClasses.join(" ");
});

const hotkeyClasses = computed(() => [
  "flex items-center gap-x-1",
  props.hotkeyPosition === "right" ? "order-last" : "order-first",
]);

const hotkeyStyle = computed(() => {
  return {
    "rounded-sm px-1 py-0.5": true,
    "bg-blue-200/25": props.type === "default",
    "bg-background-primary/25": props.type !== "default",
    "border border-blue-200/75": props.type === "text",
  };
});
</script>
