import { apiClient } from "@/libraries/utils/axios";
import { z } from "zod";
import { QuestionnaireResponseRequestForm } from "@/models/QuestionnaireResponseRequest";
import { consentSchema } from "@/models/Consent";

export async function getPatientConsents(zisNumber: number) {
  const { data } = await apiClient.get(`/patients/${zisNumber}/consent`);

  return z.array(consentSchema.readonly()).parse(data);
}

export async function createPatientQuestionnaireResponseRequest(
  zisNumber: number,
  payload: QuestionnaireResponseRequestForm,
) {
  await apiClient.post(
    `/patients/${zisNumber}/questionnaire_response_requests`,
    payload,
  );
}

export async function sendPatientQuestionnaireResponseRequest(
  zisNumber: number,
  uuid: string,
) {
  await apiClient.post(
    `/patients/${zisNumber}/questionnaire_response_requests/${uuid}/send`,
  );
}

export async function cancelPatientQuestionnaireResponseRequest(
  zisNumber: number,
  uuid: string,
) {
  await apiClient.post(
    `/patients/${zisNumber}/questionnaire_response_requests/${uuid}/cancel`,
  );
}

export async function getPatientQuestionnaireResponseRequestUrl(
  zisNumber: number,
  uuid: string,
) {
  const { data } = await apiClient.get(
    `/patients/${zisNumber}/questionnaire_response_requests/${uuid}/url`,
  );

  return z.object({ url: z.string().url() }).parse(data).url;
}
