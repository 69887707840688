<template>
  <div>
    <flux-card-button-header
      class="m-2"
      :title="$t('patient.create.form.step.payer_insurers.title')"
      :action="$t('patient.create.form.step.payer_insurers.add_more')"
      @action="addPayerInsurer()"
      icon="fal fa-plus"
    />
    <flux-card
      class="mb-5"
      v-for="(_, index) in modelValue"
      shadow="never"
      :key="'payer_insurer' + index.toString()"
    >
      <ClickableIconGroup>
        <ClickableIcon
          icon="fa-times"
          @click="() => modelValue.splice(index, 1)"
        />
      </ClickableIconGroup>
      <PayerInsurerFormComponent
        v-model:modelValue="modelValue[index]"
        formPropRoot="payer_insurers"
        :formIndex="index"
        @error="emit('error', $event)"
      />
    </flux-card>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";
import moment from "moment";
import { Insurer } from "../../models/Insurer";
import { useNotify } from "@/composables/notify";
import { PayerInsurerForm } from "@/models/PayerInsurer";
import PayerInsurerFormComponent from "../Patient/PayerInsurerForm.vue";
import { useInsurerStore } from "@/libraries/store/Insurers";
import { pinia } from "@/libraries/store";
import { $t } from "@/libraries/i18n";
import ClickableIconGroup from "../ClickableIconGroup.vue";
import ClickableIcon from "../ClickableIcon.vue";

const { notify } = useNotify();

const props = defineProps<{
  modelValue: PayerInsurerForm[];
}>();

const emit = defineEmits<{
  (e: "error", value: boolean): void;
}>();

const insurers = ref<(Insurer & { label: string })[]>([]);
const insurerStore = useInsurerStore(pinia);

onMounted(() => {
  fetchInsurers();
});

async function fetchInsurers() {
  try {
    const data = await insurerStore.findAll();
    insurers.value = data.map((insurer) => {
      return Object.assign(insurer, {
        label: insurer.name + " (" + insurer.number + ")",
      });
    });
  } catch {
    notify({
      message: "Could not fetch insurer data",
      type: "error",
    });
  }
}

function addPayerInsurer() {
  props.modelValue.push({
    insurer_id: undefined,
    number: "",
    type: "B",
    insurer_name: "",
    insurer_number: "",
    package_name: "",
    start_date: moment().startOf("year").format("YYYY-MM-DD"),
    end_date: moment().endOf("year").format("YYYY-MM-DD"),
  });
}
</script>
