<template>
  <div
    :class="[
      'group my-2 border-l-2 p-2 transition-colors duration-75',
      isNumericExpression(item)
        ? 'border-indigo-100 focus-within:border-indigo-500'
        : isBooleanExpression(item)
          ? 'border-teal-100 focus-within:border-teal-500'
          : 'border-orange-100 focus-within:border-orange-500',
    ]"
  >
    <div class="flex gap-2">
      <div
        @click="collapsed = !collapsed"
        :class="[
          'inline-block cursor-pointer rounded px-2 py-1 text-xs',
          isNumericExpression(item)
            ? 'bg-indigo-100 text-indigo-600 hover:bg-indigo-200'
            : isBooleanExpression(item)
              ? 'bg-teal-100 text-teal-600 hover:bg-teal-200'
              : 'bg-orange-100 text-orange-600 hover:bg-orange-200',
        ]"
      >
        <i
          class="fal fa-chevron-down transform duration-150"
          :class="{ 'rotate-180': collapsed }"
        ></i>
        {{ item.type }}
        <span v-if="'item' in item && item.item !== undefined"
          >({{ item.item.length }})</span
        >
      </div>
      <div
        class="inline-flex cursor-pointer items-center rounded bg-red-100 px-2 py-1 text-xs text-red-500 hover:bg-red-500 hover:text-text-primaryHover"
        @click="emit('remove')"
      >
        <i class="fas fa-times"></i>
      </div>
    </div>
    <div v-if="!collapsed">
      <div v-if="item.type == 'constant_boolean'">
        <div>Value: {{ item.value }}</div>
      </div>
      <div
        v-else-if="item.type == 'reference_boolean' || item.type == 'is_null'"
      >
        linkid: <flux-input v-model:modelValue="item.linkId"></flux-input>
      </div>
      <Or v-else-if="item.type == 'or'" :item="item"></Or>
      <And v-else-if="item.type == 'and'" :item="item"></And>
      <Comparison
        v-else-if="item.type == 'numeric_comparison'"
        :item="item"
      ></Comparison>
      <EditorEnabled
        v-else-if="item.type == 'not'"
        :item="item.exp"
      ></EditorEnabled>
      <div v-else>???</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  isBooleanExpression,
  isNumericExpression,
  BooleanExpression,
} from "@/libraries/questionnaires/score";
import And from "./enabled/And.vue";
import Comparison from "./enabled/Comparison.vue";
import Or from "./enabled/Or.vue";
import { ref } from "vue";

defineProps<{
  item: BooleanExpression;
}>();

const emit = defineEmits(["remove"]);

const collapsed = ref(false);
</script>
