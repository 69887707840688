import {
  BooleanFilterStatus,
  InputFilterStatus,
  OptionFilterStatus,
  filterLogicOptions,
  Filter,
  FilterLogicOption,
  RangeFilterStatus,
} from "@/models/Filter/filter";
import { Ref, onBeforeMount, watch } from "vue";
import { z } from "zod";
import { FilterStates } from "./FilterManager";

type CachedFilterState = {
  boolean: Record<string, BooleanFilterStatus & { rememberKey?: string }>;
  input: Record<string, InputFilterStatus & { rememberKey?: string }>;
  option: Record<string, OptionFilterStatus & { rememberKey?: string }>;
  range: Record<string, RangeFilterStatus & { rememberKey?: string }>;
};

export function useRememberFilters(
  registry: Map<string, Filter>,
  activeFilters: Ref<Array<Filter>>,
  filterStates: Ref<FilterStates>,
  filterLogic: Ref<FilterLogicOption>,
  activateFilter: (uuid: string) => void,
  filterRememberKey: string,
) {
  const release = import.meta.env.VITE_SENTRY_RELEASE || "unknown";
  const globalRememberKey = filterRememberKey + "|" + release;

  onBeforeMount(() => {
    applyRememberedFilterStates();
  });

  watch(
    [activeFilters, filterStates],
    () => {
      rememberFilterStates();
    },
    {
      deep: true,
    },
  );

  function rememberKeyToUuid(rememberKey: string): string | undefined {
    for (const [uuid, filter] of registry.entries()) {
      if (filter.rememberKey === rememberKey) {
        return uuid;
      }
    }
  }

  function uuidToRememberKey(rememberKey: string): string | undefined {
    return registry.get(rememberKey)?.rememberKey;
  }

  function addRememberKeysToFilterState(
    filterState: FilterStates,
  ): CachedFilterState {
    const result: CachedFilterState = JSON.parse(JSON.stringify(filterState));

    const filterIsActive = ([uuid, _]: [string, any]): boolean => {
      return activeFilters.value.some(
        (activeFilter) => activeFilter.uuid === uuid,
      );
    };

    result.boolean = Object.fromEntries(
      Array.from(Object.entries(result.boolean))
        .filter(filterIsActive)
        .map(([uuid, state]) => {
          const rememberKey = uuidToRememberKey(uuid);
          return [uuid, { ...state, rememberKey }];
        }),
    );
    result.input = Object.fromEntries(
      Array.from(Object.entries(result.input))
        .filter(filterIsActive)
        .map(([uuid, state]) => {
          const rememberKey = uuidToRememberKey(uuid);
          return [uuid, { ...state, rememberKey }];
        }),
    );
    result.option = Object.fromEntries(
      Array.from(Object.entries(result.option))
        .filter(filterIsActive)
        .map(([uuid, state]) => {
          const rememberKey = uuidToRememberKey(uuid);
          return [uuid, { ...state, rememberKey }];
        }),
    );
    result.range = Object.fromEntries(
      Array.from(Object.entries(result.range))
        .filter(filterIsActive)
        .map(([uuid, state]) => {
          const rememberKey = uuidToRememberKey(uuid);
          return [uuid, { ...state, rememberKey }];
        }),
    );
    return cachedFilterStateScheme.parse(result);
  }

  function rememberFilterStates() {
    if (!globalRememberKey) {
      return;
    }

    const rememberFilterStates = JSON.stringify({
      filterStates: addRememberKeysToFilterState(filterStates.value),
      activeFilters: activeFilters.value.map((filter) => filter.rememberKey),
      filterLogic: filterLogic.value,
    });
    sessionStorage.setItem(
      "filterStates_" + globalRememberKey,
      rememberFilterStates,
    );
  }

  function applyRememberedFilterStates() {
    if (!globalRememberKey) {
      return;
    }

    const rememberFilterStates = sessionStorage.getItem(
      "filterStates_" + globalRememberKey,
    );

    if (!rememberFilterStates) {
      return;
    }

    const parsed = JSON.parse(rememberFilterStates);
    filterLogic.value = parsed.filterLogic;
    try {
      const parsedRememberFilterStates = cachedFilterStateScheme.parse(
        parsed.filterStates,
      );
      for (const [_, state] of [
        ...Object.entries(parsedRememberFilterStates.boolean),
        ...Object.entries(parsedRememberFilterStates.input),
        ...Object.entries(parsedRememberFilterStates.option),
        ...Object.entries(parsedRememberFilterStates.range),
      ]) {
        const rememberKey = state.rememberKey;
        if (!rememberKey) {
          return;
        }

        const newUuid = rememberKeyToUuid(rememberKey);
        for (const [type, _] of Object.entries(filterStates.value)) {
          if (!newUuid || !(newUuid in (filterStates.value as any)[type])) {
            continue;
          }

          (filterStates.value as any)[type][newUuid] = state;
          activateFilter(newUuid);
        }
      }
    } catch (e) {
      sessionStorage.removeItem("filterStates_" + globalRememberKey);
    }
  }

  return {
    rememberFilterStates,
    applyRememberedFilterStates,
  };
}

const cachedFilterStateScheme = z.object({
  boolean: z.record(
    z.object({
      rememberKey: z.string(),
      selectedLogic: z.enum(filterLogicOptions),
    }),
  ),
  input: z.record(
    z.object({
      rememberKey: z.string(),
      text: z.string(),
      selectedLogic: z.enum(filterLogicOptions),
    }),
  ),
  option: z.record(
    z.object({
      rememberKey: z.string(),
      selectedOptions: z.array(
        z.union([
          z.string(),
          z.number(),
          z.array(z.string()),
          z.array(z.number()),
        ]),
      ),
      selectedLogic: z.enum(filterLogicOptions),
    }),
  ),
  range: z.record(
    z.object({
      rememberKey: z.string(),
      rangeStart: z.string(),
      rangeEnd: z.string(),
      selectedLogic: z.enum(filterLogicOptions),
    }),
  ),
});
