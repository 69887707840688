import { getUser } from "@/libraries/plugins/getUser";
import IndexedDBStorageManager from "@/libraries/storage/indexedDBStorageManager";
import FormAutoSaveManager from "@/libraries/utils/formAutoSaveManager";
import { watch } from "vue";
import { Ref } from "vue";
import { useDebouncedValue } from "./debouncedValue";
import { clone } from "@/libraries/utils/clone";
import { useApiKeyStore } from "./apiKeyStore";
import EncryptedStorageManager from "@/libraries/storage/encryptedStorageManager";
import store from "@/libraries/store";
import { ref } from "vue";

let formAutoSaveManager: FormAutoSaveManager | undefined = undefined;
let lastAutosaveKey: string = "";

watch(
  () => store.state.user?.current_autosave_key_id,
  () => {
    const newKey = getUser().current_autosave_key_id;
    if (formAutoSaveManager !== undefined && newKey !== lastAutosaveKey) {
      lastAutosaveKey = newKey;
      formAutoSaveManager.setEncryptionKeyForStorage(lastAutosaveKey);
    }
  },
);

export function useFormAutoSaveManager() {
  const { keyStore, deInitialize: deInitializeKeyStore } = useApiKeyStore();
  function getFormAutoSaveManager(): FormAutoSaveManager {
    if (formAutoSaveManager === undefined) {
      lastAutosaveKey = getUser().current_autosave_key_id;
      formAutoSaveManager = new FormAutoSaveManager(
        new EncryptedStorageManager(
          new IndexedDBStorageManager(),
          lastAutosaveKey,
          keyStore,
        ),
        getUser().uuid,
      );
    }
    return formAutoSaveManager;
  }

  return {
    manager: getFormAutoSaveManager,
    loadAllData: async () => getFormAutoSaveManager().getStoredForms(),
    deInitialize: () => {
      formAutoSaveManager = undefined;
      deInitializeKeyStore();
    },
  };
}

export function useFormAutoSave(
  autosaveKey: Ref<string>,
  url: Ref<string>,
  formData: Ref<any>,
  initialFill: Ref<boolean> = ref<boolean>(true),
) {
  const initialInitialFill = initialFill.value;
  async function getSavedData(): Promise<any | undefined> {
    const manager = useFormAutoSaveManager().manager();
    try {
      return await manager.getStoredForm(autosaveKey.value);
    } catch {
      return undefined;
    }
  }

  async function saveData(payload: any): Promise<void> {
    const manager = useFormAutoSaveManager().manager();
    manager.storeForm(autosaveKey.value, url.value, payload);
  }

  async function removeData(): Promise<void> {
    const manager = useFormAutoSaveManager().manager();
    manager.deleteStoredForm(autosaveKey.value);
  }

  const debouncedModelvalue = useDebouncedValue(formData, 1000);

  watch(
    debouncedModelvalue,
    async () => {
      if (debouncedModelvalue.value === undefined) {
        return;
      }
      if (initialFill.value) {
        initialFill.value = false;
        return;
      }

      await saveData(clone(debouncedModelvalue.value));
    },
    { deep: true },
  );

  return {
    loadedData: getSavedData(),
    loadData: getSavedData,
    saveData: saveData,
    removeData: removeData,
    resetInitialFill: () => (initialFill.value = initialInitialFill),
  };
}
