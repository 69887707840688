import { getAppointmentLogs } from "@/queries/appointments/appointmentLogs";
import { appointmentsQueryKeys } from "@/queries/appointments/appointmentsQueryKeys";
import { useQuery } from "@tanstack/vue-query";
import { Ref } from "vue";

export function useAppointmentLogs(appointmentId: Ref<number>) {
  return useQuery({
    queryKey: appointmentsQueryKeys.appointmentLogs(appointmentId),
    queryFn: ({ queryKey }) => getAppointmentLogs(queryKey[1]),
    throwOnError: true,
    retry: 1,
  });
}
