import { Invoice, InvoiceApi, InvoiceStatus } from "@/models/Invoice";
import { User } from "@/models/User";
import { InvoiceEntry } from "@/models/InvoiceEntry";
import { EmailSent } from "@/models/EmailSent";

export const invoiceTypes = [
  "patient.pm304",
  "patient.gds801",
  "patient.product",
  "company.product",
  "insurer.collection.pm304",
  "insurer.partial.pm304",
  "insurer.collection.gds801",
  "insurer.partial.gds801",
] as const;

export type InvoiceType = (typeof invoiceTypes)[number];

export type InvoiceEntryApiBodyBtw = {
  description: string;
  amount: number;
  amount_text: string;
  tax_rate_id: string;
  credit_reference?: string;
  price: number;
  total_price: number;
  product_code: string;
  date_treatment?: string;
  patient_zis_number?: number;
  appointment_uuid?: string;
};

export type InvoiceEntryApiBodyPm304 = {
  prestatiecode: string;
  agb_code: string;
  date_treatment: string;
  description?: string;
  amount: number;
  amount_text: string;
  tax_rate_id: string;
  csi_code: string;
  reference?: string;
  credit_reference?: string;
  price?: number;
  appointment_uuid?: string;
};

export type ProductInvoiceEntryApiBody = {
  product_code?: string;
  amount_text: string;
  description?: string;
  price: number;
  tax_rate_id: string;
  date_treatment?: string;
  appointment_uuid?: string;
};

export type InvoiceEntryApiBody =
  | InvoiceEntryApiBodyPm304
  | InvoiceEntryApiBodyBtw;

export interface InvoiceApiBody {
  type: InvoiceType;
  prices_include_tax: boolean;
  company_administration_id?: string;
  user_id: number;
  date?: string;
  status?: string;
  hcp_address_id?: number;
  hcp_agb_id?: number;
  hcp_btw_id?: number;
  hcp_kvk_id: number;
  hcp_phone_number_id?: number;
  hcp_bank_account_id?: number;
  invoice_entries: InvoiceEntryApiBody[];
  patient_address_id?: number;
  patient_zis_number?: number;
  pay_date?: string;
  payer_insurer_id?: number;
  payer_contact_id?: number;
  referral_id?: number;
  paid_in_advance?: boolean;
  correcting_invoice_id?: number;
  credits_invoice_id?: number;
}

interface StoreProductInvoiceApiBody {
  type: InvoiceType;
  prices_include_tax: boolean;
  date: string;
  pay_date: string;
  status: InvoiceStatus;
  hcp_address_id: number;
  hcp_phone_number_id: number;
  hcp_bank_account_id: number;
  hcp_kvk_id: number;
  hcp_btw_id?: number;
  invoice_entries: ProductInvoiceEntryApiBody[];
  credits_invoice_id?: number;
  correcting_invoice_id?: number;
  paid_in_advance?: boolean;
}

export type StoreCompanyProductInvoiceApiBody = StoreProductInvoiceApiBody & {
  company_administration_id: string;
  type: "company.product";
};

export type StorePatientProductInvoiceApiBody = StoreProductInvoiceApiBody & {
  hcp_agb_id?: number;
  patient_zis_number: number;
  patient_address_id?: number;
  payer_insurer_id?: number;
  user_id?: number;
  type: "patient.product";
};

export interface PatientInvoicesApi {
  "/patients/:zis_number/invoices": {
    GET: {
      response: {
        invoices: Array<
          Invoice & { user: User; invoice_entries: InvoiceEntry[] }
        >;
        company_administration_id?: string;
      };
    };
    POST: {
      body: InvoiceApiBody;
      response: {
        success: boolean;
        invoice: Invoice;
      };
    };
  };
  "/patients/:zis_number/invoices/:invoice_id": {
    GET: {
      response: InvoiceApi;
    };
    PUT: {
      body: InvoiceApiBody;
      response: {
        success: boolean;
        invoice: Invoice;
      };
    };
  };
  "/patients/:zis_number/invoices/:invoice_id/send": {
    POST: {
      body: object;
      response: {
        success: boolean;
        email_sent: EmailSent;
      };
    };
  };
}
