<template>
  <span class="rounded-full" :class="[iconClass, whiteAccent]"></span>
</template>

<script lang="ts" setup>
import { AppointmentStatus } from "@/models/Appointment";
import { computed } from "vue";

const props = defineProps<{
  status: AppointmentStatus;
  appointmentIsActive?: boolean;
}>();

const whiteAccent = computed(() => {
  return props.appointmentIsActive === true
    ? "bg-background-primary outline-1 outline outline-white/50  dark:outline-neutral-600"
    : " ";
});

const iconClass = computed(() => {
  switch (props.status) {
    case "FULFILLED":
      return "text-green-500 fas fa-check-circle ";

    case "NOSHOW":
      return "text-red-500 fas fa-times-circle ";

    case "ARRIVED":
      return "text-blue-500 fas fa-arrow-circle-right";

    case "CANCELLED":
      return "text-text-quaternary fas fa-times-circle";

    case "BOOKED":
    default:
      return "far fa-circle";
  }
});

defineExpose({ iconClass });
</script>
