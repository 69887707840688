<template>
  <div
    :class="[
      'flex flex-row items-center gap-2 rounded p-2',
      type == 'error'
        ? 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-100'
        : type == 'warning'
          ? 'bg-orange-100 text-orange-800 dark:bg-orange-900 dark:text-orange-100'
          : 'bg-background-tertiary text-text-secondary  ',
    ]"
  >
    <div class="text-center" v-if="$slots.icon">
      <slot name="icon"></slot>
    </div>
    <div class="flex-grow">
      <slot></slot>
    </div>
    <div v-if="$slots.action">
      <div
        class="group flex cursor-pointer items-center gap-1 rounded p-2 text-text-link"
        :class="
          type === 'error'
            ? 'hover:bg-red-200 hover:dark:bg-red-700/50'
            : 'hover:bg-background-quaternary'
        "
        @click="clickAction"
      >
        <span><slot name="action"></slot></span>
        <i
          class="far fa-arrow-right transform transition-transform duration-75 group-hover:translate-x-1"
        ></i>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  type: "error" | "info" | "warning";
}>();

const emit = defineEmits<{
  (e: "action"): void;
}>();

function clickAction() {
  emit("action");
}
</script>
