<template>
  <button
    class="shadow-xs flex h-6 w-6 flex-none cursor-pointer items-center justify-center rounded-md bg-background-primary"
    @click="$emit('click', $event)"
    tabindex="-1"
    :class="
      active
        ? 'text-sky-400 shadow ring-1 ring-slate-900/10 dark:text-sky-800'
        : 'bg-background-primary text-text-quaternary hover:bg-background-secondary hover:text-text-primaryHover   '
    "
  >
    <i class="text-xs" :class="icon" />
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    icon: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["click"],
  setup() {},
});
</script>
