import { Patient } from "@/composables/patient";
import { CarePlan } from "./../libraries/repositories/CarePlan/CarePlan";
import { Consent } from "./Consent";
import { User } from "./User";
import { InvoiceType } from "@/apis/patients/invoices";
import { HealthcareProfessionalType } from "./HealthcareProfessionalType";
import { locationIsNeeded } from "@/assets/codesystems/dcsph";

export type ReferralInvoiceSettings = {
  default_invoice_type?: InvoiceType | null;
};
export const locationIndications = ["01", "02", "03"] as const;
export type LocationIndication = (typeof locationIndications)[number];

export const indicationAccidents = ["O", "J", "N"] as const;
export type IndicationAccident = (typeof indicationAccidents)[number];

export interface Referral {
  id?: number;
  patient_zis_number?: number;
  hcp_diagnosis: string;
  diagnosis_code?: string;
  hcp_lpdf_id?: number;
  hcp_lpdf_name?: string;
  referred_at?: string;
  referrer_diagnosis_code?: string;
  referral_information?: string;
  indication_accident: IndicationAccident;
  default_csi_code?: string;
  is_chronic?: boolean;
  is_declarable?: boolean;
  chronic_periods: {
    start_date?: string;
    end_date?: string;
    number_of_treatments?: number;
    default_csi_code?: string;
  }[];
  consents?: Consent[];
  is_self_referral: boolean;
  is_red_flagged: boolean;
  AGB_code?: string;
  specialism_code?: string;
  machtiging_code?: string;
  external_treatment_count?: number;
  location_indication?: LocationIndication;
  import_data?: {
    external_id?: string;
  };
  invoice_settings?: ReferralInvoiceSettings;
  healthcare_professional_type: HealthcareProfessionalType;
  main_therapist_id?: number;
  readonly created_at?: string;
  readonly updated_at?: string;
}

export type ReferralPersisted = Referral & {
  id: number;
  patient_zis_number: number;
  care_plans?: CarePlan[];
  main_therapist?: User;
  treatments?: unknown[];
  closed_at?: string;
  is_draft?: boolean;
  is_physio_aftercare: boolean;
  readonly created_at: string;
  readonly updated_at: string;
};

export function getLatestReferral<
  T extends ReferralPersisted | Patient["referrals"][number],
>(referrals: T[]): T | undefined {
  return referrals.sort((a, b) => (a.id > b.id ? -1 : 1)).at(0);
}

export function getLatestOpenReferral<
  T extends ReferralPersisted | Patient["referrals"][number],
>(referrals: T[]): T | undefined {
  return referrals
    .filter(({ closed_at }) => !closed_at)
    .sort((a, b) => (a.id > b.id ? -1 : 1))
    .at(0);
}

export function hasConsentForPrem(
  referral: Referral & { consents: Pick<Consent, "action" | "type">[] },
): boolean {
  return referral.consents.some(
    (consent) =>
      consent.type === "permit" &&
      (consent.action === "prem.send.email" ||
        consent.action === "prem.send.phone" ||
        consent.action === "prem.send.post"),
  );
}

export const locationNeeded = (code: string | undefined) => {
  if (!code || code.length < 4) {
    return false;
  }
  return locationIsNeeded.includes(code);
};
