<template>
  <div class="contents">
    <flux-card-button-header
      class="m-2"
      :title="$t('patient.create.form.step.addresses.title')"
      :action="$t('patient.create.form.step.addresses.add_more')"
      @action="addAddress()"
      icon="fal fa-plus"
    />
    <flux-card class="mb-5" v-for="(address, index) in value" shadow="never">
      <clickable-icon-group>
        <clickable-icon icon="fa-times" @click="() => value.splice(index, 1)" />
      </clickable-icon-group>
      <AddressFormVue
        v-model:modelValue="value[index]"
        formPropRoot="addresses"
        :formIndex="index"
        :poRequired="true"
        @error="emit('error', $event)"
      />
    </flux-card>
  </div>
</template>

<script lang="ts" setup>
import { $t } from "@/libraries/i18n";
import AddressFormVue from "../Patient/AddressForm.vue";
import { AddressForm } from "@/models/Address";
import { computed } from "vue";

/* TODO Vue 3:
 * value -> modelValue
 */
const props = defineProps<{
  modelValue: AddressForm[];
}>();

const emit = defineEmits<{
  (e: "error", value: boolean): void;
  (e: "update:modelValue", value: AddressForm[]): void;
}>();

const value = computed<AddressForm[]>({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

function addAddress() {
  value.value.push({
    address_type: "PHYS",
    street_name: "",
    house_number: "",
    postal_code: "",
    city: "",
    country_id: 159,
  });
}
</script>
