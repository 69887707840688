<template>
  <div>
    <flux-card-button-header
      class="m-2"
      :title="$t('patient.create.form.step.healthcare_professionals.title')"
      :action="$t('patient.create.form.step.healthcare_professionals.add_more')"
      @action="addNew()"
      icon="fal fa-plus"
    />
    <flux-card
      class="overflow-visible"
      v-for="(hcp, index) in modelValue"
      shadow="never"
      :key="'hcp' + index"
    >
      <ClickableIconGroup>
        <ClickableIcon icon="fa-times" @click="remove(index)" />
      </ClickableIconGroup>
      <flux-short-form>
        <flux-short-form-item
          :label="
            $t('patient.create.form.step.healthcare_professionals.AGB_code')
          "
        >
          <flux-agb-code-input
            v-model:modelValue="hcp.AGB_code"
            @select="
              ((hcp.fullname = `${$event.initials} ${$event.surname}`),
              (hcp.specialism_code = '01.015'))
            "
            :prop="'healthcare_professionals.' + index + '.AGB_code'"
            @error="emit('error', $event)"
          />
        </flux-short-form-item>
        <flux-short-form-item
          :label="
            $t('patient.create.form.step.healthcare_professionals.fullname')
          "
        >
          <flux-input
            v-model:modelValue="hcp.fullname"
            :prop="'healthcare_professionals.' + index + '.fullname'"
            @error="emit('error', $event)"
          />
        </flux-short-form-item>
        <flux-short-form-item
          :label="$t('patient.create.form.step.healthcare_professionals.role')"
        >
          <flux-select v-model:modelValue="hcp.role">
            <option
              v-for="(role, index) in HealthcareProfessionalRoles"
              :key="index"
              :value="role"
              :label="$t(`healthcare_professional.role.${role}`)"
            >
              {{ $t(`healthcare_professional.role.${role}`) }}
            </option>
          </flux-select>
        </flux-short-form-item>
        <flux-short-form-item
          :label="
            $t('patient.create.form.step.healthcare_professionals.specialism')
          "
        >
          <flux-select v-model:modelValue="hcp.specialism_code">
            <option
              v-for="(specialism, index) in specialisms"
              :key="index"
              :value="specialism.code"
              :label="specialism.display_name_NL"
            >
              {{ specialism.display_name_NL }}
            </option>
          </flux-select>
        </flux-short-form-item>
        <flux-short-form-item
          :label="
            $t('patient.create.form.step.healthcare_professionals.UZI_code')
          "
        >
          <flux-input
            v-model:modelValue="hcp.UZI_code"
            :prop="'healthcare_professionals.' + index + '.UZI_code'"
            @error="emit('error', $event)"
          />
        </flux-short-form-item>
      </flux-short-form>
    </flux-card>
  </div>
</template>

<script lang="ts" setup>
import { apiClient } from "@/libraries/utils/axios";
import { onMounted, ref } from "vue";
import { useNotify } from "@/composables/notify";
import { HealthcareProfessionalForm } from "@/models/Forms";
import { HealthcareProfessionalRoles } from "../../models/HealthcareProfessional";
import ClickableIcon from "../ClickableIcon.vue";
import ClickableIconGroup from "../ClickableIconGroup.vue";

const { notify } = useNotify();

const props = defineProps<{
  modelValue: HealthcareProfessionalForm[];
}>();

const emit = defineEmits<{
  (e: "error", value: boolean): void;
}>();

const specialisms = ref<
  {
    code: string;
    display_name_NL: string;
  }[]
>();

onMounted(() => {
  fetchSpecialisms();
});

function remove(index: number) {
  props.modelValue.splice(index, 1);
}

function fetchSpecialisms() {
  apiClient
    .get("/specialisms")
    .then(({ data }) => {
      specialisms.value = data.specialisms;
    })
    .catch(() => {
      notify({
        message: "Could not fetch specialism data",
        type: "error",
      });
    });
}

function addNew() {
  props.modelValue.push({
    fullname: "",
    role: "REF",
    AGB_code: "",
    UZI_code: "",
    specialism_code: "",
    healthcare_provider: "",
  });
}
</script>
