import {
  InvoiceEntryApiBodyBtw,
  InvoiceEntryApiBodyPm304,
} from "@/apis/patients/invoices";
import { getUser } from "@/libraries/plugins/getUser";
import { euroToCent } from "@/libraries/utils";
import { InvoiceStatus } from "@/models/Invoice";
import { InvoicePaymentMethod } from "@/models/InvoicePayment";
import { computed, Ref } from "vue";
import { PriceInput } from "./PriceCalculator";
import { TaxRate } from "@/composables/taxRates";

export interface CreateInvoiceForm {
  id?: number;
  company_administration_id?: string;
  prices_include_tax: boolean;
  user_id: number;
  patient_zis_number?: number;
  invoice_entries: CreateInvoiceEntryForm[];
  patient_address_id: number;
  payer_contact_id?: number;
  payer_insurer_id?: number;
  referral_id?: number;
  paid_in_advance: boolean;
  status?: InvoiceStatus;
  correcting_invoice_id?: number;
  payment_method?: InvoicePaymentMethod;
}

export type CreateInvoiceEntryForm = {
  amount: string;
  product_code?: string;
  date_treatment: string;
  prestatiecode: string;
  description: string;
  agb_code: string;
  csi_code: string;
  vat: number;
  price: string;
  error?: boolean;
  tax_rate_id: string;
  appointment_uuid?: string;
  patient_zis_number?: number;
  credit_reference?: string;
};

type CreateProductInvoiceForm = {
  id?: number;
  prices_include_tax: boolean;
  invoice_entries: CreateProductInvoiceEntryForm[];
  payment_method: InvoicePaymentMethod | undefined;
  status?: InvoiceStatus;
};

export type CreateCompanyProductInvoiceForm = CreateProductInvoiceForm & {
  company_administration_id?: string;
};

export type CreatePatientProductInvoiceForm = CreateProductInvoiceForm & {
  user_id: number | null;
  patient_zis_number: number;
  patient_address_id?: number;
  referral_id?: number;
  payer_insurer_id?: number;
};

export type CreateProductInvoiceEntryForm = {
  amount: string;
  product_code: string;
  description: string;
  tax_rate_id: string;
  price: string;
  error?: boolean;
  date_treatment?: string;
  appointment_uuid?: string;
  patient_zis_number?: number;
};

export function priceInputFromForm(
  form: {
    prices_include_tax: boolean;
    invoice_entries: Array<
      CreateProductInvoiceEntryForm | CreateInvoiceEntryForm
    >;
  },
  taxRates: readonly TaxRate[],
): PriceInput {
  return {
    pricesIncludeTax: form.prices_include_tax,
    entries: form.invoice_entries.flatMap((entry) => {
      const taxRateId = entry.tax_rate_id;
      if (!taxRateId) {
        return [];
      }

      const amount = safeOr(getAmount(entry.amount), 1);
      const price = safeOr(euroToCent(entry.price), 0);

      return [
        {
          taxRate: {
            id: taxRateId,
            percent:
              taxRates.find(({ id }) => id === taxRateId)
                ?.tax_rate_percentage ?? 0,
          },
          totalPrice: Math.round(amount * price),
        },
      ];
    }),
  };
}

export function getAmount(input: string): number {
  const amount = input.replace(" ", "").replace(",", ".");

  const isTime = /^(-?)(\d+):(\d{2})$/;
  const matchResult = amount.match(isTime);
  if (matchResult) {
    const [, negationSign, hours, minutes] = Array.from(matchResult);

    const isNegative = negationSign === "-";
    const amountInHours = parseInt(hours) + parseInt(minutes) / 60;

    if (parseInt(minutes) < 60) {
      return isNegative ? -amountInHours : amountInHours;
    }
  }

  return Number.parseFloat(amount);
}

export function safeOr(n: number, defaultSafeValue: number): number {
  if (isNaN(n) || !Number.isFinite(n)) {
    return defaultSafeValue;
  }

  return n;
}

export function formatPm304Entry(
  entry: CreateInvoiceEntryForm,
): InvoiceEntryApiBodyPm304 {
  if (typeof entry.date_treatment === "symbol") {
    throw new Error("expected date_treatment to be symbol");
  }
  return {
    description: entry.description,
    amount_text: entry.amount,
    amount: Number.parseFloat(entry.amount),
    price: euroToCent(entry.price),
    tax_rate_id: entry.tax_rate_id ?? "nl.vrijgesteld",
    date_treatment: entry.date_treatment,
    prestatiecode: entry.prestatiecode,
    agb_code: entry.agb_code,
    csi_code: entry.csi_code,
    appointment_uuid: entry.appointment_uuid,
    credit_reference: entry.credit_reference ?? undefined,
  };
}
export function formatBtwEntry(
  entry: CreateProductInvoiceEntryForm,
): InvoiceEntryApiBodyBtw {
  return {
    description: entry.description,
    product_code: entry.product_code ?? "",
    price: euroToCent(entry.price),
    amount_text: entry.amount,
    amount: getAmount(entry.amount),
    tax_rate_id: entry.tax_rate_id ?? "nl.vrijgesteld",
    total_price: Math.round(getAmount(entry.amount) * euroToCent(entry.price)),
    date_treatment: entry.date_treatment ?? undefined,
    patient_zis_number: entry.patient_zis_number,
    appointment_uuid: entry.appointment_uuid,
  };
}

export const emailAddress = computed(() => {
  const emailAddressId = hcp.value.invoice_email_address_id;
  return (
    hcp.value.email_addresses.find(({ id }) => id === emailAddressId) ??
    hcp.value.email_addresses[0]
  );
});

export const address = computed(() => {
  const addressId = hcp.value.invoice_address_id;
  return (
    hcp.value.addresses.find(({ id }) => id === addressId) ??
    hcp.value.addresses[0]
  );
});

export const bankAccount = computed(() => {
  const bankAccountId = hcp.value.invoice_bank_account_id;
  return (
    hcp.value.bank_accounts.find(({ id }) => id === bankAccountId) ??
    hcp.value.bank_accounts[0]
  );
});

export const phoneNumber = computed(() => {
  const phoneNumberId = hcp.value.invoice_phone_number_id;
  return (
    hcp.value.phone_numbers.find(({ id }) => id === phoneNumberId) ??
    hcp.value.phone_numbers[0]
  );
});

const hcp = computed(() => {
  return getUser().healthcare_provider;
});

export function setPayDateOptions(
  payDateInterval: Ref<number | null | undefined>,
  payDateOptions: Ref<number[]>,
): void {
  payDateInterval.value = parseInt(
    hcp.value.invoice_settings.pay_date_interval.toString(),
  );
  if (
    !payDateOptions.value.some((option) => option === payDateInterval.value)
  ) {
    payDateOptions.value.push(payDateInterval.value);
  }
  payDateOptions.value = payDateOptions.value.sort((a, b) => a - b);
}

export function getDateForInsurer(
  start_date: string,
  end_date: string,
  intersect_date: Date,
): string {
  return new Date(start_date) <= intersect_date &&
    intersect_date <= new Date(end_date)
    ? intersect_date.toISOString().substring(0, 10)
    : end_date;
}
