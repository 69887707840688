<template>
  <flux-input-box class="ml-3" :label>
    <flux-radio-button-small
      v-model:modelValue="localType"
      label="Ja"
      optionValue="permit"
    />
    <flux-radio-button-small
      v-model:modelValue="localType"
      label="Nee"
      optionValue="deny"
    />
  </flux-input-box>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { updatePatientConsent } from "@/composables/patientConsents";
import { Consent, ConsentType, PatientConsentAction } from "@/models/Consent";

const props = defineProps<{
  zisNumber: number;
  consents: Consent[] | undefined;
  action: PatientConsentAction;
  label: string;
}>();

const localType = computed<ConsentType | undefined>({
  get: () => props.consents?.find((c) => c.action === props.action)?.type,
  set: (newType) => {
    if (newType === undefined) {
      return;
    }
    updateConsent({ action: props.action, type: newType });
  },
});

const { updatePatientConsent: updateConsent } = updatePatientConsent(
  computed(() => props.zisNumber),
);
</script>
