<template>
  <div
    class="mx-auto box-border w-full max-w-[100vw] md:max-w-[calc(100vw-6.5rem)] lg:max-w-screen-lg"
  >
    <div
      class="mb-3 flex items-center justify-between border-b border-border-primary px-2 pb-5 md:px-0"
    >
      <h1 class="m-0 hidden text-2xl font-normal sm:block">Geplande recalls</h1>
    </div>
    <div class="mx-2 md:mx-0">
      <flux-table
        :selectableRows="true"
        :data="recalls"
        :rowValue="(row: PatientRecall) => row.id"
      >
        <flux-table-column label="Patient" prop="patient">
          <template #row="row: PatientRecall & { patient: Patient }">
            <router-link :to="'/patient/' + row.patient.zis_number">
              {{ fullName(row.patient) }}
            </router-link>
          </template>
        </flux-table-column>
        <flux-table-column label="Word gestuurd op" prop="send_at">
          <template #row="row: PatientRecall">
            {{ format(new Date(row.send_at), "dd-MM-Y") }}
          </template>
        </flux-table-column>
        <template #selectActions="{ selectedRows, resetSelection }">
          <flux-button size="mini" @click="() => resetSelection()">{{
            $t("general.cancel")
          }}</flux-button>
          <flux-button
            type="delete"
            size="mini"
            icon="fal fa-trash-alt"
            @click="() => cancelRecalls(selectedRows, resetSelection)"
            >Verwijder</flux-button
          >
        </template>
      </flux-table>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { apiClient } from "@/libraries/utils/axios";
import { fullName } from "@/models/Person";
import { useNotify } from "@/composables/notify";
import { useConfirm } from "@/composables/confirm";
import { PatientRecall } from "@/models/PatientRecall";
import { format } from "date-fns";
import { Patient } from "@/composables/patient";

const { confirm } = useConfirm();
const { notify } = useNotify();
const recalls = ref<(PatientRecall & { patient: Patient })[]>([]);
const selectedRows = ref<{ id: number; state: boolean }[]>([]);
onMounted(async () => {
  await fetchRecalls();
});

async function fetchRecalls() {
  const res = await apiClient.get("/recalls");
  recalls.value = res.data.recalls;
  selectedRows.value = recalls.value.map((i) => {
    return { id: i.id, state: false };
  });
}

async function cancelRecalls(ids: number[], resetSelection: () => void) {
  try {
    if (
      await confirm({
        title: "Wilt u deze recall(s) annuleren?",
        message: "",
        type: "delete",
        confirmButtonText: "Verwijderen",
        cancelButtonText: "Terug",
        canCancel: true,
      })
    ) {
      const res = await apiClient.delete(`/recalls/cancel`, {
        data: { ids: ids },
      });
      notify({
        type: "success",
        title: "Geannuleerd",
        message: "Recalls zijn geannuleerd",
      });
      await fetchRecalls();
      resetSelection();
    }
  } catch {
    notify({
      type: "error",
      title: "Er is iets fout gegaan",
      message: "Kan recall niet annuleren",
    });
  }
}
</script>
