<template>
  <transition
    @before-enter="beforeEnter"
    @after-leave="leave"
    :duration="300"
    enter-active-class="ease-in"
    enter-from-class="scale-95 opacity-0"
    enter-to-class="scale-100 opacity-100"
    leave-active-class="ease-out"
    leave-from-class="scale-100 opacity-100"
    leave-to-class="scale-95 opacity-0"
  >
    <dialog
      v-show="visible"
      :class="[
        'pointer-events-auto fixed bottom-auto transform overflow-visible overflow-y-auto rounded-lg border-none bg-background-card p-0 text-left shadow-xl transition-all duration-300 sm:align-middle md:max-h-screen ',
        private ? 'private' : '',
      ]"
      ref="dialog"
    >
      <div
        :class="[
          'overflow-visible bg-background-card px-4 pb-4 pt-5 sm:p-6 ',
          closeable ? 'mr-4' : '',
        ]"
      >
        <div class="absolute right-0 top-0 block pr-4 pt-4" v-if="closeable">
          <button
            class="flex h-6 w-6 cursor-pointer items-center justify-around rounded-md bg-background-card p-0 text-xl text-text-quaternary transition duration-75 hover:text-text-primaryHover focus:outline-none focus:ring focus:ring-offset-2 dark:ring-offset-neutral-800"
            type="button"
            @click="handleClose"
          >
            <span class="sr-only">Close</span>
            <i class="fal fa-times" aria-hidden="true" />
          </button>
        </div>
        <div class="sm:flex sm:items-start">
          <div
            class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center sm:mx-0 sm:h-10 sm:w-10"
            v-if="icon"
          >
            <i :class="['text-4xl', icon.class, icon.color]"></i>
          </div>
          <div class="mt-3 flex-grow sm:mt-0" :class="icon ? ' sm:ml-4' : ''">
            <h3
              class="m-0 flex-grow text-lg font-medium leading-6 text-text-primary"
              id="modal-title"
            >
              <slot name="header">
                {{ title }}
              </slot>
            </h3>
            <div class="mt-2 text-text-primary">
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex flex-col items-stretch justify-items-stretch gap-2 bg-background-tertiary px-4 py-3 sm:flex-row-reverse sm:px-6"
        v-if="$slots.footer"
      >
        <slot name="footer"></slot>
      </div>
    </dialog>
  </transition>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted, provide, ref } from "vue";
import dialogPolyfill from "dialog-polyfill";
import ModalManager from "@/libraries/utils/modalManager";

const props = withDefaults(
  defineProps<{
    onCancel?: () => void;
    title?: string;
    icon?: {
      class: string;
      color: string;
    };
    closeable?: boolean;
    visible?: boolean;
    private?: boolean;
  }>(),
  {
    closeable: true,
    visible: false,
    private: false,
  },
);

const sym = Symbol();

provide("inModal", true);

const emit = defineEmits<{
  (event: "update:visible", visible: boolean): void;
  (event: "close"): void;
}>();

const dialog = ref<HTMLDialogElement>();

function beforeEnter() {
  ModalManager.registerModal(sym);
  if (dialog.value && document.contains(dialog.value)) {
    dialog.value.showModal();
  }
}

function leave() {
  ModalManager.unregisterModal(sym);
  if (dialog.value?.open && document.contains(dialog.value)) {
    dialog.value.close();
  }
}

onBeforeUnmount(() => {
  ModalManager.unregisterModal(sym);
  if (dialog.value?.open && document.contains(dialog.value)) {
    dialog.value!.close();
  }
});

function handleClose() {
  emit("update:visible", false);
  props.onCancel?.();
  emit("close");
}

onMounted(() => {
  if (!("HTMLDialogElement" in window)) {
    dialogPolyfill.registerDialog(dialog.value!);
  }
  if (props.visible && document.contains(dialog.value!)) {
    dialog.value!.showModal();
  }

  dialog.value!.addEventListener("close", () => {
    handleClose();
  });
});
</script>

<style lang="postcss">
dialog.opacity-0 + .backdrop,
dialog.opacity-0::backdrop {
  opacity: 0;
  transition-duration: 300ms;
}

dialog.opacity-100 + .backdrop,
dialog.opacity-100::backdrop {
  opacity: 1;
  transition-duration: 300ms;
}

.private::backdrop {
  backdrop-filter: blur(4px);
}

@media only screen and (max-width: 767px) {
  @supports (max-height: 90dvh) {
    dialog {
      top: calc(5dvh / 2) !important;
      max-height: 95dvh;
    }
  }
  @supports not (max-height: 90dvh) {
    dialog {
      top: 0 !important;
      max-height: 90vh;
    }
  }
  @supports (overscroll-behavior: contain) {
    dialog {
      overscroll-behavior: contain;
    }
  }
  dialog.fixed {
    transform: none !important;
  }
}
</style>
