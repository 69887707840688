<template>
  <div class="relative">
    <DropdownWrapper
      :dropdownKey="dropdownKey"
      direction="right"
      @after-leave="dropdownOptionsFilter = undefined"
    >
      <template #button="slotProps">
        <FilterButton
          :disabled="disabled"
          @keydown.enter="
            (e: KeyboardEvent) => handleEnter(e, slotProps.handleKeyDown)
          "
          @hotkeyDown="slotProps.handleButtonHotkeyDown()"
        />
      </template>
      <template #default>
        <template v-if="!dropdownOptionsFilter && !textFilter">
          <flux-dropdown-item
            v-for="filter in inactiveFilters"
            @click.stop="selectFilter(filter)"
          >
            {{ filter.subject }}
          </flux-dropdown-item>
        </template>
        <template v-else-if="dropdownOptionsFilter">
          <FilterDropdownContents
            :filter="dropdownOptionsFilter"
            :filterManager="filterManager"
            @closed="handleActivation(dropdownOptionsFilter?.uuid)"
          />
        </template>
      </template>
    </DropdownWrapper>
    <template v-if="textFilter">
      <InputFilterModal
        :filter="textFilter"
        :filterManager="filterManager"
        :modalVisible="textFilter !== undefined"
        @cancel="textFilter = undefined"
        @save="handleActivation(textFilter?.uuid)"
      />
    </template>
    <template v-if="rangeFilter">
      <RangeFilterModal
        :filter="rangeFilter"
        :filterManager="filterManager"
        :modalVisible="rangeFilter !== undefined"
        @cancel="rangeFilter = undefined"
        @save="handleActivation(rangeFilter?.uuid)"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, PropType, ref } from "vue";
import DropdownWrapper from "@/components/ui/Dropdown/DropdownWrapper.vue";
import {
  Filter,
  FilterManager,
  OptionFilter,
  RangeFilter,
  TextFilter,
} from "@/models/Filter/filter";
import FilterDropdownContents from "./OptionFilter/OptionFilterDropdownContents.vue";
import InputFilterModal from "./InputFilter/InputFilterModal.vue";
import { dropdownManagerKey } from "@/libraries/managers/DropdownManager";
import FilterButton from "./FilterButton.vue";
import RangeFilterModal from "./RangeFilter/RangeFilterModal.vue";

const props = defineProps({
  filterManager: {
    required: true,
    type: Object as PropType<FilterManager>,
  },
});

const dropdownManager = inject(dropdownManagerKey);
const dropdownKey = Symbol();

const { inactiveFilters, activateFilter } = props.filterManager;

const dropdownOptionsFilter = ref<OptionFilter>();
const textFilter = ref<TextFilter>();
const rangeFilter = ref<RangeFilter>();

const disabled = computed(() => inactiveFilters.value?.length === 0);

function selectFilter(filter: Filter) {
  if (filter.filterType === "option") {
    dropdownOptionsFilter.value = filter;
    return;
  }
  if (filter.filterType === "input") {
    textFilter.value = filter;
    dropdownManager?.close(dropdownKey);
  }
  if (filter.filterType === "boolean") {
    activateFilter(filter.uuid);
    dropdownManager?.close(dropdownKey);
  }
  if (filter.filterType === "range") {
    rangeFilter.value = filter;
    dropdownManager?.close(dropdownKey);
  }
}

function handleActivation(uuid?: string) {
  if (!uuid) {
    return;
  }
  activateFilter(uuid);
  resetState();
}

function handleEnter(
  e: KeyboardEvent,
  handleKeyDown: (e: KeyboardEvent) => void,
) {
  handleKeyDown(e);
  e.preventDefault();
}

function resetState() {
  dropdownOptionsFilter.value = undefined;
  textFilter.value = undefined;
  rangeFilter.value = undefined;
}
</script>
