import { Avatar } from "@/models/Avatar";

export function buildUserAvatarElement(
  avatar: Avatar,
  big?: boolean,
): HTMLImageElement | SVGSVGElement {
  if (avatar.uses_photo && avatar.photo_data_uri) {
    const img = document.createElement("img");
    img.src = avatar.photo_data_uri;
    img.setAttribute(
      "style",
      [
        "border-radius: 50%",
        "box-shadow: inset 0px 0px 1px black",
        "display: block",
        "width: " + (big ? "40px" : "20px"),
        "height: " + (big ? "40px" : "20px"),
      ].join(";"),
    );
    return img;
  } else {
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svg.setAttribute(
      "style",
      [
        "display: block",
        "width: " + (big ? "40px" : "20px"),
        "height: " + (big ? "40px" : "20px"),
      ].join(";"),
    );
    svg.setAttribute("id", avatar.id.toString());

    const circle = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "circle",
    );
    circle.setAttribute("cx", "50%");
    circle.setAttribute("cy", "50%");
    circle.setAttribute("r", "50%");
    circle.setAttribute("fill", "#" + avatar.color);
    svg.appendChild(circle);

    const text = document.createElementNS("http://www.w3.org/2000/svg", "text");
    text.setAttribute("x", "50%");
    text.setAttribute("y", "50%");
    text.setAttribute("dy", ".35em");
    text.setAttribute("dx", "0 1");
    text.setAttribute("text-anchor", "middle");
    text.setAttribute(
      "style",
      "fill: white;font-size:" + (big ? "20px" : "11px"),
    );
    text.textContent = avatar.initials;
    svg.appendChild(text);

    return svg;
  }
}
