<template>
  <div>
    <flux-card-button-header
      class="m-2"
      :title="$t('patient.create.form.step.name.title')"
    />
    <flux-form v-flux-loading="loading" :errors="errors">
      <flux-card shadow="never">
        <flux-short-form>
          <flux-short-form-item
            :label="$t('patient.create.form.surname')"
            span="col-span-8"
            :required="true"
          >
            <flux-input
              v-model:modelValue="personaliaForm.surname"
              prop="surname"
              :required="true"
            />
          </flux-short-form-item>
          <flux-short-form-item
            :label="$t('patient.create.form.surname_prefix')"
            span="col-span-4"
          >
            <flux-input
              v-model:modelValue="personaliaForm.surname_prefix"
              prop="surname_prefix"
            />
          </flux-short-form-item>
          <flux-short-form-item
            :label="$t('patient.create.form.maiden_name')"
            span="col-span-8"
          >
            <flux-input
              v-model:modelValue="personaliaForm.maiden_name"
              prop="maiden_name"
            />
          </flux-short-form-item>
          <flux-short-form-item
            :label="$t('patient.create.form.maiden_name_prefix')"
            span="col-span-4"
          >
            <flux-input
              v-model:modelValue="personaliaForm.maiden_name_prefix"
              prop="maiden_name_prefix"
            />
          </flux-short-form-item>
          <flux-short-form-item
            :label="$t('patient.create.form.first_names')"
            span="col-span-8"
            :required="true"
          >
            <flux-input
              v-model:modelValue="personaliaForm.first_names"
              prop="first_names"
              ref="first_names"
              :required="true"
            />
          </flux-short-form-item>
          <flux-short-form-item
            :label="$t('patient.create.form.initials')"
            span="col-span-4"
            :required="true"
          >
            <flux-input
              v-model:modelValue="personaliaForm.initials"
              prop="initials"
              ref="initials"
              pattern="^[a-zA-Z\.\s\-]*$"
              :required="true"
            />
          </flux-short-form-item>
          <flux-short-form-item
            :label="$t('patient.create.form.nickname')"
            span="col-span-8"
          >
            <flux-input
              v-model:modelValue="personaliaForm.nickname"
              prop="nickname"
            />
          </flux-short-form-item>
        </flux-short-form>
      </flux-card>
      <flux-card-button-header
        class="m-2 mt-4"
        :title="$t('patient.create.form.step.basic_info')"
      />
      <flux-card shadow="never">
        <flux-short-form>
          <flux-short-form-item :label="$t('patient.create.form.gender')">
            <flux-radio-button-group>
              <flux-radio-button
                v-for="gender in genderOptions"
                v-model:modelValue="personaliaForm.gender"
                :key="gender.value"
                :optionValue="gender.value"
                :label="gender.value"
                :collapse="true"
              >
                {{ gender.label }}
              </flux-radio-button>
            </flux-radio-button-group>
          </flux-short-form-item>
          <flux-short-form-item
            :label="$t('patient.create.form.multiple_birth')"
          >
            <flux-switch
              v-model:modelValue="personaliaForm.multiple_birth"
              :active-text="
                personaliaForm.multiple_birth
                  ? $t('patient.create.form.multiple_birth_on')
                  : $t('patient.create.form.multiple_birth_off')
              "
            >
            </flux-switch>
          </flux-short-form-item>
          <flux-short-form-item
            v-if="hasFeatureFlag('email-templates')"
            :required="true"
            :label="$t('patient.create.form.patient_title')"
          >
            <flux-select v-model:modelValue="personaliaForm.patient_title_uuid">
              <option
                v-for="patientTitle in patientTitles"
                :value="patientTitle.uuid"
                :key="patientTitle.uuid"
              >
                {{ patientTitle.short_form }} / {{ patientTitle.long_form }}
              </option>
            </flux-select>
          </flux-short-form-item>
          <flux-short-form-item
            v-if="hasFeatureFlag('email-templates')"
            :required="true"
            :label="$t('patient.create.form.salutation_template')"
          >
            <flux-select
              v-model:modelValue="personaliaForm.salutation_template_uuid"
            >
              <option :value="null">Praktijk standaard</option>
              <option
                v-for="salutationTemplate in salutationTemplates"
                :value="salutationTemplate.uuid"
                :key="salutationTemplate.uuid"
              >
                {{ salutationTemplate.preview }}
              </option>
            </flux-select>
          </flux-short-form-item>
          <flux-short-form-item :label="$t('patient.create.form.languages')">
            <flux-multiselect
              v-model:modelValue="personaliaForm.languages"
              :options="languages"
              idKey="code"
              labelKey="communication_language_nl"
            />
          </flux-short-form-item>
        </flux-short-form>
        <flux-button-group class="mx-4 mt-4 justify-end">
          <flux-submit-button :disabled="loading" @click="update()">
            {{ $t("general.update") }}
          </flux-submit-button>
        </flux-button-group>
      </flux-card>
    </flux-form>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, reactive, ref, toRef, watch } from "vue";
import { Language } from "../../models/Language";
import { useNotify } from "@/composables/notify";
import { MessageBag } from "@/libraries/utils/MessageBag";
import { isValidationErrorResponse } from "@/libraries/utils/errorHandling";
import { Patient, usePatient, useUpdatePatient } from "@/composables/patient";
import { $t } from "@/libraries/i18n";
import { pinia } from "@/libraries/store";
import { useLanguageStore } from "@/libraries/store/Languages";
import { useSalutationTemplates } from "@/composables/salutationTemplate";
import { usePatientTitles } from "@/composables/patientTitle";
import { hasFeatureFlag } from "@/libraries/plugins/hasFeatureFlag";

const { notify } = useNotify();

const props = defineProps<{ zisNumber: number }>();

const loading = ref(false);

const genderOptions = ref<{ value: string; label: string }[]>([]);
const languages = ref<Language[]>([]);

const patientTitleAutoChangeLocked = hasFeatureFlag("email-templates");

const errors = ref(new MessageBag({}));
const languageStore = useLanguageStore(pinia);

const { data: patientTitles, isFetching: fetchingPatientTitles } =
  usePatientTitles();

const personaliaForm = reactive({
  surname: "",
  surname_prefix: "",
  maiden_name: "",
  maiden_name_prefix: "",
  first_names: "",
  nickname: "",
  initials: "",
  gender: "",
  patient_title_uuid: null as string | null,
  custom_short_title: null as string | null,
  custom_long_title: null as string | null,
  salutation_template_uuid: null as string | null,
  custom_salutation: null as string | null,
  multiple_birth: false,
  deceased: false,
  languages: [] as string[],
});

const { data: patient } = usePatient(toRef(props, "zisNumber"));
const { updatePatient } = useUpdatePatient(toRef(props, "zisNumber"));

const { data: salutationTemplates } = useSalutationTemplates();

watch(patient, onPatientChanged, { immediate: true, deep: false });
function onPatientChanged(val: Patient | undefined) {
  if (val) {
    if (val.surname) {
      personaliaForm.surname = val.surname;
    }
    if (val.surname_prefix) {
      personaliaForm.surname_prefix = val.surname_prefix;
    }
    if (val.maiden_name) {
      personaliaForm.maiden_name = val.maiden_name;
    }
    if (val.maiden_name_prefix) {
      personaliaForm.maiden_name_prefix = val.maiden_name_prefix;
    }
    if (val.first_names) {
      personaliaForm.first_names = val.first_names;
    }
    if (val.nickname) {
      personaliaForm.nickname = val.nickname;
    }
    if (val.initials) {
      personaliaForm.initials = val.initials;
    }
    if (val.gender) {
      personaliaForm.gender = val.gender;
    }
    if (val.multiple_birth !== undefined) {
      personaliaForm.multiple_birth = val.multiple_birth;
    }
    if (val.languages) {
      personaliaForm.languages = val.languages.map((l) => l.code);
    }
    personaliaForm.patient_title_uuid = val.patient_title_uuid ?? null;
    personaliaForm.custom_short_title = val.custom_short_title ?? null;
    personaliaForm.custom_long_title = val.custom_long_title ?? null;
    personaliaForm.salutation_template_uuid =
      val.salutation_template_uuid ?? null;
    personaliaForm.custom_salutation = val.custom_salutation ?? null;
  }
}

watch(fetchingPatientTitles, () => {
  if (fetchingPatientTitles.value) {
    return;
  }
  setPatientTitle();
});

watch(
  () => personaliaForm.gender,
  () => {
    setPatientTitle();
  },
);

onMounted(async () => {
  const toOptions = (translation: string, list: string[]) =>
    list.map((value) => {
      return {
        value,
        label: $t(translation + "." + value),
      };
    });

  genderOptions.value = toOptions("labels.gender", ["M", "F", "UNK", "UN"]);
  genderOptions.value = toOptions("labels.gender", ["M", "F", "UNK", "UN"]);

  languages.value = await languageStore.findAll();
});

const setPatientTitle = () => {
  if (patientTitleAutoChangeLocked && personaliaForm.patient_title_uuid) {
    return;
  }
  personaliaForm.patient_title_uuid =
    patientTitles.value?.filter(
      (x) =>
        x.language === "nl-NL" &&
        x.default_for_gender === personaliaForm.gender,
    )[0]?.uuid ?? null;
};

async function update() {
  if (!patient.value) {
    return;
  }
  loading.value = true;
  const payload = personaliaForm;
  try {
    await updatePatient(payload);
    notify({
      title: $t("general.success"),
      type: "success",
      message: "Patient has been updated",
    });
  } catch (e) {
    if (isValidationErrorResponse(e)) {
      errors.value = MessageBag.fromResponse(e.response);
    }
    notify({
      title: "Kan patient niet aanpassen",
      message: "Could not update patient",
      type: "error",
    });
  } finally {
    loading.value = false;
  }
}
</script>
