<template>
  <div class="flex space-x-1 pl-3 text-xs">
    <div class="inline-flex items-center space-x-1">
      <span>{{ format(appointment.start, "HH:mm") }}</span>
      <i
        class="fal fa-long-arrow-right text-sm text-text-quaternary group-hover:text-text-primaryHover"
      />
      <span>{{ format(appointment.end, "HH:mm") }}</span>
    </div>
    <div
      class="truncate"
      v-if="appointment.appointment_type_id && appointmentTypes"
    >
      - {{ getAppointmentType(appointment.appointment_type_id)?.name }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { AppointmentType } from "@/models/Appointment";
import { AppointmentApi } from "@/libraries/repositories/appointmentRepositoryUsingApi";
import { format } from "date-fns";

export default defineComponent({
  props: {
    appointment: {
      type: Object as PropType<AppointmentApi>,
      required: true,
    },
    appointmentTypes: {
      type: Array as PropType<Array<AppointmentType>> | null,
      default: null,
    },
  },
  setup(props) {
    const getAppointmentType = (id?: number) => {
      if (!props.appointmentTypes) {
        return;
      }
      return props.appointmentTypes.find((type) => type.id === id);
    };
    return {
      format,
      getAppointmentType,
    };
  },
});
</script>
