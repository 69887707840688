import { MaybeRef } from "@tanstack/vue-query/build/legacy/types";
import { getSortedData, SortState } from "@/components/ui/Table/tableHelper";
import {
  useQuery,
  useQueryClient,
  keepPreviousData,
} from "@tanstack/vue-query";
import { computed, ref, Ref, unref, watch } from "vue";
import { apiClient } from "../utils/axios";
import { QueryClient } from "@tanstack/query-core";
import { z, ZodTypeAny } from "zod";
import { Paginator } from "@/models/Paginator";

export function useLocalDataPaginator<T>(
  allData: Ref<T[] | undefined>,
  sortState: Ref<SortState<T[keyof T]> | undefined>,
  rowsPerPage?: number,
): {
  data: Ref<T[]>;
  paginator: Ref<Paginator>;
} {
  const currentPage = ref(1);
  const perPage = rowsPerPage ?? 20;

  const sortedData = computed(() =>
    getSortedData(allData.value ?? [], sortState.value),
  );

  const data = computed(() =>
    sortedData.value.filter(
      (_, index) =>
        (currentPage.value - 1) * perPage <= index &&
        index < currentPage.value * perPage,
    ),
  );

  watch(
    () => sortedData.value.length,
    () => (currentPage.value = 1),
  );

  const totalPages = computed(() =>
    Math.max(Math.ceil(sortedData.value.length / perPage), 1),
  );

  const nextPage = computed(() =>
    Math.min(currentPage.value + 1, totalPages.value),
  );
  const previousPage = computed(() => Math.max(currentPage.value - 1, 1));

  function next() {
    currentPage.value = nextPage.value;
  }

  function prev() {
    currentPage.value = previousPage.value;
  }

  return {
    data,
    paginator: computed(() => {
      return {
        current_page: currentPage.value,
        total: sortedData.value.length,
        per_page: perPage,
        last_page: totalPages.value,
        next,
        prev,
      };
    }),
  };
}

export function useApiPaginator<T>(
  url: string,
  queryString: Ref<string | undefined>,
  sortState?: Ref<SortState<T[keyof T]> | undefined>,
): {
  data: Ref<T[]>;
  paginator: Ref<Paginator>;
  isLoading: Ref<boolean>;
  isFetching: Ref<boolean>;
  invalidateData: (url: string) => void;
} {
  const { queryClient, invalidateData } = useApiPaginatorQueryClient();

  const currentPage = ref(1);

  watch(queryString, () => (currentPage.value = 1));

  const query = useQuery({
    gcTime: 1000 * 60 * 60 * 24,
    staleTime: 1000 * 60 * 60 * 1,
    queryKey: [url, currentPage, queryString, sortState] as const,
    queryFn: (context) =>
      getPage<T>(
        context.queryKey[0],
        context.queryKey[1],
        context.queryKey[2],
        context.queryKey[3],
        //filterLogic.value === "AND" ? "all" : "any"
      ),
    placeholderData: keepPreviousData,
  });

  const total = computed(() => query.data.value?.total ?? 0);
  const totalPages = computed(() =>
    query.data.value?.per_page
      ? Math.max(Math.ceil(total.value / query.data.value?.per_page), 1)
      : 1,
  );

  const nextPage = computed(() =>
    Math.min(currentPage.value + 1, totalPages.value),
  );
  const previousPage = computed(() => Math.max(currentPage.value - 1, 1));

  watch([nextPage, () => sortState], () =>
    prefetchPage(queryClient, url, nextPage, queryString, sortState),
  );
  watch([previousPage, () => sortState], () =>
    prefetchPage(queryClient, url, previousPage, queryString, sortState),
  );

  function next() {
    currentPage.value = nextPage.value;
  }

  function prev() {
    currentPage.value = previousPage.value;
  }

  return {
    data: computed(() => query.data.value?.data ?? []),
    paginator: computed(() => {
      return {
        current_page: currentPage.value,
        total: total.value,
        per_page: query.data.value?.per_page ?? 0,
        last_page: totalPages.value,
        next_page_url: query.data.value?.next_page_url,
        prev_page_url: query.data.value?.prev_page_url,
        next,
        prev,
      };
    }),
    isLoading: query.isLoading,
    isFetching: query.isFetching,
    invalidateData,
  };
}

export function useApiPaginatorQueryClient() {
  const queryClient = useQueryClient();
  function invalidateData(url: string) {
    queryClient.invalidateQueries({ queryKey: [url] });
  }

  return { queryClient, invalidateData };
}

async function getPage<T>(
  url: string,
  currentPage: MaybeRef<number>,
  queryString: MaybeRef<string | undefined>,
  SortState?: MaybeRef<SortState<T[keyof T]> | undefined>,
): Promise<{
  data: T[];
  current_page: number;
  per_page: number;
  total: number;
  next_page_url: string | undefined;
  prev_page_url: string | undefined;
}> {
  const { data } = await apiClient.get(
    formatUrl(url, currentPage, queryString, SortState),
  );

  return data;
}

function prefetchPage<T>(
  queryClient: QueryClient,
  url: string,
  nextPage: MaybeRef<number>,
  queryString: MaybeRef<string | undefined>,
  sortState?: MaybeRef<SortState<T[keyof T]> | undefined>,
) {
  queryClient.prefetchQuery({
    gcTime: 1000 * 60 * 60 * 24,
    staleTime: 1000 * 60 * 60 * 1,
    queryKey: [url, nextPage, queryString, sortState] as const,
    queryFn: (context) =>
      getPage<T>(
        context.queryKey[0],
        context.queryKey[1],
        context.queryKey[2],
        context.queryKey[3],
      ),
  });
}

function formatUrl<T>(
  url: string,
  currentPage: MaybeRef<number>,
  queryString: MaybeRef<string | undefined>,
  sortState?: MaybeRef<SortState<T[keyof T]> | undefined>,
): string {
  let urlBuilder = `${url}?page=${unref(currentPage)}`;
  if (unref(queryString)) {
    urlBuilder += `&${unref(queryString)}`;
  }
  const sort = unref(sortState);
  if (sort) {
    urlBuilder += `&sort_by=${sort.column}&sort_direction=${sort.direction}`;
  }
  return urlBuilder;
}

export const laravelLengthAwarePaginatorScheme = <T extends ZodTypeAny>(
  data: T,
) =>
  z
    .object({
      current_page: z.number(),
      data: z.array(data),
      first_page_url: z.string(),
      from: z.number().optional(),
      last_page: z.number(),
      last_page_url: z.string(),
      next_page_url: z.string().optional(),
      path: z.string(),
      per_page: z.number(),
      prev_page_url: z.string().optional(),
      to: z.number().optional(),
      total: z.number(),
    })
    .readonly();
