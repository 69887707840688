<template>
  <div class="contents" v-flux-loading="loading">
    <flux-card-button-header
      class="m-2"
      :title="$t('patient.create.form.step.contact_info.title')"
    />
    <flux-form class="flex flex-col gap-4">
      <PatientEditContactInfoForm
        :zisNumber="zisNumber"
        @updated="handleUpdate"
        @loading="updateLoadingState"
      />

      <flux-card-button-header class="m-2" title="Voorkeuren" />
      <!-- :title="$t('patient.create.form.step.contact_info.preferences')" -->

      <PatientEditEmailPreferences
        :zisNumber="zisNumber"
        @updated="handleUpdate"
        @loading="updateLoadingState"
      />
    </flux-form>
  </div>
</template>

<script setup lang="ts">
import { ref, toRef } from "vue";
import PatientEditContactInfoForm from "./PatientEditContactInfoForm.vue";
import PatientEditEmailPreferences from "./PatientEditEmailPreferences.vue";

const props = defineProps<{
  zisNumber: number;
}>();

const loading = ref(false);
const emit = defineEmits<{
  (e: "updated"): void;
}>();

function handleUpdate() {
  emit("updated");
}

function updateLoadingState(value: boolean) {
  loading.value = value;
}
</script>
