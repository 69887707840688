<template>
  <div v-flux-loading="loading || isFetching">
    <div class="flex space-x-1">
      <template v-if="filterManager">
        <Filters :filter-manager="filterManager"></Filters>
      </template>
      <flux-dropdown
        v-if="!restrictToCurrentUser"
        typeButton="text"
        iconPosition="left"
        icon="fal fa-sort-alt"
        direction="right"
        :label="$t(`patients.sorting.${sortBy}_${direction}`)"
      >
        <flux-dropdown-item @click="setSorting('surname', 'asc')">{{
          $t("patients.sorting.surname_asc")
        }}</flux-dropdown-item>
        <flux-dropdown-item @click="setSorting('created_at', 'asc')">{{
          $t("patients.sorting.created_at_asc")
        }}</flux-dropdown-item>
        <flux-dropdown-item @click="setSorting('created_at', 'desc')">{{
          $t("patients.sorting.created_at_desc")
        }}</flux-dropdown-item>
      </flux-dropdown>
    </div>

    <PaginatedTable :data="data" :paginator="paginator">
      <flux-table-column
        prop="name"
        :label="$t('general.name')"
        primary
        columnClass="min-w-min sm:min-w-0"
      >
        <template #row="row: Patient">
          <patient-link :patient="row" />
        </template>
      </flux-table-column>
      <flux-table-column
        v-if="!restrictToCurrentUser"
        prop="date_of_birth"
        :label="$t('general.date_of_birth')"
        :smLabel="$t('general.date_of_birth_abbreviated')"
        thClass="w-24 sm:w-32"
      >
        <template #row="row: Patient">
          <span v-if="row.date_of_birth">
            {{ dateFilter(row.date_of_birth) }}
          </span>
          <span class="italic text-text-quaternary" v-else>
            {{ $t("general.unknown") }}
          </span>
        </template>
      </flux-table-column>

      <flux-table-column
        v-if="!restrictToCurrentUser"
        prop="gender"
        :label="$t('general.gender')"
        :smLabel="''"
        thClass="w-8 sm:w-12 min-w-min"
        tdClass="text-center"
      >
        <template #row="row: Patient">
          <gender-icon v-if="row.gender" :gender="row.gender"></gender-icon>
        </template>
      </flux-table-column>
      <flux-table-column
        v-if="!restrictToCurrentUser"
        prop="zis_number"
        :label="$t('general.zis_number')"
        :smLabel="$t('general.zis')"
        thClass="w-16 sm:w-24"
      >
      </flux-table-column>
      <flux-table-column
        prop=""
        label="Acties"
        thClass="w-10 sm:w-16 text-center"
        tdClass="text-center"
      >
        <template #row="row: Patient">
          <RowActions :actions="rowActions" :row="row" />
        </template>
      </flux-table-column>
    </PaginatedTable>
  </div>
</template>

<script lang="ts" setup>
import { apiClient } from "@/libraries/utils/axios";
import { computed, PropType, ref, toRef, watch } from "vue";
import { Patient } from "@/models/Patient";
import { PatientLabel } from "@/models/PatientLabel";
import Filters from "@/components/ui/Filter/Filters.vue";
import { usePatientFilter } from "@/components/PatientOverview/usePatientFilter";
import GenderIcon from "@/components/GenderIcon.vue";
import { dateFilter } from "@/filters";
import { useApiPaginator } from "@/libraries/paginators/usePaginator";
import PaginatedTable from "@/components/ui/Table/PaginatedTable.vue";
import { useNotify } from "@/composables/notify";
import { getUser } from "@/libraries/plugins/getUser";
import { FilterEntryQuery } from "@/libraries/managers/FilterManager";
import router from "@/libraries/router";
import RowActions from "./ui/Table/RowActions.vue";
import { RowAction } from "./ui/Table/RowActions";

const { notify } = useNotify();

const props = defineProps({
  labels: {
    required: true,
    type: Array as PropType<PatientLabel[]>,
  },
  restrictToCurrentUser: {
    type: Boolean,
    default: false,
  },
});

const sortBy = ref<"created_at" | "surname">("created_at");
const direction = ref<"asc" | "desc">("desc");
const filterValue = ref<(number | "none")[]>([]);

const setSorting = (
  sortByParam: "created_at" | "surname",
  directionParam: "asc" | "desc",
) => {
  sortBy.value = sortByParam;
  direction.value = directionParam;
};

const forcedFilterParams = computed(() => {
  const params: { [key: string]: FilterEntryQuery } = {};
  const currentUser = getUser();

  if (props.restrictToCurrentUser) {
    params["user_ids"] = {
      type: "optionCheckbox",
      values: [currentUser.id],
      logic: "AND",
    };
  }

  return params;
});

const { filterManager, filteredEntries } = usePatientFilter(
  toRef(props, "labels"),
  forcedFilterParams,
);

const rowActions = computed((): RowAction<unknown, Patient>[] => {
  return [
    {
      description: "Medische feed openen",
      disabled: false,
      icon: "fal fa-notes-medical",
      callback: (row: Patient) => {
        goToMedicalFeed(row);
      },
    },
    {
      description: "Behandeltraject openen",
      disabled: false,
      icon: "fal fa-diagnoses",
      callback: (row: Patient) => {
        goToReferrals(row);
      },
    },
    {
      description: "Afspraken openen",
      disabled: false,
      icon: "fal fa-calendar-check",
      callback: (row: Patient) => {
        goToAppointments(row);
      },
    },
    {
      description: "Financiën openen",
      disabled: false,
      icon: "fal fa-euro-sign",
      callback: (row: Patient) => {
        goToFinance(row);
      },
    },
    {
      description: (row: Patient) => patientIsActive(row),
      disabled: props.restrictToCurrentUser,
      icon: "fal fa-user-minus",
      callback: (row: Patient) => {
        changeInactiveState(row);
      },
    },
  ];
});
const url = "/patients";
const { data, paginator, isFetching, invalidateData } =
  useApiPaginator<Patient>(
    url,
    filterManager.filterQueryString,
    computed(() => {
      return {
        column: sortBy.value,
        direction: direction.value,
      };
    }),
  );

const loading = ref(false);

function goToMedicalFeed(row: Patient) {
  router.push(`/patient/${row.zis_number}#feed`);
}

function goToReferrals(row: Patient) {
  router.push(`/patient/${row.zis_number}#referrals`);
}

function goToAppointments(row: Patient) {
  router.push(`/patient/${row.zis_number}#appointments`);
}

function goToFinance(row: Patient) {
  router.push(`/patient/${row.zis_number}#finance`);
}

watch(filteredEntries, () => {
  const value: (number | "none")[] = [];
  if (filteredEntries.value.length == undefined) {
    return;
  }
  if (hasActiveFilters.value) {
    filteredEntries.value.map((entry) => {
      value.push("id" in entry ? entry.id : "none");
    });
  }

  filterValue.value = value;
});

const hasActiveFilters = computed(
  () => filterManager.activeFilters.value.length > 0,
);

function patientIsActive(row: { inactive_at?: string }): string {
  return row.inactive_at ? "Activeren" : "Deactiveren";
}

async function changeInactiveState(row: any) {
  loading.value = true;
  if (!row.inactive_at) {
    try {
      await apiClient.patch("/patients/:zis_number/deactivate", row, {
        params: { zis_number: row.zis_number },
      });
      invalidateData(url);
      notify({
        type: "success",
        message: "Patient is gedeactiveerd",
      });
    } catch (e) {
      notify({
        type: "error",
        message: "Patient is niet gedeactiveerd",
      });
    } finally {
      loading.value = false;
    }
  }
  if (row.inactive_at) {
    try {
      await apiClient.patch("/patients/:zis_number/activate", row, {
        params: { zis_number: row.zis_number },
      });
      invalidateData(url);
      notify({
        type: "success",
        message: "Patient is geactiveerd",
      });
    } catch (e) {
      notify({
        type: "error",
        message: "Patient is niet geactiveerd",
      });
    } finally {
      loading.value = false;
    }
  }
}
</script>
