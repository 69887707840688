<template>
  <div class="contents">
    <h3 class="mt-0 flex items-center gap-2 font-normal">
      <span>{{ professional.fullname }}</span>
      <flux-label
        v-if="
          professional.specialism_code &&
          getSpecialismNameByCode(professional.specialism_code)
        "
        type="neutral"
        >{{ getSpecialismNameByCode(professional.specialism_code) }}</flux-label
      >
      <flux-label type="neutral">
        {{ $t(`healthcare_professional.role.${professional.role}`) }}
      </flux-label>
    </h3>
    <div v-if="professional.AGB_code">
      <span class="text-sm font-normal text-text-quaternary"> AGB code </span>
      <div><AgbCode :agb-code="professional.AGB_code" /></div>
    </div>
    <div v-if="professional.UZI_code">
      <span class="text-sm font-normal text-text-quaternary"> UZI code </span>
      <div>{{ professional.UZI_code }}</div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { pinia } from "@/libraries/store";
import { Specialism, useSpecialismStore } from "@/libraries/store/Specialisms";
import { HealthcareProfessional } from "@/models/HealthcareProfessional";
import { onMounted, ref } from "vue";
import AgbCode from "@/components/ui/AgbCode.vue";

defineProps<{
  professional: Omit<HealthcareProfessional, "id">;
}>();

const specialisms = ref<Specialism[]>([]);
const specialismStore = useSpecialismStore(pinia);

onMounted(() =>
  specialismStore.findAll().then((res) => {
    specialisms.value = res;
  }),
);

function getSpecialismNameByCode(code: string): string | undefined {
  return specialisms.value.find((s) => s.code === code)?.display_name_NL;
}
</script>
