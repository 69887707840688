<template>
  <!-- Overflow for birthday cake -->
  <flux-card :overflows="true">
    <div class="flex">
      <UploadPhotoComponent
        v-model:modelValue="updatePhoto"
        :person="patient"
        @update:model-value="() => invalidatePatient(zisNumber)"
      />
      <div class="flex min-h-[5rem] flex-grow">
        <div class="hidden w-[80px] flex-none md:block">
          <template v-if="!isFetching && patient">
            <div
              class="group relative cursor-pointer filter"
              @click="updatePhoto = true"
            >
              <PatientPhoto
                class="absolute filter transition duration-75 group-hover:blur-sm group-hover:brightness-110"
                :zisNumber
                :key="patient.photo_filename"
                size="80px"
              />
              <div
                class="absolute flex h-[80px] w-[80px] items-center justify-center rounded-full opacity-0 group-hover:opacity-100"
              >
                <i class="fas fa-camera text-xl text-text-primary"></i>
              </div>
            </div>
          </template>
          <span v-else>
            <i
              class="fas fa-circle animate-pulse text-gray-200"
              style="font-size: 80px; margin-left: -1.25px"
            ></i>
          </span>
        </div>
        <div class="relative flex-grow overflow-visible pl-2">
          <!-- Show name -->
          <div class="flex justify-between">
            <h1 class="mb-2 mt-0 text-xl font-semibold">
              <div class="text-sm" v-if="!patient">&nbsp;</div>
              <template v-else>{{ fullName(patient) }}</template>
            </h1>
          </div>
          <div class="text-sm" v-if="patientMail">
            <a v-bind:href="'mailto:' + patientMail">{{ patientMail }}</a>
          </div>
          <!-- Show date of birth, gender, birthday -->
          <div class="flex justify-between text-sm">
            <div class="filler-small" v-if="!patient">&nbsp;</div>
            <div class="flex-shrink-0" v-else>
              <template v-if="patient.date_of_birth">
                <flux-tooltip :content="patientAge">
                  <!-- Show a birthday cake with confetti on patient's birthday -->
                  <span class="mr-1">
                    <BirthdayComponent class="ml-0.5 mr-px" v-if="isBirthday" />
                    {{ dateFilter(patient.date_of_birth) }} ({{
                      patient.deceased
                        ? `Overleden, ${patient.date_of_death ? dateFilter(patient.date_of_death) + ", " : ""}`
                        : ""
                    }}{{ patientAgeShort }})
                  </span>
                </flux-tooltip>
              </template>
              <span v-else-if="patient.deceased">
                Overleden,
                {{
                  patient.date_of_death ? dateFilter(patient.date_of_death) : ""
                }}
              </span>
              <span v-else>
                {{ $t("patient.overview.date_of_birth_unknown") }}
              </span>
              <GenderIconComponent
                v-if="patient.gender"
                :gender="patient.gender"
              />
              <!-- Show zis number -->
              <div class="text-sm">
                <div class="filler-small" v-if="!patient">&nbsp;</div>
                <template v-else>
                  <flux-tooltip content="ZIS-nummer">
                    <img
                      src="./../../assets/images/logo.svg"
                      style="height: 15px; margin-bottom: -2.5px"
                    />
                  </flux-tooltip>
                  {{ zisNumber }}
                </template>
              </div>
            </div>
            <div class="ml-1 w-max self-end overflow-x-auto">
              <Labels
                v-if="patient && patient.labels"
                :labels="patient.labels"
              ></Labels>
            </div>
          </div>

          <div
            class="text-sm"
            v-if="showCompany && patient?.company_division_id"
          >
            <CompanyDivision
              :id="patient.company_division_id"
              :key="patient.company_division_id"
            />
          </div>
        </div>
      </div>

      <div class="flex flex-col items-end justify-between">
        <span class="mr-4 mt-1 text-sm" v-if="treatmentHistory">
          {{ treatmentHistory.treatment_count }}
          {{ $t("general.treatment", treatmentHistory.treatment_count) }}
          <span
            class="text-xs text-text-tertiary"
            v-if="treatmentHistory.planned_appointment_count"
          >
            (en {{ treatmentHistory.planned_appointment_count }} gepland)
          </span>
        </span>
        <div
          class="flex items-center gap-2 text-sm"
          v-if="
            activeZorgvergoedingPayerInsurers &&
            activeZorgvergoedingPayerInsurers.length > 0 &&
            linkToZorgvergoeding
          "
        >
          <router-link :to="linkToZorgvergoeding">
            {{ activeZorgvergoedingPayerInsurers.length }}
            {{
              $t(
                "payer_insurer.package",
                activeZorgvergoedingPayerInsurers.length,
              ).toLowerCase()
            }}
          </router-link>
          <i
            class="far fa-angle-left cursor-pointer text-text-quaternary active:text-text-primaryActive group-hover:text-text-primaryHover"
            v-if="activeZorgvergoedingPayerInsurers.length > 1"
            @click="subIndex()"
          ></i>
          <template
            v-for="(payerInsurer, idx) in activeZorgvergoedingPayerInsurers"
          >
            <ZorgvergoedingLabelUsingProp
              v-if="idx === selectedPackageIndex"
              :key="idx"
              :zorgvergoedingResults="payerInsurer.results"
              displayDetails="modal"
              :errored="zorgvergoedingError === zorgvergoedingRatelimitError"
            />
          </template>
          <i
            class="far fa-angle-right cursor-pointer text-text-quaternary active:text-text-primaryActive group-hover:text-text-primaryHover"
            v-if="activeZorgvergoedingPayerInsurers.length > 1"
            @click="addIndex()"
          ></i>
        </div>
      </div>
      <div>
        <div class="actions">
          <slot name="actions"></slot>
        </div>
      </div>
    </div>
  </flux-card>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import BirthdayComponent from "../Birthday.vue";
import GenderIconComponent from "../GenderIcon.vue";
import UploadPhotoComponent from "../UploadPhoto.vue";
import CompanyDivision from "@/components/ui/CompanyDivision.vue";
import { hasFeatureFlag } from "@/libraries/plugins/hasFeatureFlag";
import { fullName } from "@/models/Person";
import { dateFilter } from "@/filters";
import { usePatient } from "@/composables/patient";
import { hasApp } from "@/libraries/plugins/getUser";
import Labels from "@/components/Labels.vue";
import { useTreatmentHistory } from "@/composables/treatmentHistory";
import { objectIsActive } from "@/libraries/utils/objectIsActive";
import ZorgvergoedingLabelUsingProp from "./Zorgvergoedingen/ZorgvergoedingLabelUsingProp.vue";
import { usePatientZorgvergoedingen } from "@/composables/zorgvergoedingen";
import { ZorgvergoedingResult } from "@/models/zorgvergoeding";
import {
  addMonths,
  addYears,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
  format,
} from "date-fns";
import { $t } from "@/libraries/i18n";
import PatientPhoto from "./PatientPhoto.vue";
import { zorgvergoedingRatelimitError } from "@/queries/zorgvergoedingen/zorgvergoedingenQuery";

const props = defineProps<{
  zisNumber: number;
}>();

const zisNumber = computed(() => props.zisNumber);

const { data: patient, invalidatePatient, isFetching } = usePatient(zisNumber);

const { data: treatmentHistory } = useTreatmentHistory(
  zisNumber,
  computed(() => format(new Date(), "yyyy")),
);

const { data: patientZorgvergoedingen, error: zorgvergoedingError } =
  usePatientZorgvergoedingen(
    zisNumber,
    computed(() => new Date().toISOString().substring(0, 10)),
  );

const activeZorgvergoedingPayerInsurers = computed(() =>
  patientZorgvergoedingen.value?.filter(
    (data) =>
      objectIsActive({ object: data.payer_insurer }) &&
      data.results.some((res) => hasSomeInsurance(res)),
  ),
);

function hasSomeInsurance(res: ZorgvergoedingResult) {
  return res.data.aantal || res.data.mtaant || res.data.bedrag;
}

const selectedPackageIndex = ref(0);

function addIndex() {
  if (!activeZorgvergoedingPayerInsurers.value) {
    return;
  }
  const maxIndex = activeZorgvergoedingPayerInsurers.value.length - 1;
  if (selectedPackageIndex.value + 1 > maxIndex) {
    selectedPackageIndex.value = 0;
  } else {
    selectedPackageIndex.value++;
  }
}

function subIndex() {
  if (!activeZorgvergoedingPayerInsurers.value) {
    return;
  }
  const maxIndex = activeZorgvergoedingPayerInsurers.value.length - 1;
  if (selectedPackageIndex.value - 1 < 0) {
    selectedPackageIndex.value = maxIndex;
  } else {
    selectedPackageIndex.value--;
  }
}

const updatePhoto = ref(false);

const isBirthday = computed((): boolean => {
  if (patient.value?.deceased === true) {
    return false;
  }
  const dateOfBirth = patient.value?.date_of_birth;
  if (!dateOfBirth) {
    return false;
  }
  const today = new Date();

  return (
    dateOfBirth.getDate() === today.getDate() &&
    dateOfBirth.getMonth() === today.getMonth()
  );
});

const patientAge = computed((): string => {
  if (!patient.value || !patient.value.date_of_birth) {
    return "";
  }

  const referenceDate = patient.value.date_of_death ?? new Date();

  const ageInYears = differenceInYears(
    referenceDate,
    patient.value.date_of_birth,
  );
  const ageInMonths = differenceInMonths(
    referenceDate,
    addYears(patient.value.date_of_birth, ageInYears),
  );
  const ageInDays = differenceInDays(
    referenceDate,
    addMonths(addYears(patient.value.date_of_birth, ageInYears), ageInMonths),
  );
  if (ageInYears < 1) {
    const ageInDays = differenceInDays(
      referenceDate,
      addMonths(addYears(patient.value.date_of_birth, ageInYears), ageInMonths),
    );
    return (
      ageInMonths.toString() +
      " " +
      $t("patient.overview.months_old", ageInMonths) +
      " " +
      ageInDays.toString() +
      " " +
      $t("patient.overview.days_old", ageInDays)
    );
  }

  return (
    ageInYears.toString() +
    " " +
    $t("patient.overview.years_old") +
    " " +
    ageInMonths.toString() +
    " " +
    $t("patient.overview.months_old", ageInMonths) +
    " " +
    ageInDays.toString() +
    " " +
    $t("patient.overview.days_old", ageInDays)
  );
});

const patientAgeShort = computed((): string => {
  if (!patient.value || !patient.value.date_of_birth) {
    return "";
  }

  const referenceDate = patient.value.date_of_death ?? new Date();

  const ageInYears = differenceInYears(
    referenceDate,
    patient.value.date_of_birth,
  );

  if (ageInYears < 1) {
    const ageInMonths = differenceInMonths(
      referenceDate,
      addYears(patient.value.date_of_birth, ageInYears),
    );
    return (
      ageInMonths.toString() +
      " " +
      $t("patient.overview.months_old", ageInMonths)
    );
  }

  return ageInYears.toString() + " " + $t("patient.overview.years_old");
});

const patientMail = computed((): string | undefined => {
  if (!patient.value || patient.value.email_addresses.length === 0) {
    return undefined;
  }
  return patient.value.email_addresses[0].email_address;
});

const showCompany = computed(() => hasFeatureFlag("companies"));

const shouldLinkToPayerInsurers = computed(
  (): boolean => hasApp("zorgvergoeding") && hasFeatureFlag("zorgvergoeding"),
);

const linkToZorgvergoeding = computed((): string | undefined => {
  if (!shouldLinkToPayerInsurers.value) {
    return undefined;
  }

  const zisNumber = patient.value?.zis_number;
  if (zisNumber === undefined) {
    return undefined;
  }

  return `/patient/${zisNumber}/edit#payer_insurer`;
});
</script>
