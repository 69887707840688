<template>
  <DropdownWrapper direction="right" :disabled="disabled">
    <template #button>
      <div
        :class="[
          'border-x border-border-tertiary p-1 ',
          disabled ? '' : 'cursor-pointer hover:bg-background-tertiary ',
        ]"
      >
        {{
          filter.logicTranslation?.get(selectedLogic) ??
          $t("filter.logic." + selectedLogic)
        }}
      </div>
    </template>
    <template #default>
      <div class="flex flex-col">
        <button
          class="bg-background-primary p-2"
          v-for="option in logicOptions"
          :class="
            disabled ? '' : 'cursor-pointer hover:bg-background-tertiary '
          "
          role="button"
          @click="updateFilterLogic(option)"
        >
          {{
            filter.logicTranslation?.get(option) ?? $t("filter.logic." + option)
          }}
        </button>
      </div>
    </template>
  </DropdownWrapper>
</template>

<script lang="ts" setup>
import { computed, PropType, toRef } from "vue";
import DropdownWrapper from "@/components/ui/Dropdown/DropdownWrapper.vue";
import { Filter, FilterManager } from "@/models/Filter/filter";
import { useFilterLogic } from "./FilterLogicComposable";

const props = defineProps({
  filter: {
    required: true,
    type: Object as PropType<Filter>,
  },
  filterManager: {
    required: true,
    type: Object as PropType<FilterManager>,
  },
  readonly: {
    default: false,
    type: Boolean,
  },
});

const { updateFilterLogic, selectedLogic, logicOptions } = useFilterLogic(
  toRef(props, "filter"),
  props.filterManager,
);

const disabled = computed(
  () => logicOptions.value.length < 2 || props.readonly,
);
</script>
