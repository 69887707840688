import { clone } from "@/libraries/utils/clone";
import { VNode } from "vue";

export interface TableColumn {
  prop: string;
  label: string;
  smLabel?: string;
  content: (data: any) => VNode[];
  thClass?: string;
  tdClass?: string;
  columnClass?: string;
  primary: boolean;
  clickable?: true;
  sortable?: true;
  align?: "left" | "right";
  sortFunction?: (direction: "asc" | "desc", a: any, b: any) => number;
}

export interface SortState<T> {
  column: string;
  direction: "asc" | "desc";
  sortFunction?: (direction: "asc" | "desc", a: T, b: T) => number;
}

export function getSortedData<T>(
  data: T[],
  sortState?: SortState<T[keyof T]>,
): T[] {
  if (!sortState) {
    return data;
  }
  const cloned = clone(data);
  if (sortState.sortFunction) {
    cloned.sort((a: T, b: T) =>
      sortState.sortFunction!(
        sortState.direction,
        a[sortState.column as keyof T],
        b[sortState.column as keyof T],
      ),
    );
    return cloned;
  }
  cloned.sort((a: T, b: T) => {
    const valA = a[sortState.column as keyof T];
    const valB = b[sortState.column as keyof T];
    if (!valA) {
      return -1;
    }
    if (!valB) {
      return 1;
    }
    if (valA > valB) {
      return sortState.direction === "asc" ? -1 : 1;
    }
    if (valB > valA) {
      return sortState.direction === "asc" ? 1 : -1;
    }
    return 0;
  });
  return cloned;
}
