import { fullNameInitials } from "@/models/Person";
import { $t } from "@/libraries/i18n";
import { User } from "@/models/User";
import { createOptionFilter } from "../Functionals/createOptionFilter";
import { buildUserAvatarElement } from "@/libraries/utils/buildUserAvatarElement";

type InputData = Partial<User>;

export const createUserFilter = (
  input: Partial<Parameters<typeof createOptionFilter>[number]> & {
    data: InputData[];
  },
) => {
  input.data = addAvatars(input.data);
  return createOptionFilter({
    subject: $t("general.users"),
    prefix: $t("general.user"),
    suffix: $t("general.users").toLowerCase(),
    label: (user: InputData) => fullNameInitials(user),
    filterKey: "id",
    queryStringKey: "user_id",
    optionsType: "single",
    unknownLabel: $t("general.unknown") + "e " + $t("general.user"),
    ...input,
  });
};

function addAvatars(data: InputData[]) {
  return data.map((user) => {
    if (!("avatar" in user) || !user.avatar) {
      return user;
    }
    return { ...user, icon: buildUserAvatarElement(user.avatar) };
  });
}
