<template>
  <flux-short-form v-if="!inline">
    <flux-short-form-item
      :label="$t('patient.create.form.postal_code')"
      span="col-span-6"
      :required="poRequired"
    >
      <flux-input
        v-model:modelValue="modelValue.postal_code"
        @blur="getAddress()"
        :prop="propRoot + 'postal_code'"
        :required="poRequired"
        ref="postal_code"
      >
        <template
          v-if="modelValue.postal_code && modelValue.postal_code.length > 0"
          #suffix
        >
          <i
            class="fas fa-check"
            v-if="
              canVerifyPostalCode() && postalCodeIsValid(modelValue.postal_code)
            "
            style="color: #14ce68; margin-right: 6px"
          />
          <i
            class="fas fa-times"
            v-else-if="
              canVerifyPostalCode() &&
              !postalCodeIsValid(modelValue.postal_code)
            "
            style="color: #ef3e36; margin-right: 6px"
          />
        </template>
      </flux-input>
    </flux-short-form-item>
    <flux-short-form-item
      :label="$t('patient.create.form.house_number')"
      span="col-span-6"
    >
      <flux-input
        v-model:modelValue="modelValue.house_number"
        :prop="propRoot + 'house_number'"
      ></flux-input>
    </flux-short-form-item>
    <flux-short-form-item :label="$t('patient.create.form.street_name')">
      <flux-input
        v-model:modelValue="modelValue.street_name"
        :prop="propRoot + 'street_name'"
      ></flux-input>
    </flux-short-form-item>
    <flux-short-form-item
      :label="$t('patient.create.form.city')"
      :required="true"
    >
      <flux-input
        v-model:modelValue="modelValue.city"
        :prop="propRoot + 'city'"
        :required="true"
        @error="emit('error', $event)"
      ></flux-input>
    </flux-short-form-item>
    <flux-short-form-item
      :label="$t('patient.create.form.address_type')"
      span="col-span-6"
    >
      <flux-select v-model:modelValue="modelValue.address_type">
        <option
          v-for="address_type in addressTypeOptions"
          :key="address_type.value"
          :value="address_type.value"
          :label="address_type.label"
        >
          {{ address_type.label }}
        </option>
      </flux-select>
    </flux-short-form-item>
    <flux-short-form-item
      :label="$t('patient.create.form.country')"
      span="col-span-6"
    >
      <flux-select
        class="max-w-xs"
        v-flux-loading="loading_countries"
        v-model:modelValue="modelValue.country_id"
      >
        <option
          v-for="country in countries"
          :key="country.id"
          :value="country.id"
          :label="country.name"
        >
          {{ country.name }}
        </option>
      </flux-select>
    </flux-short-form-item>
    <slot name="footer"></slot>
  </flux-short-form>
  <div v-else>
    <div class="grid grid-cols-2 gap-2">
      <flux-input
        v-model:modelValue="modelValue.postal_code"
        @blur="getAddress()"
        :placeholder="$t('patient.create.form.postal_code')"
        :prop="propRoot + 'postal_code'"
        :required="poRequired"
        ref="postal_code"
      >
        <template
          v-if="modelValue.postal_code && modelValue.postal_code.length > 0"
          #suffix
        >
          <i
            class="fas fa-check"
            v-if="
              canVerifyPostalCode(modelValue.country_id) &&
              postalCodeIsValid(modelValue.postal_code)
            "
            style="color: #14ce68; margin-right: 6px"
          />
          <i
            class="fas fa-times"
            v-else-if="
              canVerifyPostalCode(modelValue.country_id) &&
              !postalCodeIsValid(modelValue.postal_code)
            "
            style="color: #ef3e36; margin-right: 6px"
          />
        </template>
      </flux-input>
      <flux-input
        v-model:modelValue="modelValue.house_number"
        :placeholder="$t('patient.create.form.house_number')"
        :prop="propRoot + 'house_number'"
      ></flux-input>
      <flux-input
        v-model:modelValue="modelValue.street_name"
        :placeholder="$t('patient.create.form.street_name')"
        :prop="propRoot + 'street_name'"
      ></flux-input>
      <flux-input
        v-model:modelValue="modelValue.city"
        :placeholder="$t('patient.create.form.city')"
        :prop="propRoot + 'city'"
        :required="true"
        @error="emit('error', $event)"
      ></flux-input>
      <flux-select
        class="max-w-xs"
        v-flux-loading="loading_countries"
        v-model:modelValue="modelValue.country_id"
        :placeholder="$t('patient.create.form.country')"
      >
        <option
          v-for="country in countries"
          :key="country.id"
          :value="country.id"
          :label="country.name"
        >
          {{ country.name }}
        </option>
      </flux-select>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue";
import { Country } from "../../models/Country";
import { AddressForm, Address } from "../../models/Address";
import { useCountryStore } from "@/libraries/store/Countries";
import { pinia } from "@/libraries/store";
import { $t } from "@/libraries/i18n";
import { useAddress } from "@/composables/address";

const modelValue = defineModel<AddressForm | Address>("modelValue", {
  required: true,
});

const props = defineProps<{
  inline?: boolean;
  poRequired?: boolean;
  formIndex?: number;
  formPropRoot?: string;
}>();

const emit = defineEmits<{
  (e: "error", v: any): void;
}>();

watch(
  () => modelValue.value.postal_code,
  () => {
    if (
      modelValue.value.postal_code &&
      modelValue.value.postal_code.length > 0 &&
      canVerifyPostalCode()
    ) {
      emit("error", !postalCodeIsValid(modelValue.value.postal_code));
    }
  },
);

const countries = ref<Country[]>([]);
const loading_countries = ref(false);

const addressTypeOptions = ref<{ value: string; label: string }[]>([]);

const propRoot = computed(() => {
  return props.formPropRoot
    ? props.formPropRoot + "." + (props.formIndex?.toString() ?? "0") + "."
    : "";
});

const countryStore = useCountryStore(pinia);

onMounted(() => {
  fetchCountries();
  const toOptions = (translation: string, list: string[]) =>
    list.map((value) => {
      return { value, label: $t(translation + "." + value) };
    });

  addressTypeOptions.value = toOptions("labels.address_type", [
    "HP",
    "PST",
    "PHYS",
    "TMP",
    "WP",
    "HV",
  ]);
});

/**
 * Fetches countries
 */
async function fetchCountries() {
  loading_countries.value = true;
  countries.value = await countryStore.findAll();
  loading_countries.value = false;
}

const { fillAddress, postalCodeIsValid } = useAddress();

async function getAddress() {
  const filledAddress = await fillAddress(modelValue.value);
  if (filledAddress && filledAddress.street_name && filledAddress.city) {
    modelValue.value = {
      ...modelValue.value,
      city: filledAddress.city,
      street_name: filledAddress.street_name,
    };
  }
}

function canVerifyPostalCode(country_id?: number) {
  // Can only verify in the Netherlands
  return country_id == 159;
}

defineExpose({
  getAddress,
});
</script>
