<template>
  <span
    :class="[
      'inline-flex items-center rounded-md px-2.5 py-0.5 text-xs font-medium',
      colorsResult,
    ]"
    ><slot></slot
  ></span>
</template>

<script lang="ts" setup>
import { computed } from "vue";

const colors = {
  success: "text-green-800 bg-green-100 dark:bg-green-800 dark:text-green-100",
  neutral: "text-text-secondary bg-background-tertiary  ",
  down: "text-orange-800 bg-orange-100 dark:bg-orange-800 dark:text-orange-100",
  rejected: "text-red-800 bg-red-100 dark:bg-red-800 dark:text-red-100",
} as const;

const props = defineProps<{
  type?: keyof typeof colors;
}>();

const colorsResult = computed(() => {
  return colors[props.type ?? "success"];
});
</script>
