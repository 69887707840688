<template>
  <DropdownLayout
    :visible="visible"
    :direction="direction"
    :dropDirection="dropDirection"
    @afterLeave="emit('afterLeave')"
  >
    <template #button>
      <div class="contents" ref="clickCatcher">
        <slot
          name="button"
          :handleClick="handleClick"
          :handleKeyDown="handleKeyDown"
          :handleButtonHotkeyDown="handleButtonHotkeyDown"
          :uuid="uuid"
        ></slot>
      </div>
    </template>
    <template #default>
      <div ref="menuItemsRef">
        <slot
          :handleClick="handleClick"
          :close="handleClose"
          :handleKeyDown="handleKeyDown"
          :handleMenuHotkeyDown="handleMenuHotkeyDown"
        ></slot>
      </div>
    </template>
  </DropdownLayout>
</template>

<script lang="ts" setup>
import { dropdownManagerKey } from "@/libraries/managers/DropdownManager";
import {
  computed,
  inject,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  watch,
} from "vue";
import DropdownLayout from "./DropdownLayout.vue";

const props = defineProps({
  direction: {
    default: "left",
    type: String as PropType<"left" | "right">,
  },
  dropDirection: {
    default: "down",
    type: String as PropType<"up" | "down">,
  },
  autoClose: {
    default: true,
    type: Boolean,
  },
  stopPropagation: {
    default: false,
    type: Boolean,
  },
  dropdownKey: {
    type: Symbol,
  },
  disabled: {
    type: Boolean,
  },
});

const emit = defineEmits<{
  (e: "closed"): void;
  (e: "afterLeave"): void;
}>();

const dropdownManager = inject(dropdownManagerKey);
const dropdownKey = props.dropdownKey ?? Symbol();

const menuItemsRef = ref();
const clickCatcher = ref();

onMounted(() => {
  if (!props.disabled) {
    dropdownManager?.register(dropdownKey, {
      autoClose: props.autoClose,
      button: clickCatcher,
      menu: menuItemsRef,
    });
  }
});

onBeforeUnmount(() => {
  dropdownManager?.unregister(dropdownKey);
});

watch(
  () => props.disabled,
  () => {
    if (props.disabled) {
      dropdownManager?.unregister(dropdownKey);
    } else {
      dropdownManager?.register(dropdownKey, {
        autoClose: props.autoClose,
        button: clickCatcher,
        menu: menuItemsRef,
      });
    }
  },
);

const visible = computed(
  () => dropdownManager?.openDropdown.value === dropdownKey,
);

watch(visible, () => {
  if (!visible.value) {
    emit("closed");
  }
});

const uuid = "dropdown_button_" + Math.random().toString();

function handleClick(ev: MouseEvent | undefined) {
  const target = ev?.target;
  if (!ev || !(target instanceof Node)) {
    return;
  }
  if (props.stopPropagation) {
    ev.stopPropagation();
    dropdownManager?.handleStoppedClick(dropdownKey, target);
  }
}

function handleKeyDown(ev: KeyboardEvent) {
  const target = ev.target;
  if (target === null || !(target instanceof Node)) {
    return;
  }
  dropdownManager?.handleStoppedClick(dropdownKey, target);
}

function handleMenuHotkeyDown() {
  dropdownManager?.handleMenuHotkeyDown(dropdownKey);
}

function handleButtonHotkeyDown() {
  dropdownManager?.handleButtonHotkeyDown(dropdownKey);
}
function handleClose() {
  dropdownManager?.close(dropdownKey);
}
</script>
