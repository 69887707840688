import { createBooleanFilter } from "./Functionals/createBooleanFilter";
import { createOptionFilter } from "./Functionals/createOptionFilter";
import { createInputFilter } from "./Functionals/createInputFilter";
import { createRangeFilter } from "./Functionals/createRangeFilter";
import { useFilterManager } from "../../libraries/managers/FilterManager";

export interface FilterOption {
  [key: string]: any;
}

export const filterLogicOptions = [
  "EQ",
  "NEQ",
  "AEQ",
  "LTE",
  "GTE",
  "LT",
  "GT",
  "OR",
  "NOR",
  "AND",
  "NAND",
] as const;

export type FilterLogicOption = (typeof filterLogicOptions)[number];
export type FilterLogicOptionLogic = {
  onlyPositive?: true;
  onlyNegative?: true;
  onlyOr?: true;
  onlyAnd?: true;
};

export type OptionFilter = ReturnType<typeof createOptionFilter>;
export type BooleanFilter = ReturnType<typeof createBooleanFilter>;
export type TextFilter = ReturnType<typeof createInputFilter>;
export type RangeFilter = ReturnType<typeof createRangeFilter>;
export type Filter = OptionFilter | BooleanFilter | TextFilter | RangeFilter;

export type OptionFilterStatus = {
  selectedOptions: (string | number | string[] | number[])[];
  selectedLogic: FilterLogicOption;
};
export type BooleanFilterStatus = {
  selectedLogic: FilterLogicOption;
};
export type InputFilterStatus = {
  text: string;
  selectedLogic: FilterLogicOption;
};
export type RangeFilterStatus = {
  rangeStart: string;
  rangeEnd: string;
  selectedLogic: FilterLogicOption;
};
export type FilterStatus =
  | OptionFilterStatus
  | BooleanFilterStatus
  | InputFilterStatus
  | RangeFilterStatus;

export type FilterManager = ReturnType<typeof useFilterManager>;
