<template>
  <p class="py-0.5 text-xs leading-4" v-if="!actor">
    <span class="font-medium text-text-primary">{{
      $t("general.unknown")
    }}</span>
  </p>
  <p class="text-xs leading-4" v-else-if="'user_id' in actor">
    <Tooltip placement="top-start">
      <template #content>
        <UserTooltipContent :userId="actor.user_id" />
      </template>
      <AvatarVue :avatar="getFullUser(actor.user_id)?.avatar" />
    </Tooltip>
  </p>
  <p class="py-0.5 text-xs leading-4" v-else-if="'zis_number' in actor">
    <async-patient-link
      :format="FormatName.fullNameInitials"
      :zisNumber="actor.zis_number"
    />
  </p>
  <p class="py-0.5 text-xs leading-4" v-else>
    <span class="font-medium text-text-primary">{{ getInitiatorTitle() }}</span>
  </p>
</template>

<script lang="ts" setup>
import { fullName } from "@/models/Person";
import AvatarVue from "@/components/Avatar.vue";
import { $t } from "@/libraries/i18n";
import { getUser } from "@/libraries/plugins/getUser";
import { User } from "@/models/User";
import Tooltip from "@/components/ui/Tooltip.vue";
import UserTooltipContent from "@/components/ui/TooltipContent/UserTooltipContent.vue";
import { FormatName } from "@/models/Person";
import { Actor } from "@/models/Actor";

const props = defineProps<{ actor: Actor | undefined }>();

function getFullUser(id: number): User | undefined {
  return getUser().healthcare_provider.users.find((user) => id == user.id);
}

function getInitiatorTitle(): string {
  if (!props.actor) {
    return $t("general.unknown");
  }
  if ("user_id" in props.actor) {
    return fullName(getFullUser(props.actor.user_id));
  }
  if ("email" in props.actor) {
    return props.actor.email;
  }
  if ("zis_number" in props.actor) {
    return "Patient";
  }
  if (props.actor.initiator) {
    return "Flux";
  }

  return $t("general.unknown");
}
</script>
