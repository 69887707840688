import { HealthcareProvider } from "./HealthcareProvider";
import { AddressType, Address } from "./Address";
import { Person } from "./Person";
import { Avatar } from "./Avatar";
import { HealthcareProfessionalType } from "./HealthcareProfessionalType";
import { fullName } from "@/models/Person";
import { Role } from "./Role";
import { UserLabel } from "./UserLabel";
import { CalendarDisplayMode } from "@/libraries/store/interfaces";
import { Permission } from "./Permission";
import { ShortenedConsultType } from "./MedicalUpdate";

export type User = Person & {
  id: number;
  uuid: string;
  name: string;
  email: string;
  initials?: string;
  full_name?: string;
  AGB_code?: string;
  big_number?: string;
  healthcare_provider?: HealthcareProvider;
  healthcare_professional_type?: HealthcareProfessionalType;
  healthcare_professional_types: HealthcareProfessionalType[];
  roles?: Array<Role & { permissions: Permission[] }>;
  addresses?: Address[];
  phone_numbers?: string[];
  avatar?: Avatar;
  active?: boolean;
  function_title?: string;
  responsible_user_id?: number;
  user_labels?: UserLabel[];
  calendar_settings?: UserCalendarSettings;
  patient_settings?: UserPatientSettings;
  suppressed_notification_types: UserNotificationType[];
  cost_center?: string;
  ledger_account?: string;
};

export type UserCalendarSettings = {
  calendar_view?: CalendarDisplayMode;
  start_of_week: "sunday" | "monday" | "currentDay";
  start_hour: number;
  end_hour: number;
  user_sort_method?: "occupancy_rate";
  show_week_numbers: boolean;
  unavailable_users?: {
    ignore_when_scrolling: boolean;
    deselect_on_date_change: boolean;
  };
};

export type UserPatientSettings = {
  expand_medical_feed: boolean;
  default_medical_update?: ShortenedConsultType;
};

export interface CreateUserForm {
  first_names: string;
  surname: string;
  surname_prefix?: string;
  maiden_name?: string;
  maiden_name_prefix?: string;
  AGB_code?: string;
  big_number?: string;
  email: string;
  initials?: string;
  addresses?: Array<{
    street_name: string;
    house_number: string;
    postal_code: string;
    city: string;
    address_type: AddressType;
    country_id: number;
  }>;
  phone_numbers?: string[];
  roles: Array<string | number>;
  active: boolean;
  function_title?: string;
  responsible_user_id?: number;
  cost_center?: string;
  ledger_account?: string;
  healthcare_professional_types: HealthcareProfessionalType[];
}

export const userNotificationTypes = [
  "appointment_proposal_created_mail",
] as const;

export type UserNotificationType = (typeof userNotificationTypes)[number];

export function getActiveHealthcareProfessionalType(
  user: User,
): HealthcareProfessionalType {
  if (user.healthcare_professional_types.length > 0) {
    return user.healthcare_professional_types[0];
  } else {
    return "physiotherapist";
  }
}

export function orderByActive(users: Array<User & { active?: boolean }>) {
  return users.toSorted((userA, userB) => {
    if (userA.active && !userB.active) {
      return -1;
    }

    if (!userA.active && userB.active) {
      return 1;
    }

    return 0;
  });
}

export function orderByName(users: Array<User>) {
  return users.toSorted((userA, userB) =>
    fullName(userA).localeCompare(fullName(userB)),
  );
}

export function sortRelevantUsers(
  users: User[],
  settings?: { labelUuids?: string[] },
): User[] {
  return users
    .toSorted((a, b) => fullName(a).localeCompare(fullName(b)))
    .sort((a, b) => {
      const aIsDoctor = a.roles?.some((r: Role) => r.id === 1);
      const bIsDoctor = b.roles?.some((r: Role) => r.id === 1);
      if (aIsDoctor && !bIsDoctor) {
        return -1; // a comes first
      }

      if (!aIsDoctor && bIsDoctor) {
        return 1; // a comes last
      }

      return 0; // a and b are equal
    })
    .sort((a, b) => {
      if (settings?.labelUuids === undefined) {
        return 0;
      }

      const aHasLabel = a.user_labels?.some((label) =>
        settings.labelUuids?.includes(label.uuid),
      );
      const bHasLabel = b.user_labels?.some((label) =>
        settings.labelUuids?.includes(label.uuid),
      );

      if (aHasLabel && !bHasLabel) {
        return -1; // a comes first
      }

      if (!aHasLabel && bHasLabel) {
        return 1; // a comes last
      }

      return 0; // a and b are equal
    })
    .sort((a, b) => {
      if (!b.active && a.active) {
        return -1; // a comes first
      }

      if (b.active && !a.active) {
        return 1; // a comes last
      }

      return 0; // a and b are equal
    });
}

export function filterMainTherapistUsers(users: User[]): User[] {
  return users.filter(
    (user) =>
      user.active &&
      user.AGB_code !== undefined &&
      user.AGB_code !== "" &&
      user.responsible_user_id === undefined,
    // &&
    // user.healthcare_professional_types.includes(
    //   form.value.healthcare_professional_type,
    // )
  );
}

export function predictMainTherapist(
  users: User[],
  candidate: User,
): User | undefined {
  const user = users.find((user) => user.id === candidate.id);
  if (user) {
    return user;
  }
  const responsible_user_id = candidate.responsible_user_id;
  if (responsible_user_id) {
    const responsibleUser = users.find(
      (user) => user.id === responsible_user_id,
    );
    if (responsibleUser) {
      return responsibleUser;
    }
  }
}
