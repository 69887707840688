<template>
  <TypeAheadComponent
    class="w-full"
    v-model="value"
    icon="far fa-search"
    :typeahead="userTypeahead"
    :debounce-timeout="0"
    :placeholder="placeholder ? placeholder : $t('general.search')"
    @select="(value) => emit('select', value)"
  >
    <template #results="{ data, activeIndex }">
      <ResultList :listData="data" :activeIndex="activeIndex">
        <template #entry="{ entry, active }">
          <a
            :class="[
              'group box-border flex w-full cursor-pointer flex-col items-start  px-3 py-2 text-sm transition duration-75',
              active
                ? 'bg-background-tertiary text-text-secondary '
                : 'text-text-secondary hover:bg-background-tertiary active:text-text-primaryActive ',
            ]"
          >
            <div class="flex w-full items-center gap-1">
              <div class="flex-grow text-left">
                {{ fullName(entry) }}
              </div>
            </div>
          </a>
        </template>
      </ResultList>
    </template>
  </TypeAheadComponent>
</template>

<script lang="ts" setup>
import { $t } from "@/libraries/i18n";
import TypeAheadComponent from "@/components/ui/Input/TypeAhead.vue";
import { sortRelevantUsers, User } from "@/models/User";
import { computed } from "vue";
import Fuse from "fuse.js";
import store from "@/libraries/store";
import { removeDiacritics } from "@/libraries/utils/removeDiacritics";
import ResultList from "../ResultList.vue";
import { fullName } from "@/models/Person";

defineProps<{
  placeholder?: string;
}>();
const modelValue = defineModel<string>("modelValue", { required: true });

const emit = defineEmits<{
  (event: "select", value: any): void;
  (event: "update:modelValue", value: string): void;
}>();

const value = computed({
  get() {
    return modelValue.value;
  },
  set(value) {
    modelValue.value = value;
  },
});

const hcp_users = computed<User[]>(() => {
  const users = store.getters.hcp_users;
  users.forEach((user: User) => {
    user.first_names = user.first_names
      ? removeDiacritics(user.first_names)
      : undefined;
    user.surname = user.surname ? removeDiacritics(user.surname) : undefined;
    user.maiden_name = user.maiden_name
      ? removeDiacritics(user.maiden_name)
      : undefined;
  });
  return users.filter((user: User) => {
    return user.active;
  });
});

const fuse = computed(() => {
  return new Fuse(hcp_users.value, {
    minMatchCharLength: 0,
    shouldSort: true,
    tokenize: true,
    matchAllTokens: true,
    distance: 4,
    threshold: 0.4,
    limit: 3,
    keys: [
      { name: "first_names", weight: 0.33 },
      { name: "surname", weight: 0.33 },
      { name: "maiden_name", weight: 0.33 },
    ],
  });
});
function userTypeahead() {
  if (!fuse.value) {
    return [];
  }

  return sortRelevantUsers(
    fuse.value.search(modelValue.value ?? "", { limit: 8 }),
  );
}
</script>
