import { apiClient } from "@/libraries/utils/axios";
import {
  AppointmentType,
  AppointmentTypeForm,
  appointmentTypeSchema,
} from "@/models/Appointment";

export async function getAppointmentTypesWithTrashed(): Promise<
  AppointmentType[]
> {
  const response = await apiClient.get("/appointment_types");

  return appointmentTypeSchema
    .array()
    .parse(response.data.appointment_types)
    .toSorted((a, b) => a.name.localeCompare(b.name))
    .toSorted((a, b) => (a.order ?? Infinity) - (b.order ?? Infinity));
}

export async function reorder(ids: number[]) {
  await apiClient.put("appointment_types/order", {
    order: ids,
  });
}

export async function update(id: number, data: Partial<AppointmentType>) {
  await apiClient.put(`/appointment_types/${id}`, data);
}

export async function updateAppointmentType(
  id: number,
  payload: Omit<AppointmentTypeForm, "duration" | "amount_credits"> & {
    duration: number;
    amount_credits: number;
  },
): Promise<void> {
  await apiClient.put(`/appointment_types/${id}`, payload);
}

export async function createAppointmentType(
  payload: Omit<AppointmentTypeForm, "duration" | "amount_credits"> & {
    duration: number;
    amount_credits: number;
  },
): Promise<void> {
  await apiClient.post("/appointment_types", payload);
}

export async function removeAppointmentType(id: number): Promise<void> {
  await apiClient.delete(`/appointment_types/${id}`);
}
