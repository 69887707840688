import { apiClient } from "@/libraries/utils/axios";
import { handledZodParse } from "@/libraries/utils/errorHandling";
import { z } from "zod";
import {
  stringToPlainDate,
  stringToPlainDateTimeZoned,
} from "@/libraries/utils/zodTransforms";
import { mimeTypeToExtension } from "@/libraries/utils/files";

export async function getMedicalConversations(): Promise<
  Readonly<MedicalConversation>[]
> {
  const { data } = await apiClient.get("/medical_conversations");

  return handledZodParse({
    schema: z.array(medicalConversationSchema),
    input: data,
    notifyUser: true,
  });
}

export async function processMedicalConversations(id: string): Promise<void> {
  await apiClient.post(`/medical_conversations/process/${id}`);
}
export async function updatePatientMedicalConversations(
  id: string,
): Promise<void> {
  await apiClient.post(`/medical_conversations/patient/${id}`);
}

export const medicalConversationTypes = ["referral"] as const;
export const medicalConversationMediums = ["zorgdomein"] as const;
export const medicalConversationMessageTypes = [
  "zorgdomeinappointmentmessage",
  "unknown",
  "zorgdomeinreferralletter",
  "zorgdomeincancellationmessage",
  "zorgdomeinsupplement",
] as const;

const attachmentSchema = z.object({
  contentType: z
    .string()
    .transform((val) => mimeTypeToExtension(val))
    .optional(),
  data: z.string(),
  title: z.string().optional(),
  creation: stringToPlainDate.optional(),
  size: z.string().optional(),
});

const medicalConversationMessageSchema = z.object({
  id: z.string(),
  type: z.enum(medicalConversationMessageTypes),
  message: z.object({
    resourceType: z.string(),
    type: z.string().optional(),
    entry: z.array(
      z.object({
        fullUrl: z.string(),
        resource: z.object({
          resourceType: z.string(),
          date: z
            .string()
            .transform((val) => new Date(val))
            .optional(),
          text: z
            .object({
              status: z.string(),
              div: z.string(),
            })
            .optional(),
          content: z
            .array(
              z.object({
                attachment: attachmentSchema,
              }),
            )
            .optional(),
        }),
      }),
    ),
  }),
});

const addressSchema = z
  .object({
    type: z.string(),
    text: z.string(),
    line: z.array(z.string()),
    city: z.string(),
    postalCode: z.string(),
    country: z.string(),
  })
  .optional();

const nameSchema = z
  .object({
    use: z.string(),
    text: z.string(),
    family: z.string(),
    given: z.array(z.string()),
  })
  .optional();

const telecomSchema = z
  .object({
    system: z.string(),
    value: z.string(),
    use: z.string(),
  })
  .optional();

const medicalConversationPatientSchema = z.object({
  type: z.enum(["external", "internal"]),
  medium: z.enum(medicalConversationMediums).optional(),
  zdPatient: z
    .object({
      name: z.array(nameSchema),
      telecom: z.array(telecomSchema),
      address: z.array(addressSchema),
      identifier: z
        .array(
          z.object({
            system: z.string(),
            value: z.string(),
          }),
        )
        .optional(),
      birthDate: z.string().optional(),
    })
    .optional(),
  patientZisNumber: z.number().optional(),
});

const participantSchema = z.object({
  type: z.enum(["external", "internal"]),
  medium: z.enum(medicalConversationMediums),
  actor: z.any().optional(),
  organization: z
    .object({
      id: z.string(),
      identifier: z.array(
        z.object({
          system: z.string(),
          value: z.string(),
        }),
      ),
      name: z.string(),
    })
    .optional(),
  locations: z.array(
    z.object({
      name: z.string(),
      address: addressSchema.optional(),
    }),
  ),
  practitioner: z
    .object({
      resourceType: z.string(),
      name: z.array(nameSchema),
      gender: z.string(),
      address: z.array(addressSchema.optional()).optional(),
    })
    .optional(),
});

export const medicalConversationSchema = z.object({
  id: z.string(),
  external_identifier: z.string(),
  medium: z.enum(medicalConversationMediums),
  type: z.enum(medicalConversationTypes),
  messages: z.array(medicalConversationMessageSchema),
  patient: medicalConversationPatientSchema,
  sender: participantSchema,
  recipient: participantSchema,
  processed_at: stringToPlainDateTimeZoned.optional(),
  created_at: stringToPlainDateTimeZoned,
  updated_at: stringToPlainDateTimeZoned.optional(),
});

export type MedicalConversationType = (typeof medicalConversationTypes)[number];
export type MedicalConversationMessage = z.infer<
  typeof medicalConversationMessageSchema
>;
export type MedicalConversationMedium =
  (typeof medicalConversationMediums)[number];
export type MedicalConversation = z.infer<typeof medicalConversationSchema>;
export type MedicalConversationMessageAttachment = z.infer<
  typeof attachmentSchema
>;
