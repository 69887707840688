import debounce from "debounce";
import { Ref, ref, watch } from "vue";
import { FilterOption, OptionFilter } from "@/models/Filter/filter";

export function useFilterDropdownFilter(filter: Ref<OptionFilter>) {
  watch(filter, () => {
    matchingOptions.value = filter.value.data;
  });

  const matchingOptions = ref<FilterOption[]>(filter.value.data);

  function filterDropdownOptions(searchString?: string) {
    if (!searchString) {
      matchingOptions.value = filter.value.data;
      return;
    }
    debouncedFilter(searchString.toLowerCase());
  }

  const debouncedFilter = debounce(
    (searchString: string) => actualFilter(searchString),
    200,
  );

  function actualFilter(search: string) {
    matchingOptions.value = filter.value.data.filter((option) => {
      if (filter.value.searchKeys) {
        return filter.value.searchKeys.some((key) =>
          matches(option[key], search),
        );
      }
      return matches(filter.value.label(option), search);
    });
  }

  function matches(value: string | number, search: string) {
    if (typeof value === "string") {
      return value.toLowerCase().includes(search);
    }
    if (typeof value === "number") {
      return value.toString().includes(search);
    }
  }

  return { filterDropdownOptions, matchingOptions };
}
