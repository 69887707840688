<template>
  <div v-flux-loading="false">
    <div v-if="patient">
      <div v-if="patient.payer_insurers.length > 0">
        <PatientCombinedPayerInsurers
          ref="combinedPayerInsurerComponent"
          :combinedPayerInsurers="combinedPayerInsurers"
          :patient="patient"
          :payerInsurers="form.payer_insurers"
          @updated="reloadPatient()"
          @doCovCheck="checkBsnForCov()"
        >
        </PatientCombinedPayerInsurers>
      </div>
      <div v-else-if="createnew">
        <CreateNewPayerInsurer
          :patient="patient"
          @cancel="createnew = false"
          @created="reloadPatient()"
        />
      </div>
      <div v-else-if="form.payer_insurers.length == 0">
        <flux-card shadow="never">
          <div class="text-center">
            <img
              src="../../assets/images/at_work.svg"
              style="width: 100%; max-width: 300px"
            />
          </div>
          <div class="mt-4 text-center">
            {{ $t("patient.edit.payer_insurers.empty") }}
          </div>
          <div class="mt-4 text-center">
            <flux-button-group class="inline-flex pl-2">
              <flux-button
                v-if="covEnabled"
                v-flux-loading="callingCOV"
                icon="fal fa-plus"
                type="primary"
                @click="checkBsnForCov()"
              >
                {{ $t("patient.cov_check.execute") }}
              </flux-button>
              <flux-button
                icon="fal fa-plus"
                ref="btn_add_more"
                type="primary"
                @click="createnew = true"
                >{{
                  $t("patient.create.form.step.payer_insurers.add_first")
                }}</flux-button
              >
            </flux-button-group>
          </div>
        </flux-card>
      </div>
      <flux-form class="flex justify-end gap-x-5">
        <flux-input
          v-model:modelValue="covDate"
          prop="cov_date"
          type="date"
        ></flux-input>
        <flux-submit-button
          size="small"
          @click="checkBsnForCov"
          type="primary"
          icon="fal fa-search"
        >
          COV check voor datum
        </flux-submit-button>
      </flux-form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref, toRef, watch } from "vue";
import {
  PayerInsurerPersisted,
  PayerInsurerForm,
} from "../../models/PayerInsurer";
import { getInsurerName, Insurer } from "../../models/Insurer";
import PatientCombinedPayerInsurers from "./CombinedPayerInsurers.vue";
import { useNotify } from "@/composables/notify";
import {
  existingPatientCovCheck,
  notifyCovCheckError,
  notifyCovCheckSuccess,
} from "@/actions/covCheck";
import CreateNewPayerInsurer from "./CreateNewPayerInsurer.vue";
import { getUser } from "@/libraries/plugins/getUser";
import { hasFeatureFlag } from "@/libraries/plugins/hasFeatureFlag";
import { $t } from "@/libraries/i18n";
import { usePatient } from "@/composables/patient";
import moment from "moment";
import { pinia } from "@/libraries/store";
import { useInsurerStore } from "@/libraries/store/Insurers";
import { clone } from "@/libraries/utils/clone";

const { notify } = useNotify();

export type CombinedPayerInsurer = {
  number?: string;
  insurer_number?: string;
  insurer_name: string;
  end_date: string;
  package_name?: string;
  payer_insurers: Array<PayerInsurerPersisted>;
};

const props = defineProps<{
  zisNumber: number;
}>();
const { data: patient, invalidatePatient } = usePatient(
  toRef(props, "zisNumber"),
);

const combinedPayerInsurers = ref<CombinedPayerInsurer[]>([]);
const insurers = ref<Insurer[]>([]);
const callingCOV = ref<boolean>(false);
const createnew = ref<boolean>(false);
const covDate = ref<string>();

const covEnabled = computed(() => {
  return (
    getUser().healthcare_provider.cov_settings.enabled &&
    hasFeatureFlag("cov-check")
  );
});

const form: {
  payer_insurers: Array<PayerInsurerPersisted>;
} = reactive({
  payer_insurers: [],
});

watch(
  patient,
  function onPatientChanged() {
    if (patient.value) {
      const payerInsurers = clone(patient.value.payer_insurers);
      form.payer_insurers = sortPayerInsurerByEndDate(payerInsurers);
      combinedPayerInsurers.value = combinePayerInsurers(payerInsurers);
    }
  },
  { immediate: true, deep: false },
);

onMounted(async () => {
  insurers.value = await useInsurerStore(pinia).findAll();
});

function combinePayerInsurers(payerInsurers: PayerInsurerPersisted[]) {
  let combinedPayerInsurers: CombinedPayerInsurer[] = [];
  payerInsurers.forEach((payerInsurer) => {
    const foundIndex = combinedPayerInsurers.findIndex((combinedPayerInsurer) =>
      isSamePayerInsurer(payerInsurer, combinedPayerInsurer),
    );
    if (foundIndex == -1) {
      combinedPayerInsurers.push({
        number: payerInsurer.number,
        insurer_number: payerInsurer.insurer_number,
        insurer_name: getPayerInsurerName(payerInsurer),
        end_date: payerInsurer.end_date,
        package_name: payerInsurer.package_name,
        payer_insurers: [payerInsurer],
      });
    } else {
      combinedPayerInsurers[foundIndex].payer_insurers.push(payerInsurer);
    }
  });
  return combinedPayerInsurers;
}

function isSamePayerInsurer(
  payerInsurer: PayerInsurerPersisted | PayerInsurerForm,
  combinedPayerInsurer: CombinedPayerInsurer,
) {
  return (
    payerInsurer.insurer_number === combinedPayerInsurer.insurer_number &&
    payerInsurer.end_date === combinedPayerInsurer.end_date &&
    payerInsurer.number === combinedPayerInsurer.number
  );
}

function getPayerInsurerName(payer_insurer: PayerInsurerPersisted) {
  if (payer_insurer.insurer_name) {
    return payer_insurer.insurer_name;
  }
  return getInsurerName(insurers.value, payer_insurer) ?? "Onbekend";
}

function sortPayerInsurerByEndDate(payer_insurers: PayerInsurerPersisted[]) {
  return payer_insurers.sort((a, b) => (a.end_date > b.end_date ? -1 : 1));
}

async function checkBsnForCov() {
  if (!covEnabled.value) {
    return;
  }
  if (patient.value?.has_bsn === false) {
    notify({
      message: $t("patient.cov_check.error.no_bsn") as string,
      type: "error",
    });
  } else {
    callingCOV.value = true;
    await covCheck();
    reloadPatient();
    callingCOV.value = false;
  }
}

function reloadPatient() {
  invalidatePatient(props.zisNumber);
}

async function covCheck() {
  if (!patient.value) {
    return;
  }
  try {
    const { data } = covDate.value
      ? await existingPatientCovCheck(patient.value, formatDate(covDate.value))
      : await existingPatientCovCheck(patient.value);
    if (!data.success) {
      notifyCovCheckError(data);
    } else {
      reloadPatient();
      notifyCovCheckSuccess();
    }
  } catch {
    notifyCovCheckError();
  }
}

function formatDate(date: string) {
  return moment(date).format("YYYY-MM-DD");
}
</script>
