import { z } from "zod";

const recurringPaymentMandateStatusses = [
  "valid",
  "pending",
  "invalid",
] as const;
const recurringPaymentMandateMethods = ["directdebit"] as const;

export const recurringPaymentMandateSchema = z.object({
  uuid: z.string().uuid(),
  subject_id: z.string(),
  external_id: z.string().optional(),
  external_source: z.string().optional(),
  reference: z.string().optional(),
  status: z.enum(recurringPaymentMandateStatusses),
  method: z.enum(recurringPaymentMandateMethods),
  iban: z.string(),
  signature_date: z.coerce.date().optional(),
  revoked_at: z.coerce.date().optional(),
  created_at: z.coerce.date(),
  updated_at: z.coerce.date(),
});

export type RecurringPaymentMandate = z.infer<
  typeof recurringPaymentMandateSchema
>;
