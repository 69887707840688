<template>
  <div>
    <flux-alert
      class="mb-1"
      v-if="existingPatient"
      type="warning"
      title="Warning"
    >
      <p>
        Patiënt is al geregistreerd:
        <router-link :to="'/patient/' + existingPatient.zis_number">
          {{ fullName(existingPatient) }}
        </router-link>
      </p>
    </flux-alert>
    <flux-card-button-header
      class="m-2"
      :title="$t('patient.create.form.step.identity_documents.title_menu')"
    />
    <flux-card class="mb-2" shadow="never">
      <flux-short-form>
        <flux-short-form-item :label="$t('patient.create.form.nationality')">
          <flux-select v-model:modelValue="model.nationality_id">
            <option
              v-for="nationality in nationalities"
              :key="nationality.id"
              :value="nationality.id"
              :label="nationality.description"
            >
              {{ nationality.description }}
            </option>
          </flux-select>
        </flux-short-form-item>
        <flux-short-form-item
          :label="$t('patient.create.form.step.identity_documents.type')"
          span="col-span-6"
        >
          <flux-select v-model:modelValue="model.identity_documents[0].type">
            <option
              v-for="document_type in [
                'passport',
                'id_card',
                'drivers_license',
                'alien_passport',
                'refugee_passport',
                'official_passport',
                'business_passport',
                'second_passport',
                'diplomatic_passport',
                'emergency_passport',
                'laissez-passer',
                'other',
              ]"
              :key="document_type"
              :value="document_type"
              :label="$t(`identity_documents.type.${document_type}`)"
            >
              {{ $t(`identity_documents.type.${document_type}`) }}
            </option>
          </flux-select>
        </flux-short-form-item>
        <flux-short-form-item
          :label="$t('patient.create.form.step.identity_documents.number')"
          span="col-span-6"
        >
          <flux-input v-model:modelValue="model.identity_documents[0].number">
            <template
              v-if="
                model.nationality_id == 138 &&
                model.identity_documents[0].number.length > 0 &&
                ['passport', 'id_card'].includes(
                  model.identity_documents[0].type,
                )
              "
              #suffix
            >
              <i
                class="fas fa-check"
                v-if="
                  documentNumberIsValid(model.identity_documents[0].number)
                    .success == true
                "
                style="color: #14ce68; margin-right: 6px"
              />
              <i
                class="fas fa-times"
                v-else
                style="color: #ef3e36; margin-right: 6px"
              />
            </template>
          </flux-input>
        </flux-short-form-item>
      </flux-short-form>
    </flux-card>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { Nationality } from "../../models/Nationality";
import { PatientCreateForm } from "@/models/Forms";
import { documentNumberIsValid } from "../../libraries/utils/bsn";
import { PatientApi } from "../../models/Patient";
import { $t } from "@/libraries/i18n";
import { useNationalityStore } from "@/libraries/store/Nationalities";
import { pinia } from "@/libraries/store";
import { fullName } from "@/models/Person";

const model = defineModel<PatientCreateForm>("modelValue", { required: true });
const props = defineProps<{
  covLoading: boolean;
}>();

const emit = defineEmits<{
  error: [value: boolean];
}>();

const nationalities = ref<Nationality[]>([]);

const nationaliyStore = useNationalityStore(pinia);

const existingPatient = ref<PatientApi>();

onMounted(() => {
  fetchNationalitiesAndSetDutch();
});

async function fetchNationalitiesAndSetDutch() {
  nationalities.value = await nationaliyStore.findAll();
  const dutchNationality = nationalities.value.find(
    (n) => n.description === "Nederlandse",
  );
  if (dutchNationality) {
    model.value.nationality_id = dutchNationality.id;
  }
}
</script>
