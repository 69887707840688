<template>
  <flux-card shadow="never">
    <flux-long-form>
      <flux-long-form-item
        title="Notificaties"
        description="Selecteer welk type e-mails de patiënt ontvangt."
      >
        <flux-input-box label="E-mailtypes">
          <div class="flex w-full flex-col text-sm text-text-secondary">
            <flux-checkbox-label
              v-for="type in suppressedEmailTypes"
              :key="type.id"
            >
              <flux-checkbox v-model="emailTypeFormState[type.id]" />
              {{ $t(`email_sents.type.${type.id}`) }}
            </flux-checkbox-label>
          </div>
        </flux-input-box>
      </flux-long-form-item>
    </flux-long-form>
    <flux-button-group class="justify-end">
      <flux-button
        :disabled="!isEmailTypesDirty"
        @click="resetEmailTypes"
        type="secondary"
      >
        {{ $t("general.cancel") }}
      </flux-button>
      <flux-submit-button
        :disabled="!isEmailTypesDirty"
        @click="saveEmailTypes"
        type="primary"
      >
        {{ $t("general.save") }}
      </flux-submit-button>
    </flux-button-group>
  </flux-card>
</template>

<script setup lang="ts">
import { ref, reactive, toRef, toRaw, watch, computed } from "vue";
import { useNotify } from "@/composables/notify";
import { $t } from "@/libraries/i18n";
import { usePatient, useUpdatePatient } from "@/composables/patient";
import { clone } from "@/libraries/utils/clone";

const { notify } = useNotify();

const props = defineProps<{
  zisNumber: number;
}>();

const { data: patient, invalidatePatient } = usePatient(
  toRef(props, "zisNumber"),
);
const { updatePatient } = useUpdatePatient(toRef(props, "zisNumber"));

const loading = ref(false);
const emit = defineEmits<{
  (e: "updated"): void;
  (e: "loading", value: boolean): void;
}>();

const suppressedEmailTypes = [
  { id: "appointment.reminder" },
  { id: "appointment.created" },
  { id: "appointment.updated" },
  { id: "appointment.deleted" },
] as const;

type EmailType = (typeof suppressedEmailTypes)[number]["id"];

// Object to track checkbox state for each email type
const emailTypeFormState = reactive<Record<EmailType, boolean>>({
  "appointment.reminder": true,
  "appointment.created": true,
  "appointment.updated": true,
  "appointment.deleted": true,
});

// Keep track of original email type state
const initialEmailTypeState = ref<Record<EmailType, boolean>>({
  "appointment.reminder": true,
  "appointment.created": true,
  "appointment.updated": true,
  "appointment.deleted": true,
});

const isEmailTypesDirty = computed(() => {
  return suppressedEmailTypes.some(
    (type) =>
      emailTypeFormState[type.id] !== initialEmailTypeState.value[type.id],
  );
});

watch(
  patient,
  () => {
    if (patient.value) {
      const suppressed_email_types = toRaw(
        patient.value.suppressed_email_types,
      );

      // Initialize checkbox state based on suppressed email types
      suppressedEmailTypes.forEach((type) => {
        emailTypeFormState[type.id] = !suppressed_email_types.includes(type.id);
      });

      // Clone for tracking changes
      initialEmailTypeState.value = clone(emailTypeFormState);
    }
  },
  { immediate: true },
);

// Function to save just the email types
async function saveEmailTypes() {
  if (!patient.value) {
    throw new Error("no patient");
  }

  loading.value = true;
  emit("loading", true);

  // Update suppressed_email_types based on checkboxes that are not checked
  const updatedSuppressedEmailTypes = suppressedEmailTypes
    .filter((type) => !emailTypeFormState[type.id])
    .map((type) => type.id);

  try {
    await updatePatient({
      suppressed_email_types: updatedSuppressedEmailTypes,
    });

    notify({
      message: $t("patient.edit.emailpreferences.success") as string,
      type: "success",
    });

    // Update initial state
    initialEmailTypeState.value = clone(emailTypeFormState);

    invalidatePatient(props.zisNumber);
    emit("updated");
  } catch (error) {
    notify({
      message: $t("patient.edit.emailpreferences.error") as string,
      type: "error",
    });
  } finally {
    loading.value = false;
    emit("loading", false);
  }
}

// Reset just the email types
function resetEmailTypes() {
  suppressedEmailTypes.forEach((type) => {
    emailTypeFormState[type.id] = initialEmailTypeState.value[type.id];
  });
}
</script>
