<template>
  <flux-modal
    :visible="visible"
    @update:visible="emit('update:visible', $event)"
    title="Patiënten exporteren"
    top="2rem"
    @closed="closed()"
    @close="close()"
  >
    <div v-flux-loading="state == 'downloading'">
      <flux-long-form>
        <flux-long-form-item
          title="Exportkolommen"
          description="De volgende velden zullen worden geëxporteerd. Kies ten minste één veld."
        ></flux-long-form-item>
        <div>
          <div class="grid grid-cols-1">
            <flux-checkbox-label
              ><flux-checkbox
                v-model:modelValue="form.columns.zis_number"
              />ZIS-nummer</flux-checkbox-label
            >
            <flux-checkbox-label
              ><flux-checkbox
                v-model:modelValue="form.columns.full_name"
              />Naam</flux-checkbox-label
            >
            <flux-checkbox-label
              ><flux-checkbox
                v-model:modelValue="form.columns.gender"
              />Geslacht</flux-checkbox-label
            >
            <flux-checkbox-label
              ><flux-checkbox
                v-model:modelValue="form.columns.bsn"
              />BSN</flux-checkbox-label
            >
            <flux-checkbox-label
              ><flux-checkbox
                v-model:modelValue="form.columns.address"
              />Adres</flux-checkbox-label
            >
            <flux-checkbox-label
              ><flux-checkbox
                v-model:modelValue="form.columns.email"
              />E-mailadres</flux-checkbox-label
            >
            <flux-checkbox-label
              ><flux-checkbox
                v-model:modelValue="form.columns.phone"
              />Telefoonnummer</flux-checkbox-label
            >
            <flux-checkbox-label
              ><flux-checkbox
                v-model:modelValue="form.columns.insurance"
              />Laatste zorgverzekering</flux-checkbox-label
            >
            <flux-checkbox-label
              ><flux-checkbox
                v-model:modelValue="form.columns.created_at"
              />Inschrijfdatum</flux-checkbox-label
            >
            <flux-checkbox-label
              ><flux-checkbox
                v-model:modelValue="form.columns.date_of_birth"
              />Geboortedatum</flux-checkbox-label
            >
          </div>
        </div>

        <flux-long-form-divider></flux-long-form-divider>
        <flux-long-form-item title="Bestandstype"></flux-long-form-item>
        <div>
          <!-- v-model:modelValue="form.export_type" -->
          <div>
            <flux-radio-button
              v-model:modelValue="form.export_type"
              optionValue="xlsx"
              >Excel (.xlsx)</flux-radio-button
            >
            <flux-radio-button
              v-model:modelValue="form.export_type"
              optionValue="csv"
              >CSV</flux-radio-button
            >
          </div>
        </div>
      </flux-long-form>
    </div>
    <template #footer>
      <flux-button-group>
        <flux-button size="small" type="default" @click="close()"
          >Annuleren</flux-button
        >
        <flux-button
          size="small"
          type="primary"
          @click="downloadExport()"
          :disabled="state == 'downloading'"
          >Exporteren</flux-button
        >
      </flux-button-group>
    </template>
  </flux-modal>
</template>

<script lang="ts" setup>
import { apiClient } from "@/libraries/utils/axios";

import download from "downloadjs";
import { useNotify } from "@/composables/notify";
import { onMounted, ref } from "vue";
import { clone } from "@/libraries/utils/clone";

const { notify } = useNotify();

const state = ref("initial");
const initalForm = {
  export_type: "xlsx",
  columns: {
    zis_number: true,
    full_name: true,
    gender: false,
    bsn: false,
    address: false,
    email: true,
    phone: false,
    insurance: false,
    created_at: false,
    date_of_birth: false,
  },
};

const emit = defineEmits<{
  (e: "update:visible", v: boolean): void;
}>();

const form = ref<typeof initalForm>(clone(initalForm));
const visible = defineModel<boolean>("visible", { required: true });

onMounted(() => resetForm());

function resetForm() {
  form.value = clone(initalForm);
}

function close() {
  emit("update:visible", false);
}

function closed() {
  resetForm();
}

function getParams() {
  const params = {
    columns: Object.keys(form.value.columns).filter(
      (key) => !!form.value.columns[key as keyof typeof form.value.columns],
    ),
    export_type: form.value.export_type,
  };

  return params;
}

function getFileName() {
  return `patienten.${form.value.export_type}`;
}

function getMimeType() {
  if (form.value.export_type === "xlsx") {
    return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  }

  return "text/csv";
}

async function downloadExport() {
  state.value = "downloading";
  try {
    const res = await apiClient.get("patients/export", {
      responseType: "blob",
      params: getParams(),
    });
    download(res.data, getFileName(), getMimeType());
    state.value = "initial";
    close();
  } catch (error) {
    notify({
      type: "error",
      message:
        "Het is niet gelukt om de download te starten. Probeer het later opnieuw.",
    });
    state.value = "failed";
  }
}
</script>
