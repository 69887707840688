import { useQuery, useQueryClient } from "@tanstack/vue-query";
import { medicalConversationKeys } from "@/queries/medicalConversations/medicalConversationKeys";
import {
  getMedicalConversations,
  processMedicalConversations,
  updatePatientMedicalConversations,
} from "@/queries/medicalConversations/medicalConversations";
import { useNotify } from "./notify";
import { $t } from "@/libraries/i18n";
import { computed } from "vue";

export function useMedicalConversations() {
  const { notify } = useNotify();
  const invalidate = useInvalidateMedicalConversations();

  const query = useQuery({
    queryKey: medicalConversationKeys.medicalConversations(),
    queryFn: () => getMedicalConversations(),
    enabled: true,
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 5,
  });

  async function processConversation(conversationId: string) {
    try {
      await processMedicalConversations(conversationId);
      invalidate();
    } catch {
      notify({
        message: $t("error.something_went_wrong"),
        type: "error",
      });
    }
  }

  async function updatePatientConversation(conversationId: string) {
    try {
      await updatePatientMedicalConversations(conversationId);
      invalidate();
    } catch {
      notify({
        message: $t("error.something_went_wrong"),
        type: "error",
      });
    }
  }

  const hasUnhandledConversations = computed(() =>
    query.data?.value?.some((conversation) => !conversation.processed_at),
  );

  return {
    ...query,
    processConversation,
    updatePatientConversation,
    invalidate,
    hasUnhandledConversations,
  };
}

export function useInvalidateMedicalConversations() {
  const queryClient = useQueryClient();

  function invalidateMedicalConversations() {
    queryClient.invalidateQueries({
      queryKey: medicalConversationKeys.medicalConversations(),
    });
  }

  return invalidateMedicalConversations;
}
