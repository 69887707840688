<template>
  <flux-tooltip>
    <i
      class="fas fa-info-square text-text-quaternary hover:text-text-primaryHover"
    ></i>
    <template #content><slot></slot></template>
  </flux-tooltip>
</template>

<script lang="ts" setup></script>
