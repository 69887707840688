<template>
  <div class="contents">
    <flux-card-button-header
      class="m-2"
      :title="$t('patient.create.form.step.company.title')"
    />
    <flux-card
      class="mb-2"
      v-if="patient"
      v-flux-loading="isMutating"
      shadow="never"
    >
      <flux-input-box label="Bedrijfsdivisie">
        <CompanyDivisionSelect
          :id="patient.company_division_id"
          @input:id="onInput"
        />
      </flux-input-box>
      <flux-input-box label="Personeelsnummer">
        <flux-input v-model:modelValue="form.employee_number" />
      </flux-input-box>
      <div class="mt-4 flex justify-end">
        <flux-button-group>
          <flux-button
            :disabled="!changed || isMutating"
            type="primary"
            @click="update(form)"
            >Opslaan</flux-button
          >
        </flux-button-group>
      </div>
    </flux-card>
  </div>
</template>

<script lang="ts" setup>
import CompanyDivisionSelect from "@/components/ui/Input/CompanyDivisionSelect.vue";
import { $t } from "@/libraries/i18n";
import { isValidationErrorResponse } from "@/libraries/utils/errorHandling";
import { useNotify } from "@/composables/notify";
import { computed, reactive, ref, toRef, watch } from "vue";
import { usePatient, useUpdatePatient } from "@/composables/patient";

const { notify } = useNotify();

const props = defineProps<{
  zisNumber: number;
}>();

const { data: patient } = usePatient(toRef(props, "zisNumber"));
const { updatePatient, isLoading: isMutating } = useUpdatePatient(
  toRef(props, "zisNumber"),
);

const form: {
  company_division_id: string | null;
  employee_number: string | undefined;
} = reactive({
  company_division_id: null,
  employee_number: undefined,
});

const initial = ref<typeof form>({
  company_division_id: null,
  employee_number: undefined,
});

watch(
  patient,
  () => {
    if (!patient.value) {
      return;
    }
    initial.value = {
      company_division_id: patient.value.company_division_id ?? null,
      employee_number: patient.value.employee_number,
    };
    form.company_division_id = initial.value.company_division_id;
    form.employee_number = initial.value.employee_number;
  },
  { immediate: true },
);

const emit = defineEmits<{
  (e: "updatePatient"): void;
}>();

type State = "initial" | "saving";

const state = ref<State>("initial");

const changed = computed(
  () =>
    initial.value.company_division_id !== form.company_division_id ||
    initial.value.employee_number !== form.employee_number,
);

async function update(newValues: typeof form): Promise<void> {
  try {
    state.value = "saving";
    await updatePatient({
      company_division_id: newValues.company_division_id ?? null,
      employee_number: newValues.employee_number ?? null,
    });
    initial.value.company_division_id = newValues.company_division_id;
    initial.value.employee_number = newValues.employee_number;

    notify({
      type: "success",
      title: $t("general.success"),
      message: $t("patient.edit.company.success") as string,
    });
  } catch (e) {
    if (isValidationErrorResponse(e)) {
      notify({
        message: e.response.data.message,
        type: "error",
      });
    } else {
      notify({
        message: $t("error.unknown"),
        type: "error",
      });
    }
  } finally {
    state.value = "initial";
    emit("updatePatient");
  }
}

function onInput(id?: string) {
  form.company_division_id = id ?? null;
}

defineExpose({ changed, form, emit, update, state, onInput, initial });
</script>
