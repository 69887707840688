import { z } from "zod";

const baseActor = z.object({
  healthcare_provider_id: z.number(),
});

export const patientActorScheme = baseActor.merge(
  z.object({
    type: z.literal("patient"),
    zis_number: z.number(),
  }),
);

const unknownPatientActorScheme = baseActor.merge(
  z.object({
    type: z.literal("unknown_patient"),
    email: z.string(),
  }),
);

export const systemActorScheme = baseActor.merge(
  z.object({
    type: z.literal("system"),
    initiator: z.string(),
  }),
);

export const userActorScheme = baseActor.merge(
  z.object({
    type: z.literal("user"),
    user_id: z.number(),
  }),
);

export const actorScheme = z.discriminatedUnion("type", [
  patientActorScheme,
  unknownPatientActorScheme,
  systemActorScheme,
  userActorScheme,
]);

export type Actor = z.infer<typeof actorScheme>;
export type PatientActor = z.infer<typeof patientActorScheme>;
export type UnknownPatientActor = z.infer<typeof unknownPatientActorScheme>;
export type SystemActor = z.infer<typeof systemActorScheme>;
export type UserActor = z.infer<typeof userActorScheme>;

export function getUserIdFromActor(actor: Actor) {
  return actorIsUserActor(actor) ? actor.user_id : undefined;
}

function actorIsUserActor(actor: Actor): actor is UserActor {
  return "user_id" in actor && actor.type === "user";
}
