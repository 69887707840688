<template>
  <div>
    <flux-dropdown direction="left">
      <template #button="slotProps">
        <div
          class="group mt-1 flex cursor-pointer select-none items-center space-x-2 rounded-lg p-2 outline-none transition duration-75 hover:bg-background-tertiary focus:bg-background-tertiary focus:ring"
          tabindex="0"
          @click="
            ($event) => {
              slotProps.handleClick($event);
              $event.preventDefault();
            }
          "
          @keydown.enter="
            ($event) => {
              slotProps.handleKeyDown($event);
              $event.preventDefault();
            }
          "
        >
          <Avatar v-if="avatar" :avatar="avatar" :big="true"></Avatar>
          <span
            class="hidden text-text-secondary group-hover:text-text-primaryHover group-focus:text-text-primaryFocus xl:inline"
            >{{ profile_button_name }}</span
          >
        </div>
      </template>
      <flux-dropdown-item icon="fal fa-gift" @click="openReleaseNotes()">{{
        $t("releasenotes.name")
      }}</flux-dropdown-item>
      <flux-dropdown-item icon="fal fa-book" @click="openManual()">
        {{ $t("general.manual") }}
      </flux-dropdown-item>
      <flux-dropdown-item
        v-if="hasFeatureFlag('tech-support')"
        icon="far fa-question-circle"
        @click="showIntercomModalHandler"
      >
        Neem contact op
      </flux-dropdown-item>
      <hr class="my-1 border-b border-border-primary" />
      <flux-dropdown-item icon="fab fa-linkedin" @click="openLinkedIn()"
        >Volg Flux op LinkedIn</flux-dropdown-item
      >
      <flux-dropdown-item icon="fab fa-instagram" @click="openInstagram()"
        >Volg @fluxmedicalsystems op Instagram
      </flux-dropdown-item>
      <hr class="my-1 border-b border-border-primary" />
      <flux-dropdown-item icon="fal fa-sign-out" @click="logout">{{
        $t("general.logout")
      }}</flux-dropdown-item>
      <hr class="my-1 border-b border-border-primary" v-if="isDemo()" />
      <flux-dropdown-item icon="far fa-adjust" @click="toggleDarkMode">
        {{ isDark ? $t("general.lightmode") : $t("general.darkmode") }}
      </flux-dropdown-item>
      <template v-if="showQaWarning">
        <hr class="my-1 border-b border-border-primary" />
        <flux-dropdown-item
          icon="fal fa-exclamation-triangle"
          @click="removeWarning"
          >Verwijder QA meldingen</flux-dropdown-item
        >
      </template>
    </flux-dropdown>
    <flux-modal
      class="w-full max-w-3xl"
      v-model:visible="showIntercomModal"
      :title="$t('support_modal.title')"
    >
      <div class="flex flex-col gap-2">
        <div class="text-sm text-text-tertiary">
          {{ $t("support_modal.subtitle") }}
        </div>
        <flux-long-form-divider />
        <flux-textarea
          v-model="messageBody"
          ref="focusInput"
          :minRows="6"
          :maxRows="12"
          :placeholder="$t('support_modal.placeholder')"
        />
      </div>
      <template #footer>
        <flux-button
          type="primary"
          @click="submitSupportMessage()"
          :disabled="!messageBody"
        >
          {{ $t("general.send") }}
        </flux-button>
      </template>
    </flux-modal>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, inject, Ref } from "vue";
import { authModule } from "@/auth";
import { fullNameInitials } from "@/models/Person";
import { telemetryManager } from "@/libraries/telemetry/Manager";
import { getUser } from "@/libraries/plugins/getUser";
import { useRouter } from "vue-router";
import store from "@/libraries/store";
import { apiClient } from "@/libraries/utils/axios";
import Avatar from "@/components/Avatar.vue";
import { useNotify } from "@/composables/notify";
import { hasFeatureFlag } from "@/libraries/plugins/hasFeatureFlag";
import { useShowQaWarning } from "@/composables/showQaWarning";
import { isDemo } from "@/libraries/plugins/isDemo";

const isDark = inject<Ref<boolean>>("isDark");
const toggleDarkMode = inject<() => void>("toggleDarkMode");

const router = useRouter();
const { notify } = useNotify();
const { showQaWarning, removeWarning } = useShowQaWarning();

const profile_button_name = computed(() => fullNameInitials(getUser()));
const avatar = computed(() => {
  const userId = getUser().id;
  return getUser().healthcare_provider.users.find((user) => user.id === userId)
    ?.avatar;
});

const showIntercomModal = ref(false);
const messageBody = ref<string>();
const focusInput = ref();

function submitSupportMessage() {
  apiClient.post("/tech_support/create_thread", {
    body: messageBody.value,
  });
  notify({
    title: "Het bericht is verzonden",
    message: "Er zal antwoord worden gegeven via de mail",
    type: "success",
  });
  messageBody.value = "";
  showIntercomModal.value = false;
}

function showIntercomModalHandler() {
  showIntercomModal.value = true;
  setTimeout(() => {
    focusInput.value.focus();
  }, 100);
}

function logout() {
  const userTheme = localStorage.getItem("user_theme"); // Thema opslaan
  authModule(store).logout();
  if (userTheme) {
    localStorage.setItem("user_theme", userTheme); // Thema herstellen
  }
  router.push("/login");
}

function openLinkedIn() {
  window.open(
    "https://www.linkedin.com/company/flux-medical-systems/",
    "_blank",
    "noopener",
  );
}

function openInstagram() {
  window.open(
    "https://www.instagram.com/fluxmedicalsystems",
    "_blank",
    "noopener",
  );
}

function openManual() {
  window.open("https://joinflux.nl/nl/documentatie", "_blank", "noopener");
}

function openReleaseNotes() {
  telemetryManager.queueEntry({
    action: "releasenotes.open.profilemenu",
  });
  router.push("/releasenotes").catch(() => {});
  return true;
}
</script>
