<template>
  <div>
    <template v-if="zorgvergoedingError === zorgvergoedingRatelimitError">
      <flux-label type="down">
        <clickable-icon
          v-if="displayDetails"
          icon="fal fa-info-square cursor-pointer"
          content="Klik voor extra info"
          @click="showDetails = !showDetails"
        ></clickable-icon>
      </flux-label>
      <div class="my-4 text-sm text-text-secondary" v-if="showDetails">
        {{ $t("zorgvergoeding.ratelimit") }}
      </div>
    </template>
    <template v-else-if="zorgvergoedingResult">
      <flux-label type="neutral">
        <template v-if="maxTreatments">
          <span class="whitespace-nowrap">
            {{ maxTreatments }}
            {{ $t("general.treatment", maxTreatments).toLowerCase() }}
          </span>
          <template v-if="maxManualTreatments">
            , {{ maxManualTreatments }} manueel </template
          >&nbsp;
        </template>
        <template v-else-if="maxCompensation">
          € {{ centToEuro(maxCompensation) }}
        </template>
        <clickable-icon
          v-if="displayDetails"
          icon="fal fa-info-square cursor-pointer"
          content="Klik voor extra info"
          @click="showDetails = !showDetails"
        ></clickable-icon>
      </flux-label>
      <div
        class="my-4 text-sm text-text-secondary"
        v-if="showDetails && displayDetails === 'expand'"
      >
        <ZorgvergoedingDetails :zorgvergoedingResult="zorgvergoedingResult" />
      </div>
      <flux-modal v-model:visible="showModal">
        <ZorgvergoedingDetails :zorgvergoedingResult="zorgvergoedingResult" />
      </flux-modal>
    </template>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from "vue";
import { useZorgvergoedingen } from "@/composables/zorgvergoedingen";
import ZorgvergoedingDetails from "./ZorgvergoedingDetails.vue";
import { getDateForInsurer } from "@/components/finance/CreateInvoice/CreateInvoiceHelpers";
import { centToEuro } from "../../../libraries/utils";
import { zorgvergoedingRatelimitError } from "@/queries/zorgvergoedingen/zorgvergoedingenQuery";
import { $t } from "@/libraries/i18n";

type DisplayDetails = "modal" | "expand" | false;

const props = defineProps<{
  payerInsurer: {
    insurer_number?: string;
    start_date: string;
    end_date: string;
  };
  packageCode: string;
  displayDetails: DisplayDetails;
}>();

const { data: zorgvergoedingResults, error: zorgvergoedingError } =
  useZorgvergoedingen(
    computed(() => props.payerInsurer.insurer_number ?? ""),
    computed(() => props.packageCode),
    computed(() =>
      getDateForInsurer(
        props.payerInsurer.start_date,
        props.payerInsurer.end_date,
        new Date(),
      ),
    ),
  );

const zorgvergoedingResult = computed(() => zorgvergoedingResults.value?.at(0));

const maxTreatments = computed(() =>
  zorgvergoedingResults.value?.reduce(
    (max, zv) => Math.max(max, zv.data.aantal ?? 0),
    0,
  ),
);

const maxManualTreatments = computed(() =>
  zorgvergoedingResults.value?.reduce(
    (max, zv) => Math.max(max, zv.data.mtaant ?? 0),
    0,
  ),
);

const maxCompensation = computed(() =>
  zorgvergoedingResults.value?.reduce(
    (max, zv) => Math.max(max, zv.data.bedrag ?? 0),
    0,
  ),
);

const showDetails = ref(false);

const showModal = computed({
  get: () => showDetails.value && props.displayDetails === "modal",
  set: () => (showDetails.value = false),
});
</script>
