<template>
  <div
    :class="
      xScroll ? 'max-w-full overflow-x-scroll dark:[color-scheme:dark]' : ''
    "
  >
    <table
      class="w-full max-w-full border-collapse border-b border-border-primary text-left"
      :class="[compact ? 'text-xs' : 'text-sm']"
      style="border-spacing: 0.75rem 1rem"
    >
      <thead class="font-medium text-text-quaternary">
        <tr
          class="h-12 border-b border-border-primary"
          v-if="columns.length !== 0"
        >
          <th
            v-if="selectableRows"
            :class="compact ? 'pl-1 pr-2' : 'pl-3 pr-2'"
          >
            <flux-checkbox
              v-model:modelValue="selectedAllRows"
              :indeterminate="selectedSomeButNotAllRows"
            ></flux-checkbox>
          </th>
          <th
            v-for="column in columns"
            :key="column.label + column.prop"
            scope="col"
            :class="[
              'select-none pr-2 text-xs font-medium uppercase tracking-wider text-text-primary',
              compact ? 'pl-1' : 'pl-3',
              column.columnClass,
              column.align === 'right' ? 'text-right' : '',
              column.thClass,
              column.sortable ? 'cursor-pointer' : '',
            ]"
            @click="
              column.sortable
                ? setSortState(column.prop, column.sortFunction)
                : undefined
            "
          >
            <template v-if="column.smLabel === undefined">
              <flux-button
                class="px-0 text-xs uppercase"
                v-if="clickableColumns && column.clickable"
                type="text"
                size="small"
                @click="emit('clickColumn', column.prop)"
              >
                {{ column.label }}
              </flux-button>
              <template v-else>
                {{ column.label }}
              </template>
            </template>
            <template v-else>
              <span class="inline sm:hidden">
                {{ column.smLabel }}
              </span>
              <span class="hidden sm:inline">
                {{ column.label }}
              </span>
            </template>
            <template v-if="sortState && sortState.column === column.prop">
              <i
                class="fas fa-sort-up"
                v-if="sortState.direction === 'asc'"
              ></i>
              <i class="fas fa-sort-down" v-else></i>
            </template>
            <i class="fas fa-sort" v-else-if="column.sortable"></i>
          </th>
        </tr>
      </thead>
      <tbody
        class="divide-y divide-divide-primary bg-background-primary dark:bg-transparent"
        v-if="data.length > 0"
      >
        <template v-for="(row, index) in data">
          <tr
            v-if="!rowIsExpanded(row)"
            @click="clickedOnRow(row)"
            @mouseover="mouseOverRow(row)"
            @mouseenter="mouseEnterRow(index)"
            @mouseleave="mouseLeaveRow(index)"
            :key="'row_' + index + (selectableRows ? rowValue(row) : '')"
            :class="[
              'group',
              usingKeyboardNavigation && index === hoverRowIndex
                ? 'bg-background-secondary'
                : '',
              noHover ? '' : 'hover:bg-background-secondary',
              clickableRows || expandable ? 'cursor-pointer' : '',
            ]"
          >
            <td v-if="selectableRows" :class="compact ? 'pl-1' : 'pl-3'">
              <flux-checkbox
                v-model:modelValue="selectedRows"
                @input="($event: InputEvent) => inputSelectedRow($event, index)"
                @mousedown.shift.prevent
                @click.shift="onMultipleSelect($event, index)"
                :optionValue="rowValue(row)"
              />
            </td>
            <td
              v-for="column in columns"
              :key="column.prop + index"
              :class="[
                'justify-around',
                compact ? 'py-1 pl-1 text-xs' : 'py-3 pl-3 text-sm',
                column.primary === false
                  ? 'text-text-tertiary '
                  : 'font-medium text-text-primary',
                column.columnClass,
                column.align === 'right' ? 'pl-0 pr-3 text-right' : '',
                column.tdClass,
              ]"
            >
              <VNodeRenderer
                :nodes="column.content(row)"
                :memo="[column, row]"
              />
            </td>
            <td class="w-6 align-middle" v-if="expandable">
              <i
                :class="[
                  'far text-text-quaternary',
                  rowIsExpanded(row) ? 'fa-chevron-up' : 'fa-chevron-down',
                ]"
                @click.stop="toggleExpansion(row)"
              ></i>
            </td>
          </tr>
          <tr v-else :key="'2row_' + index">
            <td class="py-2 pl-1" :colspan="columns.length">
              <transition
                enter-active-class="transition transform duration-200 ease-out"
                enter-from-class="scale-100 -translate-y-2 opacity-100"
                enter-to-class="scale-100 translate-x-0 opacity-100"
                leave-from-class="scale-100 opacity-100"
                leave-to-class="scale-95 opacity-100"
                :appear="true"
              >
                <slot
                  v-bind:row="row"
                  v-bind:close="() => close(row)"
                  name="expand"
                ></slot>
              </transition>
            </td>
            <td class="w-6 pt-3 align-top" v-if="expandable">
              <i
                :class="[
                  'far text-text-quaternary',
                  rowIsExpanded(row) ? 'fa-chevron-up' : 'fa-chevron-down',
                ]"
                @click.stop="toggleExpansion(row)"
              ></i>
            </td>
          </tr>
        </template>
      </tbody>
      <tbody class="bg-background-primary dark:bg-transparent" v-else>
        <tr>
          <td
            class="px-3 py-5 text-center text-sm text-text-quaternary"
            :colspan="columns.length + (selectableRows ? 1 : 0)"
          >
            <slot name="emptyState"></slot>
            <span v-if="$slots.emptyState === undefined">{{
              $t("general.no_data")
            }}</span>
          </td>
        </tr>
      </tbody>
      <slot></slot>
    </table>
    <div
      class="pointer-events-none sticky bottom-3 mt-1 flex min-h-[2.5rem] justify-around"
      v-if="selectableRows"
    >
      <transition
        enter-active-class="transition transform duration-75 ease-out"
        enter-from-class="-translate-y-2 opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition transform duration-75 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="-translate-y-2 opacity-0"
      >
        <div
          class="pointer-events-auto z-10 flex items-center gap-2 rounded-lg border border-border-primary bg-background-primary p-1 shadow"
          v-if="selectedRows.length > 0 || showActions"
        >
          <span class="mx-2 text-xs text-text-secondary"
            >{{ selectedRows.length }} geselecteerd</span
          >
          <slot
            name="selectActions"
            :selectedRows="selectedRows"
            :resetSelection="resetSelection"
          ></slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts" setup generic="TRow extends Record<string, any>, X">
import {
  computed,
  onBeforeUnmount,
  onMounted,
  PropType,
  provide,
  Ref,
  ref,
  VNode,
} from "vue";
import VNodeRenderer from "@/components/VNodeRenderer.vue";
import { TableColumn } from "./tableHelper";
import useManager, { managerKey } from "@/libraries/managers/useManager";

interface SortState<T> {
  column: string;
  direction: "asc" | "desc";
  sortFunction?: (direction: "asc" | "desc", a: T, b: T) => number;
}

const lastSelectedIndex = ref<number>();

function inputSelectedRow(x: InputEvent, index: number) {
  lastSelectedIndex.value = index;
}

function onMultipleSelect(event: MouseEvent, index: number) {
  selectBetween(event, index);

  if (event.target instanceof HTMLElement) {
    event.target.focus();
  }
}

function selectBetween(x: MouseEvent | undefined, index: number) {
  if (lastSelectedIndex.value === undefined) {
    return;
  }

  const start = Math.min(lastSelectedIndex.value, index);
  const end = Math.max(lastSelectedIndex.value, index);

  for (let i = start; i <= end; i++) {
    enableSelection(props.rowValue(props.data[i]));
  }
}

const props = defineProps({
  data: {
    default: () => [],
    type: Array<TRow>,
  },
  noHover: {
    default: false,
    type: Boolean,
  },
  clickableRows: {
    default: false,
    type: Boolean,
  },
  clickableColumns: {
    default: false,
    type: Boolean,
  },
  selectableRows: {
    default: false,
    type: Boolean,
  },
  rowValue: {
    default: (val: TRow) => val,
    type: Function as PropType<(val: TRow) => X>,
  },
  compact: {
    default: false,
    type: Boolean,
  },
  showActions: {
    default: false,
    type: Boolean,
  },
  expandKey: {
    default: "id",
    type: String,
  },
  xScroll: {
    default: false,
    type: Boolean,
  },
});

type TValue = ReturnType<typeof props.rowValue>;

const usingKeyboardNavigation = ref(false);

const emit = defineEmits<{
  (e: "sort", v?: SortState<TRow>): void;
  (e: "clickRow", row: TRow): void;
  (e: "mouseoverRow", row: TRow): void;
  (e: "mouseenterRow", row: TRow): void;
  (e: "mouseleaveRow", row: TRow): void;
  (e: "clickColumn", column: string): void;
}>();

const manager = useManager<TableColumn>();
provide(managerKey, manager);

const slots = defineSlots<{
  default: () => VNode;
  emptyState: () => VNode;
  //Generic typing of row does not work currently (2024-07-04) so it is any.
  expand?: (props: { row: any; close: () => void }) => VNode;
  selectActions: (props: {
    selectedRows: any[];
    resetSelection: () => void;
  }) => VNode;
}>();

const selectedRows = ref<TValue[]>([]) as Ref<TValue[]>;

const expandable = computed(() => slots.expand != undefined);

const expanded = ref<{ [key: string]: boolean }>({});
const sortState = ref<SortState<TRow>>();

const hoverRowIndex = ref<number>();
const hoverRow = computed(() =>
  hoverRowIndex.value !== undefined
    ? props.data[hoverRowIndex.value]
    : undefined,
);

const columns = manager.items;

function expand(row: TRow) {
  if (props.expandKey in row) {
    expanded.value[row[props.expandKey]] = true;
  }
}

function close(row: TRow) {
  if (props.expandKey in row) {
    expanded.value[(row as any)[props.expandKey]] = false;
  }
}

onMounted(() => {
  window.addEventListener("keydown", onKeydown);
});

onBeforeUnmount(() => window.removeEventListener("keydown", onKeydown));

function onKeydown(ev: KeyboardEvent) {
  if (!props.selectableRows) {
    return;
  }

  if (ev.key === "j") {
    usingKeyboardNavigation.value = true;
    hoverDown();
  } else if (ev.key === "k") {
    usingKeyboardNavigation.value = true;
    hoverUp();
  } else if (ev.key === "x" && hoverRow.value !== undefined) {
    if (ev.shiftKey) {
      selectBetween(undefined, props.data.indexOf(hoverRow.value));
    } else {
      lastSelectedIndex.value = props.data.indexOf(hoverRow.value);
      toggleSelection(props.rowValue(hoverRow.value));
    }
  }
}

function hoverUp() {
  hoverRowIndex.value =
    ((hoverRowIndex.value ?? -1) - 1 + props.data.length) % props.data.length;
}

function hoverDown() {
  hoverRowIndex.value = ((hoverRowIndex.value ?? -1) + 1) % props.data.length;
}

const selectedAllRows = computed({
  get: () => {
    const values = props.data.map(props.rowValue);
    return (
      selectedRows.value.filter((row: TValue) => values.includes(row))
        .length === props.data.length
    );
  },
  set: (selected: boolean) => {
    if (selected) {
      const allRowData = props.data.map((row) => props.rowValue(row));
      selectedRows.value = allRowData;
    } else {
      selectedRows.value = [];
    }
  },
});

function resetSelection() {
  selectedRows.value = [];
}

const selectedSomeButNotAllRows = computed(
  () => selectedRows.value.length > 0 && !selectedAllRows.value,
);

function enableSelection(rowValue: any) {
  const index = selectedRows.value.indexOf(rowValue);
  if (index === -1) {
    selectedRows.value.push(rowValue);
  }
}

function toggleSelection(rowValue: TValue) {
  const index = selectedRows.value.indexOf(rowValue);
  if (index === -1) {
    selectedRows.value.push(rowValue);
  } else {
    selectedRows.value.splice(index, 1);
  }
}
function rowIsExpanded(row: TRow): boolean {
  return expanded.value[row[props.expandKey]] ?? false;
}
function toggleExpansion(row: TRow) {
  if (rowIsExpanded(row)) {
    close(row);
  } else {
    expand(row);
  }
}
function clickedOnRow(row: TRow) {
  if (props.clickableRows) {
    emit("clickRow", row);
  }

  if (expandable.value) {
    toggleExpansion(row);
  }
}
function mouseOverRow(row: TRow) {
  emit("mouseoverRow", row);
}
function mouseEnterRow(index: number) {
  hoverRowIndex.value = index;
  usingKeyboardNavigation.value = false;
  emit("mouseenterRow", props.data[index]);
}
function mouseLeaveRow(index: number) {
  hoverRowIndex.value = undefined;
  usingKeyboardNavigation.value = false;
  emit("mouseleaveRow", props.data[index]);
}

function setSortState(
  prop: string,
  sortFunction?: (direction: "asc" | "desc", a: TRow, b: TRow) => number,
) {
  if (!sortState.value || sortState.value.column !== prop) {
    sortState.value = { column: prop, direction: "asc", sortFunction };
    emit("sort", sortState.value);
    return;
  }
  if (sortState.value.direction === "asc") {
    sortState.value.direction = "desc";
    emit("sort", sortState.value);
    return;
  }
  sortState.value = undefined;
  emit("sort", sortState.value);
}

defineExpose({ resetSelection });
</script>
