<template>
  <flux-short-form-item
    :label="$t('patient.create.form.step.email_address.email_address')"
    span="col-span-10 md:col-span-6"
  >
    <EmailInput
      v-model:modelValue="emailAddress.email_address"
      @error="(v) => emit('error', v)"
    />
  </flux-short-form-item>
  <flux-short-form-item span="col-span-2 md:hidden">
    <flux-button
      type="text"
      icon="fal fa-times"
      size="small"
      @click="emit('remove')"
    />
  </flux-short-form-item>
  <flux-short-form-item span="col-span-1 hidden md:block">
    <flux-button
      type="text"
      icon="fal fa-times"
      size="small"
      @click="emit('remove')"
    />
  </flux-short-form-item>
</template>
<script lang="ts" setup>
import { EmailAddressForm } from "@/models/Forms";
import EmailInput from "@/components/ui/Input/EmailInput.vue";

const emailAddress = defineModel<EmailAddressForm>("emailAddress", {
  required: true,
});

const emit = defineEmits<{
  (e: "error", v: boolean): void;
  (e: "remove"): void;
}>();
</script>
