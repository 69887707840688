<template>
  <div class="contents">
    <button :class="cssClass" @click="addFind()">Reference</button>
    <button :class="cssClass" @click="addOr()">Or</button>
    <button :class="cssClass" @click="addAnd()">And</button>
    <button :class="cssClass" @click="addNot()">Not</button>
    <button :class="cssClass" @click="addFalse()">False</button>
    <button :class="cssClass" @click="addTrue()">True</button>
    <button :class="cssClass" @click="addNumericComparison()">
      Comparison
    </button>
    <button :class="cssClass" @click="addIsNull()">IsNull</button>
  </div>
</template>

<script lang="ts" setup>
const emit = defineEmits(["item"]);

const cssClass =
  "py-2 rounded hover:bg-teal-600 bg-teal-100 text-teal-600 hover:text-text-primaryHover cursor-pointer";

function addFind() {
  emit("item", {
    type: "reference_boolean",
    pattern: "",
  });
}
function addIsNull() {
  emit("item", {
    type: "is_null",
    linkId: "",
  });
}

function addOr() {
  emit("item", {
    type: "or",
    item: [],
  });
}
function addAnd() {
  emit("item", {
    type: "and",
    item: [],
  });
}
function addFalse() {
  emit("item", {
    type: "constant_boolean",
    value: false,
  });
}
function addTrue() {
  emit("item", {
    type: "constant_boolean",
    value: true,
  });
}
function addNot() {
  emit("item", {
    type: "not",
    exp: {
      type: "or",
      item: [],
    },
  });
}

function addNumericComparison() {
  emit("item", {
    type: "numeric_comparison",
    left: null,
    right: null,
    operator: "=",
  });
}
</script>
