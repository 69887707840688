<template>
  <div class="flex flex-col gap-4" v-flux-loading="isFetching || isMutating">
    <PatientEditConsent
      :zisNumber="patient.zis_number"
      :consents
      action="marketing"
      label="Patiënt stemt in met het delen van data voor marketing doeleinden?"
    />
    <PatientEditConsent
      v-if="hasFeatureFlag('ldf')"
      :zisNumber="patient.zis_number"
      :consents
      action="ldf"
      label="Patiënt stemt in met het delen van behandeldata met de LDF?"
    />
    <PatientEditConsent
      v-if="hasFeatureFlag('nivel')"
      :zisNumber="patient.zis_number"
      :consents
      action="nivel"
      label="Patiënt stemt in met het delen van behandeldata met Nivel?"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { Patient } from "@/composables/patient";
import {
  updatePatientConsent,
  usePatientConsents,
} from "@/composables/patientConsents";
import { hasFeatureFlag } from "@/libraries/plugins/hasFeatureFlag";
import PatientEditConsent from "./PatientEditConsent.vue";

const props = defineProps<{
  patient: Patient;
}>();

const { data: consents, isFetching } = usePatientConsents(
  computed(() => props.patient.zis_number),
);

const { isMutating } = updatePatientConsent(
  computed(() => props.patient.zis_number),
);
</script>
