<template>
  <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
  <transition
    enter-active-class="transform ease-out duration-150 transition"
    enter-from-class="translate-y-2 opacity-0 smx:translate-y-0 smx:translate-x-2"
    enter-to-class="opacity-100 smx:translate-x-0"
    leave-active-class="transition ease-in duration-100"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
    :appear="true"
  >
    <div
      class="pointer-events-auto mt-2 transform overflow-hidden rounded-lg bg-background-secondary shadow-lg ring-1 ring-black ring-opacity-5 dark:ring-white dark:ring-opacity-10"
      v-if="show"
      @mouseenter="clearCloseHandler"
      @mouseleave="scheduleClose"
    >
      <div class="p-4">
        <div class="flex items-start">
          <div class="mr-1 flex-shrink-0">
            <i
              class="far fa-check-circle h-5 w-5 text-green-400"
              v-if="type === 'success'"
            ></i>
            <i
              class="far fa-times-circle h-5 w-5 text-rose-500"
              v-else-if="type === 'error' || type === 'warning'"
            ></i>
          </div>
          <div class="3 w-0 flex-1 pt-0.5">
            <p class="m-0 text-sm font-medium text-text-primary">
              <slot>
                <template v-if="!$slots.default">{{
                  title || message
                }}</template>
              </slot>
            </p>
            <p
              class="m-0 mt-1 whitespace-pre-line text-sm text-text-quaternary"
              v-if="!$slots.default && title"
            >
              <component
                v-if="customComponent"
                v-bind="customComponentProps"
                :is="customComponent"
              />
              <template v-if="message">{{ message }}</template>
            </p>
          </div>
          <button
            class="ml-3 flex-shrink-0 cursor-pointer self-center rounded-md bg-blue-100 font-sans text-sm font-medium text-blue-600 hover:text-text-primaryHover focus:outline-none focus:ring-2 focus:ring-offset-2 dark:bg-blue-700 dark:text-blue-100"
            v-if="action"
            ref="actionButton"
            @click="
              action.callback();
              close();
            "
            type="button"
          >
            {{ action.text }}
          </button>
          <div class="ml-2 flex-shrink-0" v-if="actuallyShowClose">
            <button
              class="group inline-flex h-6 w-6 cursor-pointer appearance-none items-center justify-around rounded-md bg-background-secondary text-text-quaternary hover:text-text-primaryHover focus:outline-none focus:ring-2"
              @click="close"
            >
              <i class="fal fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts" setup>
import { useNotify, NotificationType } from "@/composables/notify";
import { computed, onMounted, ref } from "vue";

const props = withDefaults(
  defineProps<{
    uuid: string;
    message?: string;
    title?: string;
    top?: number;
    type?: NotificationType;
    showClose?: boolean;
    duration?: number;
    action?: {
      text: string;
      callback: () => void;
    };
    customComponent?: string | object;
    customComponentProps?: object;
  }>(),
  {
    top: 0,
    type: "default",
    showClose: true,
    duration: 5000,
  },
);

const emit = defineEmits(["close"]);

const show = ref(true);
const closing = ref(false);

const closeHandler = ref<number>();

const { unmount } = useNotify();

onMounted(() => {
  scheduleClose();
});

const actuallyShowClose = computed(
  () => props.showClose || props.duration === Infinity,
);

function scheduleClose() {
  if (props.duration === Infinity || closing.value) {
    return;
  }

  clearCloseHandler();
  closeHandler.value = setTimeout(() => {
    close();
  }, props.duration);
}

function clearCloseHandler() {
  if (closeHandler.value) {
    clearTimeout(closeHandler.value);
    closeHandler.value = undefined;
  }
}

function close() {
  show.value = false;
  closing.value = true;
  clearCloseHandler();
  emit("close");
  unmount(props.uuid);
}
</script>
