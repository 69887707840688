import { Node } from "@tiptap/vue-3";
import { MedicalUpdateWithAttachements } from "@/models/MedicalUpdate";
import { consultSpecificPlaceHolderText } from "./ReplacePlaceholders";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    setConsultValues: {
      setConsultSpecificValue: (options: {
        field: string;
        update: MedicalUpdateWithAttachements;
      }) => ReturnType;
    };
  }
}

export const setConsultValues = Node.create({
  name: "setConsultValues",
  inline: true,
  group: "inline",

  addCommands() {
    return {
      setConsultSpecificValue:
        (options) =>
        ({ commands }) => {
          const text = consultSpecificPlaceHolderText(
            options.field,
            options.update,
          );
          if (text) {
            return commands.insertContent(text);
          }
          return false;
        },
    };
  },
});
