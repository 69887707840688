<template>
  <div class="contents">
    <flux-card-button-header
      class="m-2"
      :title="$t('patient.create.form.step.name.title')"
    />
    <flux-card class="mb-2" shadow="never">
      <flux-short-form>
        <flux-short-form-item
          :label="$t('patient.create.form.surname_prefix')"
          span="col-span-4"
        >
          <flux-input
            v-model:modelValue="modelValue.surname_prefix"
            prop="surname_prefix"
          />
        </flux-short-form-item>
        <flux-short-form-item
          :label="$t('patient.create.form.surname')"
          :required="true"
          span="col-span-8"
        >
          <flux-input
            v-model:modelValue="modelValue.surname"
            prop="surname"
            :required="true"
            @error="emit('error', $event)"
          />
        </flux-short-form-item>
        <flux-short-form-item
          :label="$t('patient.create.form.first_names')"
          :required="true"
        >
          <flux-input
            v-model:modelValue="modelValue.first_names"
            ref="first_names"
            prop="first_names"
            :required="true"
            @error="emit('error', $event)"
          />
        </flux-short-form-item>
        <flux-short-form-item
          :label="$t('patient.create.form.initials')"
          span="col-span-4"
          :required="true"
        >
          <flux-input
            v-model:modelValue="patientInitials"
            prop="initials"
            ref="initials"
            pattern="^[a-zA-Z\.\s\-]*$"
            :required="true"
            @error="emit('error', $event)"
          />
        </flux-short-form-item>
        <flux-short-form-item
          :label="$t('patient.create.form.nickname')"
          span="col-span-8"
        >
          <flux-input v-model:modelValue="nickname" prop="nickname" />
        </flux-short-form-item>
        <flux-short-form-item
          :required="true"
          :label="$t('patient.create.form.gender')"
        >
          <flux-radio-button-group>
            <flux-radio-button
              v-for="gender in genderOptions"
              v-model:modelValue="modelValue.gender"
              :key="gender.value"
              :label="gender.value"
              :optionValue="gender.value"
              :collapse="true"
            >
              {{ gender.label }}
            </flux-radio-button>
          </flux-radio-button-group>
          <div class="h-0 text-xs text-red-600" v-if="errors.messages.gender">
            {{ errors.messages.gender[0] }}
          </div>
        </flux-short-form-item>
        <flux-short-form-item
          v-if="hasFeatureFlag('email-templates')"
          :required="true"
          :label="$t('patient.create.form.patient_title')"
        >
          <flux-select
            v-model:modelValue="modelValue.patient_title_uuid"
            @change="autofillLocked.patientTitle = true"
          >
            <option
              v-for="patientTitle in patientTitles"
              :value="patientTitle.uuid"
            >
              {{ patientTitle.short_form }} / {{ patientTitle.long_form }}
            </option>
          </flux-select>
        </flux-short-form-item>
        <flux-short-form-item
          v-if="hasFeatureFlag('email-templates')"
          :required="true"
          :label="$t('patient.create.form.salutation_template')"
        >
          <flux-select v-model:modelValue="modelValue.salutation_template_uuid">
            <option :value="null">Praktijk standaard</option>
            <option
              v-for="salutationTemplate in salutationTemplates"
              :value="salutationTemplate.uuid"
            >
              {{ salutationTemplate.preview }}
            </option>
          </flux-select>
        </flux-short-form-item>
        <div class="col-span-12 flex justify-center">
          <flux-button
            v-if="!more"
            type="text"
            @click="more = true"
            icon="fal fa-chevron-down"
            size="medium"
          ></flux-button>
        </div>
        <template v-if="more">
          <flux-short-form-item
            :label="$t('patient.create.form.maiden_name_prefix')"
            span="col-span-4"
          >
            <flux-input v-model:modelValue="modelValue.maiden_name_prefix" />
          </flux-short-form-item>
          <flux-short-form-item
            :label="$t('patient.create.form.maiden_name')"
            span="col-span-8"
          >
            <flux-input v-model:modelValue="modelValue.maiden_name" />
          </flux-short-form-item>
          <flux-short-form-item
            :label="$t('patient.create.form.multiple_birth')"
          >
            <flux-switch
              v-model:modelValue="modelValue.multiple_birth"
              :active-text="
                modelValue.multiple_birth
                  ? $t('patient.create.form.multiple_birth_on')
                  : $t('patient.create.form.multiple_birth_off')
              "
            >
            </flux-switch>
          </flux-short-form-item>
          <flux-short-form-item :label="$t('patient.create.form.languages')">
            <flux-multiselect
              v-model:modelValue="modelValue.languages"
              :options="languages"
              idKey="code"
              labelKey="communication_language_nl"
            />
          </flux-short-form-item>
          <div class="col-span-12 flex justify-center">
            <flux-button
              type="text"
              @click="more = false"
              icon="fal fa-chevron-up"
              size="medium"
            ></flux-button>
          </div>
        </template>
      </flux-short-form>
    </flux-card>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue";
import { PatientCreateForm } from "@/models/Forms";
import { getInitialsFromFirstNames } from "../../libraries/utils";
import { Language } from "@/models/Language";
import { MessageBag } from "@/libraries/utils/MessageBag";
import { useLanguageStore } from "@/libraries/store/Languages";
import { pinia } from "@/libraries/store";
import { $t } from "@/libraries/i18n";
import { usePatientTitles } from "@/composables/patientTitle";
import { useSalutationTemplates } from "@/composables/salutationTemplate";
import { hasFeatureFlag } from "@/libraries/plugins/hasFeatureFlag";

/* TODO Vue 3:
 * value -> modelValue
 */
const props = defineProps<{
  modelValue: PatientCreateForm;
  errors: MessageBag;
}>();

const emit = defineEmits<{
  (e: "error", value: boolean): void;
}>();

const autofillLocked = ref({
  nickname: false,
  initials: false,
  patientTitle: false,
});

const more = ref(false);

const nickname = computed({
  get: () => {
    if (autofillLocked.value.nickname) {
      return props.modelValue.nickname;
    }

    props.modelValue.nickname = getNicknameFromFirstNames(
      props.modelValue.first_names,
    );
    return props.modelValue.nickname;
  },
  set: (newVal: string) => {
    autofillLocked.value.nickname = true;
    props.modelValue.nickname = newVal;
  },
});

watch(
  () => props.modelValue.gender,
  () => {
    if (autofillLocked.value.patientTitle) {
      return;
    }

    props.modelValue.patient_title_uuid =
      patientTitles.value?.filter(
        (x) =>
          x.language === "nl-NL" &&
          x.default_for_gender === props.modelValue.gender,
      )[0]?.uuid ?? null;
  },
);

const patientInitials = computed({
  get: () => {
    if (autofillLocked.value.initials) {
      return props.modelValue.initials;
    }

    props.modelValue.initials = getInitialsFromFirstNames(
      props.modelValue.first_names,
    );
    return props.modelValue.initials;
  },
  set: (newVal: string) => {
    autofillLocked.value.initials = true;
    props.modelValue.initials = newVal;
  },
});

function getNicknameFromFirstNames(firstNames: string) {
  return firstNames.split(" ").at(0) ?? "";
}

const genderOptions = ref<{ value: string; label: string }[]>([]);

const languages = ref<Language[]>([]);

const languageStore = useLanguageStore(pinia);
const { data: patientTitles } = usePatientTitles();
const { data: salutationTemplates } = useSalutationTemplates();

onMounted(async () => {
  languages.value = await languageStore.findAll();

  const toOptions = (translation: string, list: string[]) =>
    list.map((value) => {
      return {
        value,
        label: $t(translation + "." + value),
      };
    });

  genderOptions.value = toOptions("labels.gender", ["M", "F", "UNK", "UN"]);
});
</script>
