<template>
  <div class="flex flex-col gap-2" v-flux-loading="loading">
    <flux-long-form>
      <flux-long-form-item
        v-if="
          hasFeatureFlag('invoice-generation') &&
          hasFeatureFlag('insurer-contracts')
        "
        :title="$t('patient.edit.invoices.default_invoice_type.title')"
        :description="
          $t('patient.edit.invoices.default_invoice_type.description')
        "
      >
        <ReferralCreateInvoiceSettings
          v-model:modelValue="form"
          @defaultInvoiceTypesChanged="updatePatientDebounced"
        />
      </flux-long-form-item>
      <flux-long-form-item
        v-if="hasFeatureFlag('recurring-payments')"
        :title="$t('patient.edit.invoices.recurring_payments.title')"
        :description="
          $t('patient.edit.invoices.recurring_payments.description')
        "
      >
        <div class="items-left my-auto ml-6 flex flex-col gap-y-3">
          <flux-radio-button-small
            label="Abbonnementen"
            :optionValue="undefined"
            :disabled="true"
            :checked="true"
          />
        </div>
      </flux-long-form-item>
    </flux-long-form>
    <div class="flex justify-end">
      <flux-button
        type="text"
        size="small"
        icon="fal fa-plus"
        @click="mandateModalVisible = true"
      >
        Mandaat toevoegen
      </flux-button>
    </div>
    <flux-table v-flux-loading="isFetching" :data="mandates">
      <flux-table-column prop="status" label="Status">
        <template #row="row: RecurringPaymentMandate">
          <div class="max-w-fit">
            <IconLabel
              v-if="row.status === 'valid'"
              color="lime"
              icon="far fa-check"
              :text="
                $t('patient.overview.recurring_payment_mandate.status.valid')
              "
            />
            <IconLabel
              v-if="row.status === 'invalid'"
              color="rose"
              icon="far fa-times"
              :text="
                $t('patient.overview.recurring_payment_mandate.status.invalid')
              "
            />
            <IconLabel
              v-if="row.status === 'pending'"
              color="amber"
              icon="far fa-circle"
              :text="
                $t('patient.overview.recurring_payment_mandate.status.pending')
              "
            />
          </div>
        </template>
      </flux-table-column>
      <flux-table-column prop="iban" label="IBAN" />
      <flux-table-column prop="status" label="Methode">
        <template #row="row: RecurringPaymentMandate">
          {{
            $t(
              `patient.overview.recurring_payment_mandate.method.${row.method}`,
            )
          }}
        </template>
      </flux-table-column>
      <flux-table-column prop="actions" label="Acties">
        <template #row="row: RecurringPaymentMandate">
          <flux-button
            v-if="row.status === 'valid'"
            type="warning"
            size="mini"
            icon="far fa-times"
            @click="handleMandateRevoke(row.uuid)"
          >
            Intrekken
          </flux-button>
        </template>
      </flux-table-column>
    </flux-table>
    <RecurringPaymentMandateFormModal
      v-model:visible="mandateModalVisible"
      :zisNumber
      @cancel="mandateModalVisible = false"
      @saved="mandateModalVisible = false"
    />
  </div>
</template>

<script lang="ts" setup>
import { $t } from "@/libraries/i18n";
import { useNotify } from "@/composables/notify";
import { debounce } from "debounce";
import { computed, ref, watch } from "vue";
import { PatientInvoiceSettings } from "../../models/Patient";
import { usePatient, useUpdatePatient } from "@/composables/patient";
import { hasFeatureFlag } from "@/libraries/plugins/hasFeatureFlag";
import { useRecurringPaymentMandates } from "@/composables/recurringPaymentMandates";
import { RecurringPaymentMandate } from "@/models/RecurringPaymentMandate";
import { useConfirm } from "@/composables/confirm";
import IconLabel from "../ui/IconLabel.vue";
import ReferralCreateInvoiceSettings from "../Patient/Referral/ReferralCreateInvoiceSettings.vue";
import { clone } from "@/libraries/utils/clone";
import RecurringPaymentMandateFormModal from "./RecurringPaymentMandateFormModal.vue";

const { notify } = useNotify();
const { confirm } = useConfirm();

const props = defineProps<{
  zisNumber: number;
}>();

const { data: patient } = usePatient(computed(() => props.zisNumber));
const { updatePatient: mutate } = useUpdatePatient(
  computed(() => props.zisNumber),
);
const {
  isFetching,
  data: mandates,
  revokeRecurringPaymentMandate,
} = useRecurringPaymentMandates(computed(() => props.zisNumber));

const form = ref<PatientInvoiceSettings>({
  default_invoice_types: [],
});

const mandateModalVisible = ref(false);

const loading = ref(false);

watch(
  patient,
  () => {
    if (!patient.value) return;
    if (patient.value.invoice_settings) {
      form.value = clone({
        default_invoice_types: [],
        ...patient.value.invoice_settings,
      });
    }
  },
  { immediate: true, deep: true },
);

const updatePatientDebounced = debounce(updatePatient, 500);

async function updatePatient() {
  try {
    loading.value = true;
    await mutate({
      invoice_settings: form.value,
    });
    notify({
      type: "success",
      message: $t("admin.settings.saved.succes"),
    });
  } catch {
    notify({
      type: "error",
      message: $t("admin.settings.saved.failure"),
    });
  } finally {
    loading.value = false;
  }
}

async function handleMandateRevoke(uuid: string) {
  if (
    !(await confirm({
      message: "Weet je zeker dat je het mandaat wilt intrekken?",
      type: "delete",
      confirmButtonText: "Intrekken",
    }))
  ) {
    return;
  }
  try {
    await revokeRecurringPaymentMandate(props.zisNumber, uuid);
    notify({
      message: "Mandaat is ingetrokken",
      type: "success",
    });
  } catch {
    notify({
      message: "Het is niet gelukt",
      type: "error",
    });
  }
}
</script>
