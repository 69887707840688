<template>
  <div class="flex justify-around">
    <div class="w-full max-w-4xl">
      <flux-card-button-header :title="title">
        <template #action>
          <flux-button-group>
            <flux-button
              v-if="canExport && !restrictToCurrentUser"
              type="text"
              size="small"
              icon="fal fa-cloud-download"
              @click="showExportDialog()"
              >{{ $t("patients.export") }}</flux-button
            >
            <flux-button
              type="primary"
              size="small"
              icon="fal fa-plus"
              @click="router.push('patient/create')"
              >{{ $t("patient.create.button") }}</flux-button
            >
          </flux-button-group>
        </template>
      </flux-card-button-header>
      <PatientListComponent
        v-if="labels"
        :key="labels.length"
        :labels="labels"
        :restrictToCurrentUser="restrictToCurrentUser"
      />
      <ExportComponent v-if="canExport" v-model:visible="export_visible" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import ExportComponent from "@/components/Patient/Export.vue";
import PatientListComponent from "./PatientList.vue";
import { hasPermission } from "@/libraries/utils/hasPermission";
import { usePatientLabels } from "@/composables/patientLabels";
import { $t } from "@/libraries/i18n";
import { useRouter } from "vue-router";

const router = useRouter();

const props = defineProps({
  restrictToCurrentUser: {
    type: Boolean,
    default: false,
  },
});

const export_visible = ref(false);

const title = computed(() => {
  return !props.restrictToCurrentUser ? $t("patients.title") : "Mijn patiënten";
});

const canExport = computed(() => {
  return hasPermission("export-patients");
});

const { data: labels } = usePatientLabels();

function showExportDialog() {
  export_visible.value = true;
}
</script>
