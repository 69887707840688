<template>
  <div
    class="grow-wrap box-border grid overflow-hidden rounded border transition duration-75 focus-within:border-blue-600 focus-within:ring focus-within:hover:border-blue-600"
    :style="wrapperStyle"
    :class="
      inline
        ? 'border-transparent hover:border-border-primary'
        : 'border-border-secondary '
    "
    ref="wrapper"
  >
    <textarea
      class="box-border resize-none overflow-hidden break-words rounded bg-background-secondary text-text-primary placeholder-slate-800 outline-none placeholder:opacity-40 dark:placeholder-white"
      :value="modelValue"
      ref="textarea"
      type="textarea"
      :placeholder="placeholder"
      @input="($event) => onInput($event)"
      @blur="onBlur"
      :disabled="disabled"
    >
    </textarea>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";

const props = defineProps({
  modelValue: {
    type: String,
  },
  minRows: {
    default: 2,
    type: Number,
  },
  maxRows: {
    default: 6,
    type: Number,
  },
  inline: {
    default: false,
    type: Boolean,
  },
  placeholder: {
    type: String,
  },
  disabled: {
    default: false,
    type: Boolean,
  },
});

const emit = defineEmits<{
  "update:modelValue": [string];
  blur: [string];
}>();

const wrapper = ref<HTMLDivElement>();
const textarea = ref<HTMLTextAreaElement>();

const wrapperStyle = computed(() => {
  const minHeight = (props.minRows * 1.25).toString() + "rem";
  if (props.maxRows === Infinity) {
    return "min-height:" + minHeight + ";";
  }

  const maxHeight = (props.maxRows * 1.25).toString() + "rem";
  return "min-height:" + minHeight + "; max-height:" + maxHeight;
});

onMounted(() => {
  resizeTextarea();
});

defineExpose({
  focus,
});

function onBlur(event: FocusEvent) {
  if (event.target instanceof HTMLTextAreaElement) {
    emit("blur", event.target.value);
  }
}

function onInput(e: Event) {
  emitInput(e);
  resizeTextarea();
}

function focus() {
  textarea.value?.focus();
}

function resizeTextarea() {
  if (!wrapper.value || !textarea.value) {
    return;
  }
  wrapper.value.dataset.replicatedValue = textarea.value.value;
}

function emitInput(e: Event) {
  emit("update:modelValue", (e.target as HTMLInputElement).value);
}
</script>

<style lang="postcss" scoped>
.grow-wrap,
.grow-wrap::after {
  overflow-wrap: anywhere;
}

.grow-wrap {
  &::after,
  textarea {
    @apply m-0 box-border w-full px-3 py-1 text-sm;
  }
}

.grow-wrap::after {
  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) " ";

  /* This is how textarea text behaves */
  white-space: pre-wrap;

  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}

.grow-wrap > textarea,
.grow-wrap::after {
  grid-area: 1 / 1 / 2 / 2;
}
</style>
