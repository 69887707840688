import { Ref } from "vue";
import { QueryKeyFactory } from "../querykeys";
import { AppointmentStatus } from "@/models/Appointment";

export const appointmentsQueryKeys = {
  appointments: (
    start: Ref<string>,
    end: Ref<string>,
    userIds?: Ref<number[]>,
    locationIds?: Ref<number[]>,
    patientZisNumbers?: Ref<number[] | undefined>,
    companyDivisionIds?: Ref<string[] | undefined>,
    status?: Ref<AppointmentStatus>,
  ) =>
    [
      "appointments",
      start,
      end,
      userIds,
      locationIds,
      patientZisNumbers,
      companyDivisionIds,
      status,
    ] as const,
  appointmentLogs: (appointmentId: Ref<number>) =>
    ["appointmentLogs", appointmentId] as const,
} as const satisfies QueryKeyFactory;
