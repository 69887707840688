<template>
  <flux-tooltip
    class="inHeader"
    v-if="hotkey"
    :openDelay="1000"
    placement="right"
  >
    <template #content>
      <div class="flex flex-row gap-2">
        <p>{{ meta.label }}</p>
        <HotkeyDisplay :hotkey="hotkey" />
      </div>
    </template>
    <router-link :to="{ name: name }">
      <div
        :class="[
          'flex h-16 cursor-pointer items-center justify-center',
          'text-gray-300 duration-300 hover:text-text-secondaryHover',
          'relative',
        ]"
      >
        <div
          class="bg-sky-5 absolute bottom-0 left-0 top-0 flex w-1 transform items-center transition duration-300"
          :class="isActive ? 'translate-x-1.5' : '-translate-x-1 opacity-0'"
        >
          <div class="h-1 w-1 rounded-full bg-sky-400 transition"></div>
        </div>
        <i v-bind:class="meta.icon"></i>
      </div>
    </router-link>
  </flux-tooltip>
  <router-link v-else :to="{ name: name }">
    <div
      :class="[
        'flex h-16 cursor-pointer items-center justify-center',
        'text-gray-300 duration-300 hover:text-text-secondaryHover',
        'relative',
      ]"
    >
      <div
        class="bg-sky-5 absolute bottom-0 left-0 top-0 flex w-1 transform items-center transition duration-300"
        :class="isActive ? 'translate-x-1.5' : '-translate-x-1 opacity-0'"
      >
        <div class="h-1 w-1 rounded-full bg-sky-400 transition"></div>
      </div>
      <i v-bind:class="meta.icon"></i>
    </div>
  </router-link>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { MenuRouteMeta } from "../../interfaces";
import HotkeyDisplay from "../ui/HotkeyDisplay.vue";

const route = useRoute();

const props = defineProps<{
  name: string;
  meta: MenuRouteMeta;
  hotkey?: {
    key: string;
    secondKey: string;
  };
}>();

const isActive = computed(() => {
  return route.name === props.name;
});
</script>
