import {
  FilterLogicOption,
  FilterLogicOptionLogic,
  FilterOption,
  OptionFilterStatus,
} from "@/models/Filter/filter";
import { $t } from "@/libraries/i18n";
import { TranslateResult } from "vue-i18n";

export function createOptionFilter<
  DataElement extends FilterOption & { count?: number },
>({
  data,
  subject,
  prefix,
  suffix,
  label,
  filterKey,
  queryStringKey,
  optionsType,
  searchKeys,
  emptyState,
  logicOptions,
  logicOptionLogic,
  logicTranslation,
  shouldCount,
  unknownData,
  unknownLabel = $t("general.unknown"),
  uuid = crypto.randomUUID(),
  dropdownType = "checkbox",
  rememberKey,
}: {
  data: DataElement[];
  subject: string | TranslateResult;
  prefix: string | TranslateResult;
  suffix?: string | TranslateResult;
  label: (v: DataElement) => string | undefined;
  filterKey: string;
  queryStringKey: string;
  optionsType: "single" | "multiple";
  searchKeys?: string[];
  emptyState?: string;
  logicOptions?: FilterLogicOption[];
  logicOptionLogic?: FilterLogicOptionLogic;
  logicTranslation?: Map<FilterLogicOption, string>;
  shouldCount?: boolean;
  unknownData?: (number | string)[];
  unknownLabel?: string | TranslateResult;
  uuid?: string;
  dropdownType?: "checkbox" | "radio";
  rememberKey?: string;
}) {
  function getUnique(
    filterOptions: FilterOption[],
    unknownData?: (number | string)[],
  ) {
    const unique = Array.from(
      new Map(
        filterOptions.map((option) => [option[filterKey], option]),
      ).values(),
    ).filter(function (option) {
      return option[filterKey] !== undefined;
    });

    if (unknownData && unknownData.length > 0) {
      unique.push(unknownData);
    }
    return unique;
  }

  function getUniquefilterOptions(
    filterOptions: FilterOption[],
    unknownData?: (number | string)[],
    shouldCount?: boolean,
  ) {
    if (shouldCount) {
      return getUniqueAndCount(filterOptions, unknownData);
    }
    return getUnique(filterOptions, unknownData);
  }

  function getUniqueAndCount(
    filterOptions: FilterOption[],
    unknownData?: (number | string)[],
  ) {
    const unique: Map<FilterOption[string], FilterOption & { count: number }> =
      new Map();
    filterOptions.forEach((option) => {
      const key = option[filterKey];
      if (unique.has(key)) {
        unique.set(key, { ...option, count: unique.get(key)!.count + 1 });
      } else {
        unique.set(key, { ...option, count: 1 });
      }
    });
    const uniqueArray = Array.from(unique.values()).filter(function (option) {
      return option[filterKey] !== undefined;
    });
    if (unknownData && unknownData.length > 0) {
      uniqueArray.push({ [filterKey]: unknownData, count: unknownData.length });
    }
    return uniqueArray.sort((a, b) => a.count - b.count);
  }

  const defaultFilterStatus: OptionFilterStatus = {
    selectedOptions: [] as (string | number)[],
    selectedLogic: logicOptions?.at(0) ?? "EQ",
  };

  return {
    uuid,
    filterType: "option" as const,
    data: getUniquefilterOptions(data, unknownData, shouldCount),
    subject,
    prefix,
    suffix,
    label: (v: any) => label(v) ?? unknownLabel,
    filterKey,
    queryStringKey,
    optionsType,
    searchKeys,
    emptyState,
    logicOptions,
    logicOptionLogic,
    logicTranslation,
    defaultFilterStatus,
    dropdownType,
    rememberKey,
  };
}
