import { Invoice } from "@/models/Invoice";
import { InvoiceListEntry } from "@/libraries/repositories/invoiceRepository";
import { Token } from "@/models/Model";
import { apiClient } from "../axios";
import { useNotify } from "@/composables/notify";

const { notify } = useNotify();
type InvoiceOrListEntry = Invoice | InvoiceListEntry;

const getInvoiceToken = async function (
  invoice: InvoiceOrListEntry,
): Promise<Token | undefined> {
  try {
    const {
      data: { token },
    } = await apiClient.post(
      `/invoices/:id/token`,
      {},
      {
        params: {
          id: invoice.id,
        },
      },
    );
    return token;
  } catch (err) {
    notify({
      message: "De download kon niet gestart worden.",
      type: "error",
    });
  }
};

export const downloadInvoice = async function (
  invoice: InvoiceOrListEntry,
): Promise<void> {
  const token = await getInvoiceToken(invoice);
  if (!token) {
    notify({
      message: "De download kon niet gestart worden.",
      type: "error",
    });
    return;
  }
  const body = document.getElementsByTagName("body")[0];
  const iframe = document.createElement("iframe");
  iframe.src = await getInvoiceURL(invoice, token);
  iframe.style.display = "none";
  body.appendChild(iframe);
  window.setTimeout(() => {
    body.removeChild(iframe);
  }, 60000);
};

export const getInvoiceURL = async function (
  invoice: InvoiceOrListEntry,
  token?: Token,
  preview?: boolean,
) {
  if (!token) {
    token = await getInvoiceToken(invoice);
  }
  if (!token) {
    throw new Error("could not get token");
  }
  const filename = invoice.number || "concept";
  let query = `?token=${token.token}`;
  if (preview) {
    query += `&preview=${preview}`;
  }
  return (
    (import.meta.env.VITE_API_BASE_URL ?? "") +
    `/invoices/${invoice.id}/${filename}.pdf${query}`
  );
};
