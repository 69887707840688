import { parseISO } from "date-fns";
import { z } from "zod";

const consentTypes = ["permit", "deny"] as const;
export type ConsentType = (typeof consentTypes)[number];

export const patientConsentActions = ["nivel", "ldf", "marketing"] as const;
export type PatientConsentAction = (typeof patientConsentActions)[number];

const consentActions = [
  "prem.send.email",
  "prem.send.post",
  "prem.send.phone",
  "share.data.with.general.practitioner",
  "care.plan",
  "informed",
  ...patientConsentActions,
] as const;
export type ConsentAction = (typeof consentActions)[number];

export const consentSchema = z.object({
  uuid: z.string(),
  patient_zis_number: z.number(),
  referral_id: z.number().optional(),
  type: z.enum(consentTypes),
  action: z.enum(consentActions),
  user_id: z.number().optional(),
  care_plan_id: z.string().optional(),
  letter_id: z.number().optional(),
  description: z.string().optional(),
  revoked_at: z.optional(
    z.string().transform((s) => (s ? parseISO(s) : undefined)),
  ),
  created_at: z.string().transform((s) => parseISO(s)),
  updated_at: z.optional(
    z.string().transform((s) => (s ? parseISO(s) : undefined)),
  ),
});

export type Consent = z.infer<typeof consentSchema>;
