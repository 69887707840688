import { ref } from "vue";
import {
  useFloating,
  autoUpdate,
  flip,
  shift,
  offset,
  type Placement,
} from "@floating-ui/vue";

export function useDropdownPosition(
  preferredDirection: Placement = "bottom-start",
) {
  const reference = ref<HTMLElement | null>(null);
  const floating = ref<HTMLElement | null>(null);
  const placement = ref<Placement>(preferredDirection);

  const { floatingStyles } = useFloating(reference, floating, {
    placement,
    middleware: [
      offset(8),
      flip(),
      shift({
        padding: 15,
      }),
    ],
    whileElementsMounted(referenceEl, floatingEl, update) {
      const cleanup = autoUpdate(referenceEl, floatingEl, update, {
        ancestorScroll: true,
      });
      return cleanup;
    },
  });

  const updatePlacement = (newPlacement: Placement) => {
    placement.value = newPlacement;
  };

  return {
    reference,
    floating,
    floatingStyles,
    updatePlacement,
    placement,
  };
}
