import { apiClient } from "@/libraries/utils/axios";
import { recurringPaymentMandateSchema } from "@/models/RecurringPaymentMandate";

export async function fetchRecurringPaymentMandatesForPatient(
  zisNumber: number,
) {
  const res = await apiClient.get(
    `patients/${zisNumber}/recurring_payment_mandates`,
  );
  return recurringPaymentMandateSchema.array().parse(res.data);
}

export async function createRecurringPaymentMandate(
  zisNumber: number,
  signatureDate: Temporal.PlainDate,
  reference: string,
  iban: string,
) {
  await apiClient.post(`patients/${zisNumber}/recurring_payment_mandates/`, {
    signature_date: signatureDate.toString(),
    reference,
    iban,
  });
}

export async function revokeRecurringPaymentMandate(
  zisNumber: number,
  uuid: string,
) {
  await apiClient.patch(
    `patients/${zisNumber}/recurring_payment_mandates/${uuid}/revoke`,
  );
}
