<template>
  <div class="contents">
    <flux-card-button-header
      class="m-2"
      :title="$t('patient.create.form.step.contacts.title')"
      :action="$t('patient.create.form.step.contacts.add_more')"
      @action="addEmptyContact()"
      icon="fal fa-plus"
    />
    <flux-card
      class="mb-2"
      v-for="(contact, index) in contactForms"
      :key="'contact' + index.toString()"
      shadow="never"
    >
      <clickable-icon-group>
        <clickable-icon
          icon="fa-times"
          @click="() => contactForms.splice(index, 1)"
        />
      </clickable-icon-group>
      <ContactFormComponent
        v-model:modelValue="contactForms[index]"
        :formIndex="index"
        :formPropRoot="'contacts'"
        @error="(v) => emit('error', v)"
      />
    </flux-card>
  </div>
</template>

<script lang="ts" setup>
import { ContactForm } from "../../models/Contact";
import ContactFormComponent from "@/components/Patient/ContactForm.vue";

const contactForms = defineModel<ContactForm[]>({
  required: true,
});

const emit = defineEmits<{
  (e: "error", value: boolean): void;
}>();

function addEmptyContact() {
  contactForms.value.push({
    fullname: "",
    relationship: "AUNT",
    role: "1",
    phone_numbers: [],
    email_addresses: [],
    is_payer: false,
  });
}
</script>
