<template>
  <div class="relative inline-block" ref="reference">
    <slot name="button"></slot>
    <Teleport to="body" :disabled="inModal">
      <transition name="dropdown" @after-leave="emit('afterLeave')">
        <div
          class="absolute z-50 max-h-96 w-max overflow-y-auto overflow-x-hidden whitespace-nowrap rounded-md border border-border-secondary bg-background-primary py-1 text-text-primary shadow-md dark:[color-scheme:dark]"
          v-show="visible"
          ref="floating"
          :style="floatingStyles"
        >
          <slot></slot>
        </div>
      </transition>
    </Teleport>
  </div>
</template>
<script lang="ts" setup>
import { useDropdownPosition } from "@/composables/useFloatingDropdown";
import { Placement } from "@floating-ui/vue";
import { inject, PropType } from "vue";

const { visible, preferredDirection } = defineProps({
  visible: {
    default: false,
    type: Boolean,
  },
  preferredDirection: {
    default: "bottom-start" as const,
    type: String as PropType<Placement>,
  },
});

const inModal = inject("inModal", false);

const emit = defineEmits<{ (e: "afterLeave"): void }>();

const { reference, floating, floatingStyles } =
  useDropdownPosition(preferredDirection);
</script>

<style scoped>
.dropdown-enter-active,
.dropdown-leave-active {
  transition: opacity 0.2s ease;
}

.dropdown-enter-from,
.dropdown-leave-to {
  opacity: 0;
}

.dropdown-enter-to,
.dropdown-leave-from {
  opacity: 1;
}
</style>
