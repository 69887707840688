import { apiClient } from "@/libraries/utils/axios";
import { stringToPlainDateTimeZoned } from "@/libraries/utils/zodTransforms";
import { actorScheme } from "@/models/Actor";
import { appointmentStatus } from "@/models/Appointment";
import { z } from "zod";

const appointmentLogScheme = z.intersection(
  z.discriminatedUnion("type", [
    z.object({
      type: z.literal("AppointmentWasCreated"),
      payload: z.object({
        start: z.string().transform(Temporal.Instant.from),
        end: z.string().transform(Temporal.Instant.from),
      }),
    }),
    z.object({
      type: z.literal("AppointmentCancellationNoteWasChanged"),
      payload: z.object({ note: z.string() }),
    }),
    z.object({
      type: z.literal("AppointmentReferralWasChanged"),
      payload: z.object({
        referralId: z.number().optional(),
      }),
    }),
    z.object({
      type: z.literal("AppointmentWasImported"),
    }),
    z.object({
      type: z.literal("AppointmentStatusWasChanged"),
      payload: z.object({ status: z.enum(appointmentStatus) }),
    }),
    z.object({
      type: z.literal("AppointmentLocationWasChanged"),
      payload: z.object({ location_id: z.number() }),
    }),
    z.object({
      type: z.literal("AppointmentTimeWasChanged"),
      payload: z.object({
        start: z.string().transform(Temporal.Instant.from),
        end: z.string().transform(Temporal.Instant.from),
      }),
    }),
    z.object({
      type: z.literal("AppointmentWasRescheduled"),
      payload: z.object({
        start: z.string().transform(Temporal.Instant.from),
        end: z.string().transform(Temporal.Instant.from),
      }),
    }),
    z.object({
      type: z.literal("AppointmentPrivacyWasChanged"),
      payload: z.object({ private: z.boolean() }),
    }),
    z.object({ type: z.literal("AppointmentScheduleHistory") }),
    z.object({
      type: z.literal("AppointmentPatientWasChanged"),
      payload: z.object({}),
    }),
    z.object({
      type: z.literal("AppointmentResponsibleUserWasChanged"),
      payload: z.object({ user_id: z.number() }),
    }),
    z.object({
      type: z.literal("AppointmentUserWasAdded"),
      payload: z.object({ user_id: z.number() }),
    }),
    z.object({
      type: z.literal("AppointmentUserWasRemoved"),
      payload: z.object({ user_id: z.number() }),
    }),
    z.object({
      type: z.literal("AppointmentEmailWasSent"),
      payload: z.object({
        email: z.string().optional(),
        zis_number: z.number().optional(),
      }),
    }),
    z.object({
      type: z.literal("AppointmentCreditWasReserved"),
      payload: z.object({
        amount: z.number(),
      }),
    }),
    z.object({
      type: z.literal("AppointmentCreditWasUnReserved"),
      payload: z.object({ amount: z.number() }),
    }),
    z.object({
      type: z.literal("AppointmentDescriptionWasChanged"),
      payload: z.object({
        description: z.string().optional(),
      }),
    }),
    z.object({
      type: z.literal("AppointmentCreditWasSpent"),
      payload: z.object({ amount: z.number() }),
    }),
    z.object({
      type: z.literal("AppointmentCreditWasUnSpent"),
      payload: z.object({ amount: z.number() }),
    }),
    z.object({
      type: z.literal("AppointmentTypeWasChanged"),
      payload: z.object({ appointment_type_name: z.string() }),
    }),
    z.object({
      type: z.literal("AppointmentWasDeleted"),
      payload: z.object({}),
    }),
    z.object({
      type: z.literal("AppointmentBlockedForGenerationWasChanged"),
      payload: z.object({ block_generation: z.boolean() }),
    }),
  ]),
  z.object({
    // type: z.string(),
    at: stringToPlainDateTimeZoned,
    payload: z.object({
      actor: z
        .object({ type: z.string() })
        .passthrough()
        .transform((value) => {
          const actor = Object.assign(value, { type: mapType(value.type) });
          return actorScheme.parse(actor);
        })
        .optional(),
    }),
  }),
);

function mapType(type: string): string {
  const map = {
    UserActor: "user",
    PatientActor: "patient",
    UnknownPatientActor: "unknown_patient",
    SystemActor: "system",
  };
  const value = type.split("\\").at(-1);
  if (value === undefined) {
    throw new Error(`Invalid actor type: ${type}`);
  }

  if (!(value in map)) {
    throw new Error(`Invalid actor type: ${value}`);
  }

  return (map as any)[value];
}

export type AppointmentLog = z.infer<typeof appointmentLogScheme>;

export async function getAppointmentLogs(
  appointmentId: number,
): Promise<Map<Temporal.Instant, AppointmentLog[]>> {
  const res = await apiClient.get(`appointments/${appointmentId}/events`);

  const raw = z
    .record(z.string(), appointmentLogScheme.array())
    .parse(res.data.logs);

  const logs = new Map<Temporal.Instant, AppointmentLog[]>();
  for (const [key, value] of Object.entries(raw)) {
    logs.set(Temporal.Instant.from(key), value);
  }

  return logs;
}
