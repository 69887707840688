import { z } from "zod";

export const stringToPlainDate = z
  .string()
  .transform((val) => Temporal.PlainDate.from(val));

export const stringToPlainDateTime = z
  .string()
  .transform((val) => Temporal.PlainDateTime.from(val));

export const stringToPlainDateTimeZoned = z
  .string()
  .transform((val) =>
    Temporal.PlainDateTime.from(val)
      .toZonedDateTime("UTC")
      .withTimeZone("Europe/Amsterdam")
      .toPlainDateTime(),
  );

export const stringToPlainYearMonth = z
  .string()
  .transform((val) => Temporal.PlainYearMonth.from(val));
