<template>
  <div>
    <EditorItem
      v-for="(child, index) in item.item"
      :item="child"
      :key="key.toString() + index + uuid + key"
      @remove="item.item.splice(index, 1)"
      @up="up(index)"
      @down="down(index)"
    />
    <div v-if="enabled_when">
      <editor-enabled :item="enabled_when"></editor-enabled>
    </div>
    <div v-if="typeof gridColumns === 'string'">
      <flux-input v-model="gridColumns"></flux-input>
    </div>
    <div
      class="col-span-full grid grid-cols-6 gap-1 opacity-40 transition-opacity duration-75 focus-within:opacity-100 hover:opacity-100"
    >
      <button
        class="cursor-pointer rounded bg-background-secondary py-2 text-text-tertiary hover:bg-background-quaternary hover:text-text-primaryHover"
        @click="makeGrid()"
      >
        Make grid
      </button>
      <button
        class="cursor-pointer rounded bg-background-secondary py-2 text-text-tertiary hover:bg-background-quaternary hover:text-text-primaryHover"
        @click="makeQuestion()"
      >
        Make question
      </button>
      <button
        class="cursor-pointer rounded bg-background-secondary py-2 text-text-tertiary hover:bg-background-quaternary hover:text-text-primaryHover"
        @click="makeEnabledWhen()"
      >
        Make enabled_when
      </button>
      <button
        class="cursor-pointer rounded bg-teal-100 py-2 text-teal-600 hover:bg-teal-600 hover:text-text-primaryHover"
        @click="addItem('boolean')"
      >
        Add boolean
      </button>
      <button
        class="cursor-pointer rounded bg-indigo-100 py-2 text-indigo-600 hover:bg-indigo-600 hover:text-text-primaryHover"
        @click="addVAS()"
      >
        Add VAS
      </button>
      <button
        class="cursor-pointer rounded bg-indigo-100 py-2 text-indigo-600 hover:bg-indigo-600 hover:text-text-primaryHover"
        @click="addNRS()"
      >
        Add NRS
      </button>
      <button
        class="cursor-pointer rounded bg-indigo-100 py-2 text-indigo-600 hover:bg-indigo-600 hover:text-text-primaryHover"
        @click="addNumber()"
      >
        Add number
      </button>
      <button
        class="cursor-pointer rounded bg-indigo-100 py-2 text-indigo-600 hover:bg-indigo-600 hover:text-text-primaryHover"
        @click="addItem('open-choice')"
      >
        Add open-choice
      </button>
      <button
        class="cursor-pointer rounded bg-indigo-100 py-2 text-indigo-600 hover:bg-indigo-600 hover:text-text-primaryHover"
        @click="addItem('choice')"
      >
        Add choice
      </button>
      <button
        class="cursor-pointer rounded bg-orange-100 py-2 text-orange-600 hover:bg-orange-600 hover:text-text-primaryHover"
        @click="addItem('group')"
      >
        Add group
      </button>
      <button
        class="cursor-pointer rounded bg-indigo-100 py-2 text-indigo-600 hover:bg-indigo-600 hover:text-text-primaryHover"
        @click="addItem('display')"
      >
        Add display
      </button>
      <button
        class="cursor-pointer rounded bg-red-100 py-2 text-red-600 hover:bg-red-600 hover:text-text-primaryHover"
        @click="item.item = []"
      >
        Clear
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  EXTENSION,
  GroupItem,
  VALUE_SET,
  ItemType,
  Item,
} from "@/libraries/questionnaires/item";
import EditorItem from "./Item.vue";
import { computed, getCurrentInstance, nextTick, ref } from "vue";

const uuid = crypto.randomUUID();
const key = ref(0);

const props = defineProps<{
  item: GroupItem;
}>();

function up(index: number) {
  if (index == 0) {
    return;
  }
  const tmp1 = Object.assign({}, props.item.item[index]);
  const tmp2 = Object.assign({}, props.item.item[index - 1]);
  props.item.item[index - 1] = tmp1;
  props.item.item[index] = tmp2;
  const instance = getCurrentInstance();
  instance?.proxy?.$forceUpdate();
  key.value++;
  nextTick();
}

function down(index: number) {
  if (index == props.item.item.length - 1) {
    return;
  }
  const tmp1 = props.item.item[index];
  const tmp2 = props.item.item[index + 1];
  props.item.item[index + 1] = tmp1;
  props.item.item[index] = tmp2;
  const instance = getCurrentInstance();
  instance?.proxy?.$forceUpdate();
  key.value++;
  nextTick();
}

function addItem(type: ItemType | Item) {
  let item!: Item;
  if (typeof type === "string") {
    item = getItem(type);
  } else {
    item = type;
  }
  if (!("extension" in item)) {
    item.extension = [];
  }
  props.item.item.push(item);
}

function getIntegerWithControl(control: "VAS" | "NRS") {
  const item = getItem("integer");
  item.extension = [
    {
      url: EXTENSION.ITEM_CONTROL,
      valueCodeableConcept: {
        coding: [
          {
            system: VALUE_SET.ITEM_CONTROL,
            code: control,
          },
        ],
      },
    },
    {
      url: EXTENSION.MIN_VALUE,
      valueDecimal: 0,
    },
    {
      url: EXTENSION.MAX_VALUE,
      valueDecimal: 4,
    },
  ];
  return item;
}

function addVAS() {
  const item = getIntegerWithControl("VAS");
  addItem(item);
}

function addNRS() {
  const item = getIntegerWithControl("NRS");
  addItem(item);
}

function addNumber() {
  const item = getItem("integer");
  addItem(item);
}

function getItem(type: ItemType): Item {
  if (type === "boolean") {
    return {
      type,
      linkId: Math.random().toString(),
      text: "Test",
    };
  } else if (type === "open-choice") {
    return {
      type,
      linkId: Math.random().toString(),
      answerOption: [],
    };
  } else if (type === "choice") {
    return {
      type,
      linkId: Math.random().toString(),
      answerOption: [],
    };
  } else if (type === "group") {
    return {
      type: "group",
      linkId: Math.random().toString(),
      item: [],
    };
  } else if (type === "display") {
    return {
      type: "display",
      linkId: Math.random().toString(),
      text: "",
    };
  } else if (type === "integer") {
    return {
      type: "integer",
      linkId: Math.random().toString(),
    };
  }
  throw new Error(`${type} not implemented`);
}

const gridColumns = computed({
  get: () =>
    props.item?.extension
      ?.find(
        (val) =>
          val.url === EXTENSION.DISPLAY &&
          val.valueCodeableConcept?.coding.some(
            (coding) =>
              coding.system === VALUE_SET.DISPLAY && coding.code === "table",
          ),
      )
      ?.valueCodeableConcept?.coding.find(
        (coding) => coding.system === VALUE_SET.DISPLAY_GRID_COLUMNS,
      )?.code,
  set: (grid: any) => {
    const coding = props.item?.extension
      ?.find(
        (val) =>
          val.url === EXTENSION.DISPLAY &&
          val.valueCodeableConcept?.coding.some(
            (coding) =>
              coding.system === VALUE_SET.DISPLAY && coding.code === "table",
          ),
      )
      ?.valueCodeableConcept?.coding.find(
        (coding) => coding.system === VALUE_SET.DISPLAY_GRID_COLUMNS,
      );

    if (coding) {
      coding.code = grid;
    }
  },
});

function makeGrid() {
  if (props.item.extension === undefined) {
    props.item.extension = [];
  }
  let index = props.item.extension!.findIndex(
    (ex) => ex.url === EXTENSION.DISPLAY,
  );

  props.item.extension?.splice(index, 1);
  props.item.extension!.push({
    url: EXTENSION.DISPLAY,
    valueCodeableConcept: {
      coding: [
        { system: VALUE_SET.DISPLAY, code: "table" },
        { system: VALUE_SET.DISPLAY_GRID_COLUMNS, code: "max-content 1fr" },
      ],
    },
  });
  key.value++;
}

function makeEnabledWhen() {
  if (props.item.extension === undefined) {
    props.item.extension = [];
  }
  let index = props.item.extension!.findIndex(
    (ex) => ex.url === EXTENSION.ENABLED_WHEN,
  );

  props.item.extension?.splice(index, 1);
  props.item.extension!.push({
    url: EXTENSION.ENABLED_WHEN,
    valueJson: {
      type: "or",
      item: [],
    },
  });
  key.value++;
}

const enabled_when = computed(
  () =>
    props.item.extension?.find((ex) => ex.url === EXTENSION.ENABLED_WHEN)
      ?.valueJson,
);

async function makeQuestion() {
  if (props.item.extension === undefined) {
    props.item.extension = [];
  }
  let index = props.item.extension!.findIndex(
    (ex) => ex.url === EXTENSION.DISPLAY,
  );
  props.item.extension?.splice(index, 1);

  props.item.extension?.push({
    url: EXTENSION.DISPLAY,
    valueCodeableConcept: {
      coding: [{ system: VALUE_SET.DISPLAY, code: "question" }],
    },
  });
  key.value++;
}
</script>
