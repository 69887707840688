<template>
  <div>
    <flux-alert
      class="mb-1"
      v-if="existingPatient"
      type="warning"
      title="Warning"
    >
      <p>
        Patiënt is al geregistreerd:
        <router-link :to="'/patient/' + existingPatient.zis_number">
          {{ fullName(existingPatient) }}
        </router-link>
      </p>
    </flux-alert>
    <flux-card-button-header
      class="m-2"
      :title="$t('patient.create.form.step.personal_info.title_menu')"
    />
    <flux-card class="mb-2" shadow="never">
      <flux-short-form>
        <flux-short-form-item
          :label="$t('patient.create.form.date_of_birth')"
          span="col-span-6"
          :required="true"
        >
          <flux-input
            v-model:modelValue="model.date_of_birth"
            prop="date_of_birth"
            prefix-icon="fal fa-calendar-alt"
            icon="fal fa-calendar-alt"
            type="date"
            min="1900-01-01"
            :required="true"
            :max="new Date().toISOString().split('T')[0]"
            :disabled="covLoading"
            @blur="emitCov()"
            @error="emit('error', $event)"
          >
          </flux-input>
        </flux-short-form-item>
        <flux-short-form-item
          v-if="covEnabled"
          label="COV check "
          span="col-span-6"
        >
          <p class="text-text-secondary/70 text-xs">
            Geboortedatum <br />
            BSN of Postcode en Huisnummer
          </p>
        </flux-short-form-item>

        <flux-short-form-item
          :label="$t('patient.create.form.bsn')"
          span="col-span-6"
        >
          <flux-input
            v-model:modelValue="model.bsn"
            @update:model-value="checkBsn(model.bsn)"
            inputClass="pr-6"
            prop="bsn"
            :disabled="covLoading"
            @error="emit('error', $event)"
          >
            <template
              v-if="
                !numberIsNotBsn &&
                ((model.nationality_id == 138 && model.bsn.length > 0) ||
                  existingPatient)
              "
              #suffix
            >
              <i
                class="fas fa-check"
                v-if="bsnIsValidBsn"
                style="color: #14ce68; margin-right: 6px"
              />
              <i
                class="fas fa-times"
                v-else-if="!bsnIsValidBsn"
                style="color: #ef3e36; margin-right: 6px"
              />
            </template>
          </flux-input>
          <div class="flex items-center text-sm text-text-tertiary">
            <flux-checkbox-label class="mt-4">
              <flux-checkbox v-model:modelValue="numberIsNotBsn" prop="bsn" />
              Nummer is geen BSN
            </flux-checkbox-label>
          </div>
        </flux-short-form-item>

        <div class="col-span-6 flex flex-col gap-y-2">
          <flux-short-form-item
            v-if="covEnabled && model.addresses[0]"
            :label="$t('patient.create.form.postal_code')"
            span="col-span-6"
          >
            <flux-input
              v-model:modelValue="model.addresses[0].postal_code"
              @blur="addressChanged()"
            >
            </flux-input>
          </flux-short-form-item>
          <flux-short-form-item
            v-if="covEnabled && model.addresses[0]"
            :label="$t('patient.create.form.house_number')"
          >
            <flux-input
              v-model:modelValue="model.addresses[0].house_number"
              @blur="addressChanged()"
            ></flux-input>
          </flux-short-form-item>
        </div>
      </flux-short-form>
    </flux-card>
  </div>
</template>

<script lang="ts" setup>
import { apiClient } from "@/libraries/utils/axios";
import { computed, ref, watch } from "vue";
import { PatientCreateForm } from "@/models/Forms";
import { bsnIsValid } from "../../libraries/utils/bsn";
import { PatientApi } from "../../models/Patient";
import { $t } from "@/libraries/i18n";
import { hasPermission } from "@/libraries/utils/hasPermission";
import { getUser } from "@/libraries/plugins/getUser";
import { hasFeatureFlag } from "@/libraries/plugins/hasFeatureFlag";
import { fullName } from "@/models/Person";
import { useAddress } from "@/composables/address";

const model = defineModel<PatientCreateForm>("modelValue", { required: true });
const bsnAlreadyExists = defineModel<boolean>("bsnAlreadyExists", {
  required: true,
});

defineProps<{
  covLoading: boolean;
}>();

const emit = defineEmits<{
  error: [value: boolean];
  cov: [];
  sbvz: [];
  bsnAlreadyExists: [v: boolean];
}>();

const existingPatient = ref<PatientApi>();
const numberIsNotBsn = computed({
  get: (): boolean => {
    return (
      !!model.value.national_identity_number_type &&
      model.value.national_identity_number_type !== "bsn"
    );
  },
  set: (isNotBsn: boolean) => {
    model.value.national_identity_number_type = isNotBsn ? "other" : "bsn";
  },
});
const bsnIsValidBsn = computed(() => bsnIsValid(model.value.bsn).success);
watch(
  () => [existingPatient.value?.zis_number, numberIsNotBsn.value],
  () =>
    (bsnAlreadyExists.value =
      existingPatient.value !== undefined && numberIsNotBsn.value === false),
  { immediate: true },
);

const covEnabled = computed(() => {
  return (
    getUser().healthcare_provider.cov_settings.enabled &&
    hasFeatureFlag("cov-check")
  );
});

const sbvzEnabled = computed(() => {
  return (
    getUser().healthcare_provider.sbvz_settings.enabled &&
    getUser().healthcare_provider.sbvz_settings.has_upload &&
    hasPermission("call-sbv-z", getUser()) &&
    hasFeatureFlag("sbv-z")
  );
});

function emitCov() {
  if (
    dateOfBirthIsValid() &&
    (bsnIsValid(model.value.bsn).success || postalAndNumberIsFilled()) &&
    covEnabled.value
  ) {
    emit("cov");
  }
}

function checkBsn(bsn: string) {
  if (bsn === "") {
    existingPatient.value = undefined;
    return;
  }

  if (bsnIsValid(model.value.bsn).success) {
    apiClient.post("/patients/bsn_exists", { bsn }).then((res) => {
      existingPatient.value = res.data?.patient ?? undefined;
    });
    if (sbvzEnabled.value) {
      emit("sbvz");
    }
    emitCov();
  } else {
    existingPatient.value = undefined;
  }
}

function dateOfBirthIsValid() {
  const dateOfBirth = new Date(model.value.date_of_birth);
  return dateOfBirth > new Date("1899-12-31") && dateOfBirth < new Date();
}

function postalAndNumberIsFilled(): boolean {
  return Boolean(
    model.value.addresses[0]?.postal_code.length &&
      model.value.addresses[0]?.house_number.length,
  );
}

const { fillAddress } = useAddress();

async function addressChanged() {
  if (model.value.addresses[0] === undefined) {
    return;
  }

  const filledAddress = await fillAddress(model.value.addresses[0]);
  if (filledAddress && filledAddress.street_name && filledAddress.city) {
    model.value.addresses[0] = {
      ...model.value.addresses[0],
      city: filledAddress.city,
      street_name: filledAddress.street_name,
    };
  }
  emitCov();
}
</script>
