<template>
  <div class="contents">
    <flux-card-button-header
      class="m-2"
      :title="$t('patient.create.form.step.company.title')"
    />
    <flux-card class="mb-2" shadow="never">
      <div class="space-y-2">
        <flux-input-box label="Bedrijfsdivisie">
          <CompanyDivisionSelect
            :id="value.company_division_id"
            @input:id="
              (d) => {
                value.company_division_id = d;
              }
            "
          />
        </flux-input-box>
        <flux-input-box label="Personeelsnummer">
          <flux-input v-model:modelValue="value.employee_number" />
        </flux-input-box>
      </div>
    </flux-card>
  </div>
</template>

<script lang="ts" setup>
import CompanyDivisionSelect from "@/components/ui/Input/CompanyDivisionSelect.vue";
import { PatientCreateForm } from "@/models/Forms";
import { computed } from "vue";

const props = defineProps<{ modelValue: PatientCreateForm }>();

const emit = defineEmits<{
  (e: "error", value: boolean): void;
  (e: "update:modelValue", value: PatientCreateForm): void;
}>();
const value = computed({
  get: () => props.modelValue,
  set: (v: PatientCreateForm) => emit("update:modelValue", v),
});
</script>
